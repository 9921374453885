import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import "./index.scss";
import BreadCrumb from "../../components/ui/BreadCrumb";
import SearchBar from "../../components/ui/SearchBar";
import HorizontalSpace from "../../components/ui/HorizontalSpace";
import VerticalSpace from "../../components/ui/VerticalSpace";
import Container from "reactstrap/lib/Container";
import Title from "../../components/ui/Title";
import Tile from "../../components/ui/Tile";
import SelectComponent from "../../components/ui/Select";
import NewBarChart from "../../components/charts/NewBarChart";
import Map from "../../components/maps/Map";
import { BuildingService } from "../../services/building-overview";
import { HelperFunctions } from "../../helper/helper-functions";

export default function BUildingOverview() {
  const [waterData, setWaterData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [solarData, setSolarData] = useState([]);
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const [energyStar, setEnergyStar] = useState([]);
  const [energyIntenstity, setEnergyIntensity] = useState([]);
  const [markerToBeHighlighted, setHighlightedMarker] = useState({});
  const [filterYear, setFilterYear] = useState({});
  const [yearList, setYearList] = useState([]);
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [grpToHighlight, setGrpToHighlight] = useState(null);
  const configData = window.runtimeConfig;

  useEffect(() => {
    getYearList();
  }, []);

  useEffect(() => {
    window.document.title = "SustainableDGS | Building Overview";
  }, []);

  async function getYearList() {
    const currentDate = new Date();
    const d = currentDate.setMonth(currentDate.getMonth() - window.MONTHFILTER);
    const yearMonth = `${currentDate.getFullYear()}${HelperFunctions.getMonth(
      new Date(d)
    )}`;
    try {
      const res = await BuildingService.getYearsList(yearMonth);
      if (res.isSuccessfull) {
        const years = res.data.map((obj) => {
          return { label: obj.YEAR, value: obj.YEAR };
        });
        setYearList(years);
        setFilterYear(years[years.length - 1]);
      }
    } catch (err) {}
  }

  const getWaterData = async () => {
    const res = await BuildingService.getWaterData(filterYear.value);
    if (res.isSuccessfull) {
      let categories = res.data.map((d) => d.MT_WATER_CONSUMPTION_GRP);
      let dataValue = res.data.map((d) => d.GRPCNT).map((d) => parseFloat(d));
      if (categories.find((x) => x === "0")) {
        categories = categories.filter((x) => x !== "0");
        dataValue.shift();
      }
      categories = formatCategories(categories);
      let barChart = [{ name: "CCF", data: dataValue }];
      setWaterData({ categories: categories, chart: barChart });
    }
  };

  useEffect(() => {
    if (yearList && yearList.length > 0) {
      getChartsData();
      getMapData();
      if (markerToBeHighlighted && markerToBeHighlighted.ID_ECAP_BUILDING) {
        getGroupForMarker(markerToBeHighlighted);
      }
    }
  }, [filterYear]);

  const formatCategories = (categories) => {
    return categories.map((x) => {
      while (x.indexOf("0") === 0) {
        x = x.slice(1, x.length);
      }
      return x;
    });
  };

  const getEnergyIntensity = async () => {
    const res = await BuildingService.getEnergyIntensity(filterYear.value);
    if (res.isSuccessfull) {
      let categories = res.data.map((d) => d.MT_ENERGY_USE_INTENSITY_GRP);
      let dataValue = res.data.map((d) => d.GRPCNT).map((d) => parseFloat(d));
      if (categories.find((x) => x === "0-Null")) {
        categories = categories.filter((x) => x !== "0-Null");
        dataValue.shift();
      }
      categories = formatCategories(categories);
      let barChart = [{ name: "kBtu", data: dataValue }];
      setEnergyIntensity({ categories: categories, chart: barChart });
    }
  };

  const getSolarData = async () => {
    const res = await BuildingService.getSolarData(filterYear.value);
    if (res.isSuccessfull) {
      let categories = res.data.map((d) => d.MT_SOLAR_GENERATION_GRP);
      let dataValue = res.data.map((d) => d.GRPCNT).map((d) => parseFloat(d));
      if (categories.find((x) => x === "0")) {
        categories = categories.filter((x) => x !== "0");
        dataValue.shift();
      }
      let barChart = [{ name: "Solar Generation", data: dataValue }];
      categories = formatCategories(categories);
      setSolarData({ categories: categories, chart: barChart });
    }
  };

  const getEnergyStarData = async () => {
    const res = await BuildingService.getEnergyStarData(filterYear.value);
    if (res.isSuccessfull) {
      let categories = res.data.map((d) => d.MT_ENERGY_STAR_SCORE_GRP);
      let dataValue = res.data.map((d) => d.GRPCNT).map((d) => parseFloat(d));
      if (categories.find((x) => x === "0")) {
        categories = categories.filter((x) => x !== "0");
        dataValue.shift();
      }
      categories = formatCategories(categories);
      let barChart = [{ name: "Energy Use Per Square Foot", data: dataValue }];
      setEnergyStar({ categories: categories, chart: barChart });
    }
  };

  const getChartsData = async () => {
    getWaterData();
    getEnergyIntensity();
    getSolarData();
    getEnergyStarData();
  };

  const clearChartData = () => {
    setEnergyStar([]);
    setEnergyIntensity([]);
    setWaterData([]);
    setSolarData([]);
  };

  const pinAddressToMap = (address) => {
    if (address) {
      const currentAd = autoCompleteResults.find(
        (x) => x.DS_CR_ADDRESS === address
      );
      if (currentAd && currentAd.DS_LAT && currentAd.DS_LON) {
        setHighlightedMarker(currentAd);
        getGroupForMarker(currentAd);
      }
    }
  };

  const getMapData = async () => {
    const res = await BuildingService.getMapData();
    if (res.isSuccessfull) {
      setMarkers(res.data);
    }
  };

  const getInputData = (title, data) => {
    return (
      <>
        <div className="w-100 mb-2">
          <p className="mb-1">{title}</p>
          <div className="dgs_buildingOverviewPage-dataRow-data-obj">
            <p className="mb-0">{data}</p>
          </div>
        </div>
        <HorizontalSpace xs />
      </>
    );
  };

  const getHighlightHintMsg = () => {
    if (grpToHighlight) {
      return (
        <p
          style={{
            fontSize: 11,
            textAlign: "left",
            paddingLeft: "1rem",
          }}
        >
          * Highlighted Buildings are shown in{" "}
          <span style={{ color: "#4791fe", fontWeight: "bold" }}>blue</span>{" "}
          color on charts.
        </p>
      );
    }
    return "";
  };

  const updateAutoCompleteResults = async (value) => {
    setBtnDisabled(true);
    const res = await BuildingService.getMapData(value);
    if (res.isSuccessfull) {
      setAutoCompleteResults(res.data);
      setBtnDisabled(false);
    }
  };

  const getGroupForMarker = async (marker) => {
    const res = await BuildingService.getTheNeededBuildingToHighlight(
      marker.ID_ECAP_BUILDING,
      filterYear.value
    );
    if (res.isSuccessfull) {
      const groupResponse = res.data[0];
      if (groupResponse) {
        const groupKeys = Object.keys(groupResponse);
        if (groupKeys) {
          groupKeys.forEach((key) => {
            if (groupResponse[key]) {
              if (groupResponse[key].indexOf("0") === 0)
                groupResponse[key] = groupResponse[key].slice(
                  1,
                  groupResponse[key].length
                );
            }
          });
        }
      }
      setGrpToHighlight(groupResponse);
    }
  };

  return (
    <div className="dgs_buildingOverviewPage">
      <BreadCrumb title="Building Overview" />
      <ReactTooltip clickable delayHide={500} />
      <div>
        <Map
          height="30rem"
          markers={markers}
          highlightedMarker={markerToBeHighlighted}
          markerHandler={(marker, data) => {
            if (marker) {
              if (
                marker.ID_ECAP_BUILDING &&
                markerToBeHighlighted &&
                markerToBeHighlighted.ID_ECAP_BUILDING ===
                  marker.ID_ECAP_BUILDING
              ) {
                setHighlightedMarker(null);
                setGrpToHighlight(null);
              } else {
                setHighlightedMarker(marker);
                getGroupForMarker(marker);
              }
            } else {
              setHighlightedMarker(null);
              setGrpToHighlight(null);
            }
          }}
          field="ID_ECAP_BUILDING"
          usedPage="building"
        />
      </div>
      <div className="dgs_buildingOverviewPage-srchRow py-3">
        <SearchBar
          disabled={isBtnDisabled}
          reset={markerToBeHighlighted === null}
          callBack={(value) => updateAutoCompleteResults(value)}
          options={autoCompleteResults && autoCompleteResults}
          onSelectCallBack={(address) => pinAddressToMap(address)}
        />
      </div>
      <Container className="dgs_buildingOverviewPage-dataRow py-4">
        {markerToBeHighlighted && markerToBeHighlighted.ID_ECAP_BUILDING && (
          <>
            <div className="dgs_buildingOverviewPage-dataRow-title">
              <div className="d-flex align-items-center">
                <Title
                  children={markerToBeHighlighted.DS_ECAP_BUILDING}
                  left
                  secondary
                />
                <HorizontalSpace xxs />
                <p style={{ marginBottom: 0 }}>
                  {markerToBeHighlighted.DS_CR_ADDRESS}
                </p>
              </div>
            </div>
            <div
              className="dgs_buildingOverviewPage-dataRow-data"
              style={{ marginBottom: 30 }}
            >
              <div className="d-flex mb-4 justify-content-between">
                {getInputData("Agency", markerToBeHighlighted.DS_ECAP_AGENCY)}
                {getInputData(
                  "Building Type",
                  markerToBeHighlighted.DS_ECAP_FACILITY_TYPE
                )}
                {getInputData(
                  "SF",
                  HelperFunctions.formatNumberWithCommas(
                    markerToBeHighlighted.MT_ECAP_SQUARE_FOOTAGE
                  )
                )}
              </div>
              {/* <div>
                <span>
                  * Highlighted Buildings are shown in{" "}
                  <span style={{ color: "#4791fe", fontWeight: "bold" }}>
                    blue
                  </span>{" "}
                  color on charts.
                </span>
              </div> */}
            </div>
          </>
        )}
        <div className="dgs_buildingOverviewPage--year_filter">
          <SelectComponent
            options={yearList}
            selected={filterYear}
            selectedCallBack={(dateObj) => {
              setFilterYear(dateObj);
              clearChartData();
            }}
          />
        </div>
        <div>
          <div className="dgs_buildingOverviewPage-dataRow-tile d-flex w-100">
            <Tile
              title={
                <p
                  style={{ marginBottom: 0 }}
                  data-effect="solid"
                  data-html="true"
                  data-tip=" <strong>ENERGY STAR Score: </strong>A measure of how well your<br/>
                  property is performing relative to similar properties, when<br/>
                  normalized for climate and operational characteristics."
                >
                  ENERGY STAR <img alt="" src="/icon/exclamation.svg"></img>
                </p>
              }
              children={
                <>
                  <NewBarChart
                    unit={""}
                    title="ENERGY STAR"
                    grpToHighlight={grpToHighlight}
                    field={"MT_ENERGY_STAR_SCORE_GRP"}
                    tooltipUnit={"Energy Star Score"}
                    seriesData={energyStar.chart}
                    xAxis={"Energy Star Score"}
                    categories={energyStar.categories}
                    buildingToBeHighlighted={markerToBeHighlighted}
                    noLegends
                    small
                    withColors
                    limitColors={
                      window.BUILDING_OVERVIEW_BARS_COLOR
                        ?.ENERGY_STAR_SCORE_COLORS
                    }
                  />
                  {getHighlightHintMsg()}
                </>
              }
            />
            <HorizontalSpace xxs />
            <Tile
              title={
                <p
                  style={{ marginBottom: 0 }}
                  data-html="true"
                  data-effect="solid"
                  data-tip={HelperFunctions.getEnergyUseIntensity()}
                >
                  Energy Use Intensity{" "}
                  <img alt="" src="/icon/exclamation.svg"></img>
                </p>
              }
              children={
                <>
                  <NewBarChart
                    unit={"kBtu/Sqft"}
                    title="Energy Use Intensity"
                    grpToHighlight={grpToHighlight}
                    field={"MT_ENERGY_USE_INTENSITY_GRP"}
                    tooltipUnit={"kBtu/Sqft"}
                    xAxis={"kBtu/Sqft"}
                    seriesData={energyIntenstity.chart}
                    categories={energyIntenstity.categories}
                    field="MT_ENERGY_USE_INTENSITY_GRP"
                    buildingToBeHighlighted={markerToBeHighlighted}
                    noLegends
                    small
                    withColors
                    limitColors={
                      window.BUILDING_OVERVIEW_BARS_COLOR
                        ?.ENERGY_USE_INTENSITY_COLORS
                    }
                  />
                  {getHighlightHintMsg()}
                </>
              }
            />
          </div>
          <VerticalSpace m />
          <div className="dgs_buildingOverviewPage-dataRow-tile d-flex w-100">
            <Tile
              title={
                <p
                  style={{ marginBottom: 0 }}
                  data-html="true"
                  data-effect="solid"
                  data-tip=" <strong>Solar Generation: </strong>Electricity (kWh) produced by solar photovoltaics</br> panels when exposed to photons, or particles of light"
                >
                  Solar Generation{" "}
                  <img alt="" src="/icon/exclamation.svg"></img>
                </p>
              }
              children={
                <>
                  <NewBarChart
                    unit={"kWh"}
                    title="Solar Generation"
                    grpToHighlight={grpToHighlight}
                    field={"MT_SOLAR_GENERATION_GRP"}
                    tooltipUnit={"MWh"}
                    xAxis={"MWh"}
                    seriesData={solarData.chart}
                    categories={solarData.categories}
                    field="MT_SOLAR_GENERATION_GRP"
                    buildingToBeHighlighted={markerToBeHighlighted}
                    noLegends
                    small
                    withColors
                    limitColors={
                      window.BUILDING_OVERVIEW_BARS_COLOR?.SOLAR_COLORS
                    }
                    log
                  />
                  {getHighlightHintMsg()}
                </>
              }
            />
            <HorizontalSpace xxs />
            <Tile
              title={
                <p
                  style={{ marginBottom: 0 }}
                  data-html="true"
                  data-effect="solid"
                  data-tip=" <strong>Water Use: </strong>The total amount of utility-provided potable water that <br>is used by a variety of inside and outside building systems."
                >
                  Water Use <img alt="" src="/icon/exclamation.svg"></img>
                </p>
              }
              children={
                <>
                  <NewBarChart
                    unit={"CCF"}
                    title="Water Use"
                    field={"MT_WATER_CONSUMPTION_GRP"}
                    grpToHighlight={grpToHighlight}
                    tooltipUnit={"CCF"}
                    xAxis={"CCF"}
                    seriesData={waterData.chart}
                    categories={waterData.categories}
                    field="MT_WATER_CONSUMPTION_GRP"
                    buildingToBeHighlighted={markerToBeHighlighted}
                    noLegends
                    small
                    withColors
                    limitColors={
                      window.BUILDING_OVERVIEW_BARS_COLOR?.WATER_USE_COLORS
                    }
                  />
                  {getHighlightHintMsg()}
                </>
              }
            />
          </div>
        </div>
        <VerticalSpace s />
        <div className="dgs_buildingOverviewPage-dataRow-tile d-flex">
          <HorizontalSpace s />
        </div>
      </Container>
    </div>
  );
}

import React from "react";
import "./index.scss";

export default React.forwardRef(
  ({ type, id, placeholder, s, xs, dark, onChange, ...props }, ref) => {
    const getClasses = () => {
      const res = ["dgs_form_input"];
      if (s) res.push("dgs_form_input-small");
      if (xs) res.push("dgs_form_input-xsmall");
      if (dark) res.push("dgs_form_input-dark");
      return res.join(" ");
    };
    return (
      <input
        autocomplete="off"
        type={type}
        className={getClasses()}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        ref={ref}
        {...props}
      />
    );
  }
);

import React, { createContext, useState } from "react";
import LocalStorageService from "../services/authentication/localStorageService";

export const AppContext = createContext();

const { Provider } = AppContext;

export const AppProvider = (props) => {
  const [user, setUserInternally] = useState(LocalStorageService.getUser());

  const setUser = (user) => {
    LocalStorageService.setUser(user);
    setUserInternally(user);
  };

  return <Provider value={[user, setUser]}>{props.children}</Provider>;
};

import Axios from "axios";
import { HelperFunctions } from "../../helper/helper-functions";

const backendUrl = HelperFunctions.getBackendUrl();

const dataSet = "/dataset";

const addYearFilter = (value) => {
  return {
    field: "YEAR",
    operator: "eq",
    value: value,
  };
};

export class BuildingService {
  static async getYearsList(month) {
    const RequestBody = {
      name: "ECAP_BASED_DATA",
      sort: [
        {
          dir: "ASC",
          field: "YEAR",
        },
      ],
      filter: {
        logic: "AND",
        filters: [
          {
            field: "YEAR_MONTH",
            operator: "lt",
            value: month,
          },
        ],
      },
      group: {
        keys: ["YEAR"],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          return e;
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getWaterData(year) {
    const RequestBody = {
      name: "BUILDING_WATER_USE_GRP",
      column: [
        {
          name: "MT_WATER_CONSUMPTION_GRP",
        },
      ],
      sort: [
        {
          dir: "ASC",
          field: "MT_WATER_CONSUMPTION_GRP",
        },
      ],
      filter: {
        filters: [addYearFilter(year)],
      },
      group: {
        keys: ["MT_WATER_CONSUMPTION_GRP"],
        groups: [
          {
            field: "ID_ECAP_BUILDING",
            function: "COUNT",
            title: "GRPCNT",
            isDistinct: true,
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getElectricityMixData(year) {
    const RequestBody = {
      name: "ECAP_BASED_DATA",
      filter: {
        logic: "AND",
        filters: [
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "in",
            value: `${window.COMMODITY_TYPES.ELECTIRC},${window.COMMODITY_TYPES.GAS}`,
          },
          addYearFilter(year),
        ],
      },
      group: {
        keys: ["DS_ECAP_BUILDING", "ID_ECAP_BUILDING"],
        groups: [
          {
            field: "ELECTRIC_BY_KBTU",
            function: "SUM",
            title: "ELECTRIC_BY_KBTU",
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getWindData(year) {
    const RequestBody = {
      name: "ECAP_BASED_DATA",
      filter: {
        logic: "AND",
        filters: [
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "eq",
            value: window.COMMODITY_TYPES.WIND,
            //function: "TRIM",
          },
          addYearFilter(year),
        ],
      },
      group: {
        keys: ["DS_ECAP_BUILDING", "ID_ECAP_BUILDING"],
        groups: [
          {
            field: "WIND_GENERATION_BY_MWH",
            function: "SUM",
            title: "WIND_GENERATION_BY_MWH",
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getMapData(filterValue = "") {
    const RequestBody = {
      name: "BUILDING_MAP_DATA",
      sort: [
        {
          dir: "ASC",
          field: "ID_ECAP_BUILDING",
        },
      ],
      filter: {
        logic: "OR",
        filters: [
          {
            field: "DS_CR_ADDRESS",
            operator: "contains",
            value: filterValue,
          },
          {
            field: "DS_ECAP_FACILITY_TYPE",
            operator: "contains",
            value: filterValue,
          },
          {
            field: "DS_ECAP_BUILDING",
            operator: "contains",
            value: filterValue,
          },
          {
            field: "DS_ECAP_AGENCY",
            operator: "contains",
            value: filterValue,
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getEnergyIntensity(year) {
    const RequestBody = {
      name: "BUILDING_EUI_GRP",
      column: [
        {
          name: "MT_ENERGY_USE_INTENSITY_GRP",
        },
      ],
      sort: [
        {
          dir: "ASC",
          field: "MT_ENERGY_USE_INTENSITY_GRP",
        },
      ],
      filter: {
        logic: "AND",
        filters: [
          addYearFilter(year),
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "in",
            value: `${window.COMMODITY_TYPES.ELECTIRC},${window.COMMODITY_TYPES.GAS},${window.COMMODITY_TYPES.SOLAR}`,
            function: "TRIM",
          },
        ],
      },
      group: {
        keys: ["MT_ENERGY_USE_INTENSITY_GRP"],
        groups: [
          {
            field: "ID_ECAP_BUILDING",
            function: "COUNT",
            title: "GRPCNT",
            isDistinct: true,
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getEnergyMix(year) {
    const RequestBody = {
      name: "ECAP_BASED_DATA",
      filter: {
        logic: "AND",
        filters: [
          addYearFilter(year),
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "in",
            value: `${window.COMMODITY_TYPES.ELECTIRC},${window.COMMODITY_TYPES.GAS},${window.COMMODITY_TYPES.SOLAR},${window.COMMODITY_TYPES.WIND}`,
            function: "TRIM",
          },
        ],
      },
      group: {
        keys: ["DS_ECAP_BUILDING", "ID_ECAP_BUILDING"],
        groups: [
          {
            field: "TOTAL_ENERGY_USE_BY_KBTU",
            function: "SUM",
            title: "TOTAL_ENERGY_USE_BY_KBTU",
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getTheNeededBuildingToHighlight(buildingId, year) {
    const RequestBody = {
      name: "ECAP_BASED_DATA",
      column: [],
      filter: {
        logic: "AND",
        filters: [
          {
            field: "ID_ECAP_BUILDING",
            operator: "eq",
            value: buildingId,
          },
          addYearFilter(year),
        ],
      },
      group: {
        keys: [],
        groups: [
          {
            field: "MT_WATER_CONSUMPTION_GRP",
            function: "MAX",
            title: "MT_WATER_CONSUMPTION_GRP",
          },
          {
            field: "MT_SOLAR_GENERATION_GRP",
            function: "MAX",
            title: "MT_SOLAR_GENERATION_GRP",
          },
          {
            field: "MT_ENERGY_USE_INTENSITY_GRP",
            function: "MAX",
            title: "MT_ENERGY_USE_INTENSITY_GRP",
          },
          {
            field: "MT_ENERGY_STAR_SCORE_GRP",
            function: "MAX",
            title: "MT_ENERGY_STAR_SCORE_GRP",
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getEnergyStarData(year) {
    const RequestBody = {
      name: "ECAP_BASED_DATA",
      column: [
        {
          name: "MT_ENERGY_STAR_SCORE_GRP",
        },
      ],
      filters: [addYearFilter(year)],
      sort: [
        {
          dir: "ASC",
          field: "MT_ENERGY_STAR_SCORE_GRP",
        },
      ],
      group: {
        keys: ["MT_ENERGY_STAR_SCORE_GRP"],
        groups: [
          {
            field: "ID_ECAP_BUILDING",
            function: "COUNT",
            title: "GRPCNT",
            isDistinct: true,
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getSolarData(year) {
    const RequestBody = {
      name: "BUILDING_SOLAR_GEN_GRP",
      column: [
        {
          name: "MT_SOLAR_GENERATION_GRP",
        },
      ],
      sort: [
        {
          dir: "ASC",
          field: "MT_SOLAR_GENERATION_GRP",
        },
      ],
      filter: {
        logic: "AND",
        filters: [
          addYearFilter(year),
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "eq",
            value: window.COMMODITY_TYPES.SOLAR,
            function: "TRIM",
          },
        ],
      },
      group: {
        keys: ["MT_SOLAR_GENERATION_GRP"],
        groups: [
          {
            field: "ID_ECAP_BUILDING",
            function: "COUNT",
            title: "GRPCNT",
            isDistinct: true,
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of error
          return {};
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }
}

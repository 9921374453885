import React from "react";
import "./index.scss";
import BreadCrumb from "../../components/ui/BreadCrumb";
import { Container } from "reactstrap";
import Title from "../../components/ui/Title";
import VerticalSpace from "../../components/ui/VerticalSpace";
import HorizontalSpace from "../../components/ui/HorizontalSpace";
import LinkComponent from "../../components/ui/LinkComponent";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";

export default function WastePage() {
  const getPortfolioRateCard = (icon, title, value) => {
    return (
      <div className="dgs_wastePage-body-rateRow-data d-flex align-items-center px-3 py-2">
        <img alt="" src={`/icon/${icon}`}></img>
        <HorizontalSpace xs />
        <Title children={title} s left secondary></Title>
        <HorizontalSpace xs />
        <Title children={value} l left secondary></Title>
      </div>
    );
  };

  const getLevelRowSections = (title) => {
    return (
      <>
        <Title children={title} xs left secondary></Title>
        <div className="d-flex w-50">
          <Input xs dark />
          <HorizontalSpace xs />
          <Button children="edit" edit icon="pen.svg" />
        </div>
      </>
    );
  };

  return (
    <div className="dgs_wastePage">
      <BreadCrumb title="Waste" />
      <div className="dgs_wastePage-subHeader"></div>
      <Container className="dgs_wastePage-body">
        <Title children="Portfolio Diversion Rate" left secondary></Title>
        <VerticalSpace s />
        <div className="dgs_wastePage-body-rateRow d-flex pb-4">
          {getPortfolioRateCard("header_icon_1.svg", "Trash", 30)}
          <HorizontalSpace xs />
          {getPortfolioRateCard("header_icon_1.svg", "Recycling", 130)}
          <HorizontalSpace xs />
          {getPortfolioRateCard("header_icon_1.svg", "Organics", 65)}
          <HorizontalSpace xs />
          {getPortfolioRateCard(
            "header_icon_1.svg",
            "Calculated Diversion Rate (%)",
            "12%"
          )}
        </div>
        <div className="dgs_wastePage-body-statRow py-4">
          <Title children="Program Stats" left secondary></Title>
          <div className="d-flex align-items-center">
            <Title children="30" l left secondary></Title>
            <HorizontalSpace xs />
            <Title children="Participating Schools" xs left menu></Title>
            <HorizontalSpace xs />
            <Title children="Stat #2" xs left menu></Title>
            <HorizontalSpace xs />
            <Title children="Stat #3" xs left menu></Title>
          </div>
        </div>
        <div className="dgs_wastePage-body-scheduleRow py-4">
          <div className="d-flex align-items-center">
            <Title children="DGS Hauling Schedule" left secondary></Title>
            <LinkComponent primary children="* click to download" />
          </div>
          <div className="d-flex align-items-center">
            <p>*current as of 2021/03/27, subject to change seasonally​</p>{" "}
          </div>
        </div>
        <div className="dgs_wastePage-body-levelRow py-4 ">
          <Title children="Portfolio Level Narrative" s left secondary></Title>
          <VerticalSpace s />
          {getLevelRowSections("Section 1")}
          {getLevelRowSections("Section 2")}
        </div>
        <div className="dgs_wastePage-body-carouselRow py-4 ">
          <Title children="Carousel Carousel Carousel" l primary></Title>
          <VerticalSpace l />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mollis
            sit amet ligula a imperdiet. Praesent ligula massa, gravida non nibh
            eu, cursus efficitur felis. Ut nulla diam, pulvinar eget auctor et,
            faucibus at lorem. In ac neque augue. Proin gravida sapien feugiat,
            cursus metus vitae, bibendum nulla.
          </p>
        </div>
        <div className="dgs_wastePage-body-programRow py-4 ">
          <Title children="External Programs​" s left secondary></Title>
          <VerticalSpace s />
          <div className="d-flex align-items-center">
            <div className="dgs_wastePage-body-programRow-info ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit .{" "}
              <LinkComponent
                primary
                underline
                children="Click for more information"
              />
            </div>
            <HorizontalSpace xs />
            <Button children="edit" edit icon="pen.svg" />
          </div>
        </div>
      </Container>
    </div>
  );
}

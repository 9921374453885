import React from "react";
import "./index.scss";

export default function Logo({ big, link }) {
  return (
    <>
      {big ? (
        <a href={link} target="_blank">
          <img alt="" src="/imgs/DGS-Logo.png"></img>
        </a>
      ) : (
        <a href={link} target="_blank">
          <img
            alt=""
            width="150"
            src="/imgs/NEW MMB_branding_J-01-WHITE.png"
          ></img>
        </a>
      )}
    </>
  );
}

import Axios from "axios";
import { HelperFunctions } from "../../helper/helper-functions";

const backendUrl = HelperFunctions.getBackendUrl();

const dataSet = "/dataset";

export class PortfolioService {
  static async getEnergyUsePerSquareFoot() {
    const RequestBody = {
      name: "PORTFOLIO_ENERGY_USE_PER_SF",
      sort: [
        {
          field: "YEAR",
          dir: "ASC",
        },
      ],
      filter: {
        logic: "AND",
        filters: [
          {
            field: "YEAR",
            operator: "lt",
            value: `${HelperFunctions.getLastYearFilterLimit()}`,
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of errors
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getDistributionBySquareFeet() {
    const RequestBody = {
      name: "PORTFOLIO_DISTRIBUTION_BY_SF",
      sort: [
        {
          dir: "ASC",
          field: "SQUARE_FOOTAGE_BY_AGENCY",
        },
      ],
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          // todo remove this and maybe return empty object in case of error
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static getNeededMonth = () => {
    const d = new Date();
    d.setMonth(d.getMonth() - window.MONTHFILTER);
    return HelperFunctions.getMonth(d);
  };

  static async getEnergyMix() {
    const startTime = `${HelperFunctions.getLastYear()}${this.getNeededMonth()}`;
    const endTime = `${HelperFunctions.getCurrentYear()}${this.getNeededMonth()}`;
    const RequestBody = {
      name: "PORTFOLIO_ENERGY_MIX",
      sort: [
        {
          field: "YEAR_MONTH",
          dir: "ASC",
        },
      ],
      filter: {
        logic: "AND",
        filters: [
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "in",
            value: `${window.COMMODITY_TYPES.ELECTIRC},${window.COMMODITY_TYPES.GAS},${window.COMMODITY_TYPES.SOLAR},${window.COMMODITY_TYPES.WIND}`,
          },
          {
            field: "YEAR_MONTH",
            operator: "lte",
            value: endTime,
          },
          {
            field: "YEAR_MONTH",
            operator: "gte",
            value: startTime,
          },
        ],
      },
      group: {
        keys: ["DS_UTILITY_COMMODITY", "YEAR_MONTH"],
        groups: [
          {
            field: "ENERGY_MIX_BY_MMBTU",
            function: "SUM",
            title: "ENERGY_MIX_BY_MMBTU",
          },
          {
            field: "MT_UTILITY_CONSUMPTION",
            function: "SUM",
            title: "MT_UTILITY_CONSUMPTION",
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((response) => response.data)
        .catch((error) => {});
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getEnergyConsumption() {
    const RequestBody = {
      name: "PORTFOLIO_ENERGY_CONSUMPTION",
      sort: [
        {
          field: "YEAR",
          dir: "ASC",
        },
      ],
      filter: {
        logic: "AND",
        filters: [
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "in",
            value: `${window.COMMODITY_TYPES.ELECTIRC},${window.COMMODITY_TYPES.GAS},${window.COMMODITY_TYPES.SOLAR},${window.COMMODITY_TYPES.WIND}`,
          },
          {
            field: "YEAR",
            operator: "lt",
            value: `${HelperFunctions.getLastYearFilterLimit()}`,
          },
        ],
      },
      group: {
        keys: ["YEAR"],
        groups: [
          {
            field: "TOTAL_ENERGY_USE_BY_MMBTU",
            function: "SUM",
            title: "TOTAL_ENERGY_USE_BY_MMBTU",
          },
          {
            field: "MT_UTILITY_CONSUMPTION",
            function: "SUM",
            title: "MT_UTILITY_CONSUMPTION",
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getWaterConsumption() {
    const RequestBody = {
      name: "PORTFOLIO_ENERGY_CONSUMPTION",
      sort: [
        {
          field: "YEAR",
          dir: "ASC",
        },
      ],
      filter: {
        logic: "AND",
        filters: [
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "contains",
            value: window.COMMODITY_TYPES.WATER,
          },
          {
            field: "YEAR",
            operator: "lt",
            value: `${HelperFunctions.getLastYearFilterLimit()}`,
          },
        ],
      },
      group: {
        keys: ["YEAR"],
        groups: [
          {
            field: "MT_UTILITY_CONSUMPTION",
            function: "SUM",
            title: "MT_UTILITY_CONSUMPTION",
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getGreenPowerConsumption() {
    const RequestBody = {
      name: "PORTFOLIO_GREEN_POWER_RATE",
      sort: [
        {
          field: "YEAR",
          dir: "ASC",
        },
      ],
      filter: {
        logic: "AND",
        filters: [
          {
            field: "DS_UTILITY_COMMODITY",
            operator: "in",
            value: `${window.COMMODITY_TYPES.ELECTIRC},${window.COMMODITY_TYPES.SOLAR},${window.COMMODITY_TYPES.WIND}`,
          },
          {
            field: "YEAR",
            operator: "lt",
            value: `${HelperFunctions.getLastYearFilterLimit()}`,
          },
        ],
      },
      group: {
        keys: ["YEAR"],
        groups: [
          {
            field: "GREEN_POWER_BY_MWH",
            function: "SUM",
            title: "GREEN_POWER_BY_MWH",
          },
          {
            field: "TOTAL_USE_BY_MWH",
            function: "SUM",
            title: "TOTAL_USE_BY_MWH",
          },
        ],
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result.data)
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }
}

import React from "react";
import "./index.scss";
import "react-toggle/style.css";
import EditableSection from "../../sections/HomePage/ClaimToogle";
import Title from "../Title";

export default function Tile({
  title,
  href,
  children,
  gray,
  activeClaim,
  isEditable,
  secondGroupClaims,
  onChange,
}) {
  return (
    <>
      <div className={`dgs_tile ${gray ? "dgs_tile_gray" : ""}`}>
        <div className="dgs_tile-title px-3 d-flex align-items-center justify-content-between py-2">
          {href ? (
            <a className="dgs_tile-title-link" href={href}>
              {isEditable ? (
                <Title left children={title} secondary xs />
              ) : (
                <Title left children={title} secondary s />
              )}
              <EditableSection
                activeClaim={activeClaim}
                isEditable={isEditable}
                secondGroupClaims={secondGroupClaims}
                onChange={onChange}
              />
            </a>
          ) : (
            <>
              {isEditable ? (
                <Title children={title} secondary xs left />
              ) : (
                <Title left children={title} secondary s />
              )}
              <EditableSection
                activeClaim={activeClaim}
                isEditable={isEditable}
                secondGroupClaims={secondGroupClaims}
                onChange={onChange}
              />
            </>
          )}
        </div>
        <div className="dgs_tile-table py-2">{children}</div>
      </div>
    </>
  );
}

import React from "react";
import Header from "../components/ui/Header";
import Footer from "../components/ui/Footer";
import { withRouter } from "react-router-dom";
export const Layout = withRouter((props) => <MyLayout {...props} />);

class MyLayout extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column min-vh-100 ">
        <Header activeItem={this.props.location.pathname} />
        <div className="h-100">{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

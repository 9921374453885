import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import BreadCrumb from "../../components/ui/BreadCrumb";
import Title from "../../components/ui/Title";
import Tile from "../../components/ui/Tile";
import VerticalSpace from "../../components/ui/VerticalSpace";
import ThreeDPie from "../../components/charts/ThreeDPie";
import { Container } from "reactstrap";
import "./index.scss";
import BarChart from "../../components/charts/BarChart";
import StackedAreaChart from "../../components/charts/StackedAreaChart";
import { PortfolioService } from "../../services/portfolio";
import { AuthService } from "../../services/authentication";
import LastTiles from "../../components/sections/HomePage/LastTiles";
import { HelperFunctions } from "../../helper/helper-functions";
import LoadingComponent from "../../components/ui/Loading";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondGroupClaims: [],
      claimsToUpdate: {},
      energyUsePerSquareFoot: {},
      energyDistribution: {},
      energyMix: {},
      activeClaimValue: "",
      updateLoading: false,
    };
  }

  getChartData = () => {
    PortfolioService.getEnergyUsePerSquareFoot().then((data) =>
      this.setEnergyUsePerSquareFoot(data.data)
    );
    PortfolioService.getDistributionBySquareFeet().then((data) =>
      this.setDistributionBySquareFeet(data.data)
    );
    PortfolioService.getEnergyMix().then((data) => {
      this.setEnergyMix(data.data);
    });
  };

  getClaimsData = async () => {
    try {
      const res = await AuthService.getClaimsForRole(
        window.ROLE_SECOND_GROUP
      ).then((data) => {
        this.setState({ secondGroupClaims: data.data });
      });
    } catch (err) {
      if (err?.response?.status) {
        await AuthService.handleLogin();
        await this.getClaimsData();
      }
    }
  };

  async componentDidMount() {
    window.document.title = "SustainableDGS | Portfolio";
    this.getChartData();
    await this.getClaimsData();
  }

  setEnergyMix(data) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let dataGrouped = data.reduce(function (r, a) {
      r[a.DS_UTILITY_COMMODITY] = r[a.DS_UTILITY_COMMODITY] || [];
      r[a.DS_UTILITY_COMMODITY].push(parseFloat(a.ENERGY_MIX_BY_MMBTU));
      return r;
    }, Object.create(null));

    let cat = data.map((d) => d.YEAR_MONTH);
    let catUniq = [...new Set(cat)].map(
      (d) =>
        `${d.substr(0, 4)} ${monthNames[parseInt(d.substr(4)) - 1].substr(
          0,
          3
        )}`
    );
    let keys = Object.keys(dataGrouped);

    let chart = keys.map((key) => {
      return { name: key, data: dataGrouped[key] };
    });

    if (
      keys.indexOf("Electric") > -1 &&
      keys.indexOf("Natural Gas") > -1 &&
      keys.indexOf("Solar") > -1 &&
      keys.indexOf("Wind") > -1 &&
      keys.length === 4
    ) {
      chart = [
        { name: "Solar", data: dataGrouped["Solar"] },
        {
          name: "Wind",
          data: dataGrouped["Wind"],
        },
        {
          name: "Electric",
          data: dataGrouped["Electric"],
        },
        {
          name: "Fossil Gas",
          data: dataGrouped["Natural Gas"],
        },
      ];
    }

    this.setState({
      energyMix: {
        categories: catUniq,
        chart: chart,
      },
    });
  }

  setDistributionBySquareFeet(data) {
    let pieData = data.map((d) => [
      d.ID_ECAP_AGENCY_SRC,
      parseFloat(d.SQUARE_FOOTAGE_BY_AGENCY),
      d.DS_ECAP_AGENCY,
    ]);
    let totalArea = data
      .map((d) => parseInt(d.TOTAL_SQUARE_FOOTAGE))
      .reduce((a, b) => a + b, 0);
    let titleValue = totalArea / 1000000;
    let title = titleValue.toFixed(1) + " Million" + " sqft";
    this.setState({ energyDistribution: { title: title, pieData: pieData } });
  }

  setEnergyUsePerSquareFoot(data) {
    let categories = data.map((d) => d.YEAR);
    let dataValue = data
      .map((d) => d.ENERGY_MIX_BY_KBTU_PER_SF)
      .map((d) => parseFloat(d));
    let barChart = [{ name: "Energy Use Per Square Foot", data: dataValue }];
    this.setState({
      energyUsePerSquareFoot: { categories: categories, chart: barChart },
    });
  }

  updateClaimForSecondGroup = async () => {
    if (this.state.claimsToUpdate && this.state.claimsToUpdate.key) {
      await AuthService.updatePermission({
        roleName: window.ROLE_SECOND_GROUP,
        claimName: HelperFunctions.getClaimName(this.state.claimsToUpdate.key),
        claimValue: this.state.claimsToUpdate.value === false ? "0" : "1",
      });
    }
  };

  updateClaimForPublic = async () => {
    if (this.state.claimsToUpdate && this.state.claimsToUpdate.key) {
      await AuthService.updatePermission({
        roleName: window.ROLE_PUBLIC_USER,
        claimName: HelperFunctions.getClaimName(this.state.claimsToUpdate.key),
        claimValue: this.state.claimsToUpdate.value === false ? "0" : "1",
      });
    }
  };

  updateClaims = async ({ key, value }) => {
    this.setState({ claimsToUpdate: { key, value } }, async () => {
      this.setState({ updateLoading: true });
      await this.updateClaimForSecondGroup();
      await this.updateClaimForPublic();
      await this.getClaimsData();
      this.setState({ updateLoading: false });
    });
  };

  render() {
    return (
      <div className="dgs_HomePage">
        <ReactTooltip clickable delayHide={500} />
        <BreadCrumb title="Portfolio" />
        <div className="dgs_HomePage-banner">
          <img alt="Portfolio Landing" src="/imgs/rev5.jpeg" />
          <div className="dgs_HomePage-banner-content">
            <h1 className="dgs_HomePage-banner-content-title">
              <p style={{ marginBottom: 0 }}>
                Welcome to SustainableDGS<sup>beta</sup>
              </p>
            </h1>
            <h2 className="dgs_HomePage-banner-content-subtitle">
              <p style={{ marginBottom: 0 }}>
                Powered by DGS Sustainability and Energy
              </p>
            </h2>
          </div>
        </div>
        <Container className="dgs_HomePage-body">
          <div
            style={{
              marginBottom: 30,
              borderBottom: "1px solid #bcbcbc",
              borderTop: "1px solid #bcbcbc",
              padding: "20px 0",
            }}
          >
            <p style={{ marginBottom: 0, fontSize: 18 }}>
              <span style={{ fontWeight: "bold" }}>
                SustainableDGS<sup>beta</sup>
              </span>{" "}
              captures utility data to support decarbonization and
              sustainability action across the DC government portfolio.
            </p>
          </div>
          <div className="dgs_HomePage-body-chartRow row d-flex">
            <div className="col-lg-6 col-md-12 pb-md-4 pb-sm-4">
              <Tile
                title={
                  <p
                    style={{ marginBottom: 0 }}
                    data-html="true"
                    data-effect="solid"
                    data-tip={HelperFunctions.getEnergyUseIntensity()}
                  >
                    Site Energy Use{" "}
                    <img alt="" src="/icon/exclamation.svg"></img>
                  </p>
                }
                children={
                  <BarChart
                    unit={window.PORTFOLIO_SITE_ENERGY.axisValue}
                    tooltipUnit={window.PORTFOLIO_SITE_ENERGY.tooltipValue}
                    noLegends
                    seriesData={this.state.energyUsePerSquareFoot.chart}
                    categories={this.state.energyUsePerSquareFoot.categories}
                    color={window.PORTFOLIO_SF_ENERGY_COLOR}
                  />
                }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <Tile
                title={
                  <p
                    style={{ marginBottom: 0 }}
                    data-html="true"
                    data-effect="solid"
                    data-event="mouseover"
                    data-tip="The DGS Utility Dashboard combines data from a variety of <br/>
               sources to give users a full picture of what is happening across<br/>
               the portfolio and at specific buildings... <a style=color:#de4156 href=/background/#dgs_background-about><strong>read more</strong>"
                  >
                    Agency Distribution{" "}
                    <img alt="" src="/icon/exclamation.svg"></img>
                  </p>
                }
                children={
                  <ThreeDPie
                    dataValues={this.state.energyDistribution.pieData}
                    title={this.state.energyDistribution.title}
                    colors={window.PORTFOLIO_PIE_CHART_COLORS}
                  />
                }
              />
            </div>
          </div>
          <VerticalSpace l />
          <Tile
            title={
              <p
                style={{ marginBottom: 0 }}
                data-html="true"
                data-tip=" The DGS Utility Dashboard combines data from a variety of<br/>
                sources to give users a full picture of what is happening across<br/>
                the portfolio and at specific buildings... <a style=color:#de4156 href=/background/#dgs_background-ourData><strong>read more</strong>"
                data-effect="solid"
                data-event="mouseover"
              >
                Energy Mix <img alt="" src="/icon/exclamation.svg"></img>
              </p>
            }
            children={
              <>
                <StackedAreaChart
                  title={""}
                  unit={window.PORTFOLIO_ENERGY_CONSUMPTION_AREA_CHART_AXIS}
                  tooltipUnit={window.PORTFOLIO_ENERGY_CONSUMPTION_AREA_CHART}
                  seriesData={this.state.energyMix.chart}
                  categories={this.state.energyMix.categories}
                  colors={window.PORTFOLIO_ENERGY_CONSUMPTION_COLORS}
                />
              </>
            }
          />
          <VerticalSpace s />
          <div className="dgs_HomePage-body-chartRow-map">
            <LastTiles
              secondGroupClaims={this.state.secondGroupClaims}
              onChange={this.updateClaims}
            />
          </div>
          <VerticalSpace m />
          <Title children="Awards and Recognition" />
          <VerticalSpace s />
          <div className="dgs_HomePage-body-references">
            <a href="https://www.c40.org/cities/washington-dc" target="blank">
              <img alt="reference 2" src="/imgs/ref/ref_9.png" />
            </a>
            <a href="http://bikeleague.org/bfa/awards" target="blank">
              <img alt="reference 1" src="/imgs/ref/ref_8.png" />
            </a>
            <a
              href="https://www.usgbc.org/articles/washington-dc-named-first-leed-platinum-city-world"
              target="blank"
            >
              <img alt="reference 3" src="/imgs/ref/ref_10.png" />
            </a>
            <a
              href="https://www.tpl.org/city/washington-district-columbia"
              target="blank"
            >
              <img
                alt="reference 4"
                src="/imgs/ref/ref_7.jpg"
                style={{ maxWidth: "6rem", marginRight: "0.5rem" }}
              />
            </a>
            <a
              href="https://www.epa.gov/greenpower/meet-our-partners"
              target="blank"
            >
              <img alt="reference 5" src="/imgs/ref/ref_12.jpeg" />
            </a>
          </div>
        </Container>
        {this.state.updateLoading && (
          <div className="loading">
            <LoadingComponent loading={this.state.updateLoading} />
          </div>
        )}
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import { Marker as Mrk, InfoWindow } from "@react-google-maps/api";
import "./index.scss";
import { Link } from "react-router-dom";

export default function Marker({
  marker,
  highlight,
  usedPage,
  markerHandler,
  noFullAddress,
}) {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [selected, setSelected] = useState(false);

  const handleMouseOver = (e) => {
    if (!selected) {
      setShowInfoWindow(true);
    }
  };
  const handleMouseExit = (e) => {
    if (!selected) {
      setShowInfoWindow(false);
    }
  };

  const handleClicked = (e) => {
    setShowInfoWindow(true);
    setSelected(!selected);
    markerHandler && markerHandler(marker, true);
  };

  useEffect(() => {
    if (highlight) {
      setShowInfoWindow(true);
      setSelected(true);
    } else {
      setShowInfoWindow(false);
      setSelected(false);
    }
  }, [highlight]);

  const pos = { lat: Number(marker.DS_LAT), lng: Number(marker.DS_LON) };

  const renderMarker = (showInfoWindow, marker, page) => {
    if (!showInfoWindow) {
      return;
    }
    if (page === "renewable") {
      return (
        <InfoWindow position={pos} onCloseClick={() => markerHandler(marker)}>
          <div
            className="dgs_infoWindow"
            onMouseMove={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <div>
              {!noFullAddress && (
                <>
                  <p>
                    <strong>Building: </strong>
                    {marker.DS_ECAP_BUILDING}
                  </p>
                  <p>
                    <strong>Agency: </strong>
                    {marker.DS_ECAP_AGENCY}
                  </p>
                  <p>
                    <strong>Facility Type: </strong>{" "}
                    {marker.DS_ECAP_FACILITY_TYPE}
                  </p>
                </>
              )}
              <p>
                <strong>Address: </strong> {marker.DS_CR_ADDRESS}
              </p>
            </div>
            <Link to={{ pathname: "/data-search", selectedMarker: marker }}>
              Read more
            </Link>
          </div>
        </InfoWindow>
      );
    }

    return (
      <InfoWindow position={pos} onCloseClick={() => markerHandler(marker)}>
        <div
          className="dgs_infoWindow"
          onMouseMove={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div>
            <p>
              <strong>Building: </strong>
              {marker.DS_ECAP_BUILDING}
            </p>
            <p>
              <strong>Agency: </strong>
              {marker.DS_ECAP_AGENCY}
            </p>
            <p>
              <strong>Facility Type: </strong> {marker.DS_ECAP_FACILITY_TYPE}
            </p>
            <p>
              <strong>Address: </strong> {marker.DS_CR_ADDRESS}
            </p>
          </div>
        </div>
      </InfoWindow>
    );
  };

  return (
    <Mrk
      position={{
        lat: Number(marker.DS_LAT),
        lng: Number(marker.DS_LON),
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseExit}
      onClick={handleClicked}
    >
      {renderMarker(showInfoWindow, marker, usedPage)}
    </Mrk>
  );
}

import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { HelperFunctions } from "../../../../helper/helper-functions";
import { AuthService } from "../../../../services/authentication";
import LocalStorageService from "../../../../services/authentication/localStorageService";
import { PortfolioService } from "../../../../services/portfolio";
import LineChartFill from "../../../charts/LineChartFill";
import "./index.scss";
import Tile from "../../../ui/Tile";
import VerticalSpace from "../../../ui/VerticalSpace";

function OneTile({
  currentKey,
  title,
  secondGroupClaims,
  isAdmin,
  data,
  chartTitle,
  percentage,
  onChange,
  isVisible,
  href,
  noLoading,
  color,
}) {
  const isGrayed =
    HelperFunctions.getChartVisibility(currentKey, secondGroupClaims) === "1";
  return (
    <>
      {(isVisible || isAdmin) && (
        <Tile
          title={title}
          isEditable={isAdmin}
          gray={isAdmin && !isGrayed}
          children={
            <LineChartFill
              noLoading={noLoading}
              percentage={percentage}
              chartData={data}
              title={chartTitle}
              tooltip={chartTitle}
              chartColor={color}
            />
          }
          href={href}
          activeClaim={currentKey}
          secondGroupClaims={secondGroupClaims}
          onChange={onChange}
        />
      )}
    </>
  );
}

export default function LastTiles({ secondGroupClaims, onChange }) {
  const keys = [
    "energyConsumption",
    "waterConsumption",
    "greenEnergy",
    "waste",
  ];
  const [energyConsumption, setEnergyConsumption] = useState({});
  const [waterConsumption, setWaterConsumption] = useState({});
  const [greenEnergy, setGreenEnergy] = useState({});
  const [claims, setClaims] = useState({});
  const [loaded, setLoaded] = useState(false);

  const getClaims = async () => {
    try {
      const res = await AuthService.getClaims();
      if (res.status === 200) {
        LocalStorageService.setClaims(res.data);
        setClaims(res.data);
        setLoaded(true);
      }
    } catch (err) {
      if (err?.response?.status) {
        await AuthService.handleLogin();
        await getClaims();
      }
    }
  };

  const getChartsData = async () => {
    PortfolioService.getEnergyConsumption().then((data) =>
      setEnergyConsumption(
        data.data.map((d) => [
          parseInt(d.YEAR),
          parseInt(d.TOTAL_ENERGY_USE_BY_MMBTU),
        ])
      )
    );
    PortfolioService.getWaterConsumption().then((data) =>
      setWaterConsumption(
        data.data.map((d) => [
          parseInt(d.YEAR),
          parseInt(d.MT_UTILITY_CONSUMPTION),
        ])
      )
    );
    PortfolioService.getGreenPowerConsumption().then((data) =>
      setGreenEnergy(
        data.data.map((d) => [
          parseInt(d.YEAR),
          (Number(d.GREEN_POWER_BY_MWH) / Number(d.TOTAL_USE_BY_MWH)) * 100,
        ])
      )
    );
  };

  useEffect(() => {
    getChartsData();
    getClaims();
  }, []);

  const getData = (key) => {
    if (key === "energyConsumption") return energyConsumption;
    if (key === "waterConsumption") return waterConsumption;
    if (key === "greenEnergy") return greenEnergy;
    if (key === "waste") return {};
    return {};
  };

  const getChartTitle = (key) => {
    if (key === "energyConsumption") return window.PORTFOLIO_ENERGY_CONSUPTION;
    if (key === "waterConsumption") return window.PORTFOLIO_WATER_USE;
    if (key === "greenEnergy") return window.PORTFOLIO_GREEN_POWER;
    if (key === "waste") return "";
    return "";
  };
  let visibleCounter = keys.map(
    (k) => HelperFunctions.getChartVisibility(k) === "1"
  );
  const isAdmin = HelperFunctions.isAdmin();

  if (visibleCounter) {
    visibleCounter = visibleCounter.filter((x) => x === true).length;
  }

  return (
    <>
      {loaded && (
        <div className="row w-100 justify-content-between ml-0">
          <ReactTooltip clickable delayHide={500} />
          {keys.map((k) => {
            const isVisible = HelperFunctions.getChartVisibility(k) === "1";
            return (
              <>
                {(isVisible || isAdmin) && (
                  <div
                    className={`dgs__lastTile-body col-md-12 col-lg-${
                      isAdmin ? 3 : 12 / visibleCounter
                    }`}
                  >
                    <>
                      <OneTile
                        onChange={onChange}
                        currentKey={k}
                        isAdmin={isAdmin}
                        title={
                          <div
                            data-html="true"
                            data-effect="solid"
                            data-event="mouseover"
                            data-tip={HelperFunctions.getTileTooltip(k)}
                          >
                            {HelperFunctions.getTileTitle(k)}
                            <img alt="" src="/icon/exclamation.svg"></img>
                          </div>
                        }
                        data={getData(k)}
                        chartTitle={getChartTitle(k)}
                        percentage={k === "greenEnergy"}
                        isVisible={isVisible}
                        noLoading={k === "waste"}
                        secondGroupClaims={secondGroupClaims}
                        color={window.PORTFILIO_TILES}
                      />
                      <VerticalSpace s />
                    </>
                  </div>
                )}
              </>
            );
          })}
        </div>
      )}
    </>
  );
}

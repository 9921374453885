import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { NotificationManager } from "react-notifications";
import Container from "reactstrap/lib/Container";
import LineChart from "../../components/charts/LineChart";
import Map from "../../components/maps/Map";
import BreadCrumb from "../../components/ui/BreadCrumb";
import Button from "../../components/ui/Button";
import CollapseComponent from "../../components/ui/Collapse";
import DatePickerComponent from "../../components/ui/DatePicker";
import HorizontalSpace from "../../components/ui/HorizontalSpace";
import LoadingComponent from "../../components/ui/Loading";
import Range from "../../components/ui/Range";
import SelectComponent from "../../components/ui/Select";
import VerticalSpace from "../../components/ui/VerticalSpace";
import { HelperFunctions } from "../../helper/helper-functions";
import { DateSearch } from "../../services/date-search";
import "./index.scss";

export class DataSearchPage extends Component {
  constructor(props) {
    super(props);
    let selectedMarker = this.props.location.selectedMarker?.DS_ECAP_BUILDING;
    this.configData = window.runtimeConfig;
    this.chartRef = React.createRef(null);
    this.state = {
      selectedGranulirty: {
        label: this.configData[4].RANGE_NAME,
        value: this.configData[4].RANGE_NAME,
      },
      selectedMarker: selectedMarker,
      mapLoading: true,
      TimeRange: {},
      markers: [],
      isBtnDisabled: true,
      showLoading: false,
      data: {},
      fullChartData: {},
      markerToBeHighlighted: [],
      changedByUser: false,
      selectedBuildingName: {},
      buildingNames: [],
      autoCompleteResults: [],
      FilterBuildingType: null,
      currentStartTime: null,
      currentEndTime: null,
      renewableFilter: null,
      FilterAgent: null,
      WardFilter: null,
      rangeData: [
        { title: "Sqft", default: 0, value: "sf" },
        {
          title: (
            <p
              style={{ marginBottom: 0 }}
              data-html="true"
              data-effect="solid"
              data-tip={HelperFunctions.getEnergyUseIntensity()}
            >
              EUI <img alt="" src="/icon/exclamation.svg"></img>
            </p>
          ),
          default: 0,
          value: "eui",
        },
        {
          title: (
            <p
              style={{ marginBottom: 0 }}
              data-effect="solid"
              data-html="true"
              data-tip=" <strong>ENERGY STAR Score: </strong>A measure of how well your<br/>
              property is performing relative to similar properties, when<br/>
              normalized for climate and operational characteristics."
            >
              ENERGY STAR Score <img alt="" src="/icon/exclamation.svg"></img>
            </p>
          ),
          value: "energyStar",
          default: 0,
          min: 0,
          max: 100,
        },
        {
          title: (
            <p
              style={{ marginBottom: 0 }}
              data-effect="solid"
              data-html="true"
              data-tip="  <strong>Greenhouse Gases (GHG): </strong>Gaseous compounds,<br/>
            including carbon dioxide (CO2), methane (CH4), nitrous oxide<br/>
            (N2O) and fluorinated gases, that absorb infrared radiation... <a style=color:#de4156 href=/background/#dgs_background-terms-energy-intensity-greenhouse><strong>read more</strong>"
            >
              Annual GHG Emmision <img alt="" src="/icon/exclamation.svg"></img>
            </p>
          ),
          default: 0,
          value: "ghg",
        },
      ],

      FilteringData: [
        { title: "Building Type", options: [] },
        { title: "Agency", options: [] },
        { title: "Ward", options: [] },
        { title: "Building Name", options: [] },
        {
          title: "Onsite renewables",
          options: [
            { label: "No", value: "No" },
            { label: "Yes", value: "Yes" },
          ],
        },
      ],
      FilteringMetricData: [
        { title: "Sqft" },
        { title: "EUI" },
        { title: "Energy Star Score" },
        { title: "GHG" },
      ],
      StartTime: null,
      EndTime: null,
      minSF: 0,
      maxSF: 0,
      minTotal: 0,
      maxTotal: 0,
      minGHG: 0,
      maxGHG: 0,
    };
  }

  setSelectedGranulirty() {
    this.setState({
      selectedGranulirty: {
        label: this.configData[4].RANGE_NAME,
        value: this.configData[4].RANGE_NAME,
      },
    });
  }

  handelMapSelectedData() {
    this.setState(this.selectedBuildingName);
  }

  filterData(title, e) {
    if (title === "Building Name") {
      let buildingName = e.map((e) => e.value);
      this.setState({ buildingNames: buildingName }, () => {
        this.afterSettingStateApplyFilter();
      });
      let highlited = e.map((e) => {
        return { DS_ECAP_BUILDING: e.value };
      });

      this.setState({ markerToBeHighlighted: highlited });
    } else {
      e = e.value;
    }
    if (title.includes("Building Type")) {
      this.setState({ FilterBuildingType: e }, () =>
        this.afterSettingStateApplyFilter()
      );
    } else if (title.includes("Agency")) {
      this.setState({ FilterAgent: e }, () =>
        this.afterSettingStateApplyFilter()
      );
    } else if (title.includes("Ward")) {
      this.setState({ WardFilter: e }, () =>
        this.afterSettingStateApplyFilter()
      );
    } else if (title.includes("Onsite renewables")) {
      this.setState({ renewableFilter: e }, () =>
        this.afterSettingStateApplyFilter()
      );
    }
  }

  afterSettingStateApplyFilter() {
    let filteredData = this.state.markers
      .filter(
        (data) =>
          this.state.FilterBuildingType === null ||
          this.state.FilterBuildingType.includes(data.DS_ECAP_FACILITY_TYPE)
      )
      .filter(
        (data) =>
          this.state.FilterAgent === null ||
          this.state.FilterAgent.includes(data.DS_ECAP_AGENCY)
      )
      .filter(
        (data) =>
          this.state.WardFilter === null ||
          this.state.WardFilter.includes(data.DS_WARD)
      )
      .filter(
        (data) =>
          // todod check if we need to filter with renewable
          this.state.renewableFilter === null ||
          (this.state.renewableFilter.includes("Yes") &&
            data.ID_ONSITE_RENEWABLES_IND == 1) ||
          (this.state.renewableFilter.includes("No") &&
            data.ID_ONSITE_RENEWABLES_IND == 0)
      );
    let res = [...filteredData];
    if (
      this.state.buildingNames.length > 0 &&
      (this.state.renewableFilter !== null ||
        this.state.WardFilter !== null ||
        this.state.FilterBuildingType !== null ||
        this.state.FilterAgent !== null)
    ) {
      res = [];
      this.state.buildingNames.forEach((bName) => {
        const item = filteredData.find((x) => x.DS_ECAP_BUILDING === bName);
        if (item) {
          res.push(item);
        }
      });
    }
    console.log("FilteredValues:" + { ...res });
    this.setFilteringValues(res);
  }

  componentDidMount() {
    window.document.title = "SustainableDGS | Data Search";
    this.fetDataFromBackEnd();
    this.pinAddressToMap();
    if (this.state.selectedMarker) {
      let selectedBuilding = this.state.selectedMarker;
      this.filterData("Building Name", [
        { label: selectedBuilding, value: selectedBuilding },
      ]);
      this.setState(
        { selectedMarker: null, buildingNames: [selectedBuilding] }
        // this.getChartData()
      );
    }
    let startTime = new Date();
    let endTime = new Date();
    endTime.setMonth(endTime.getMonth() - window.MONTHFILTER);
    startTime.setMonth(endTime.getMonth() - 12);
    this.setState({ currentStartTime: startTime, currentEndTime: endTime });
  }

  downloadInternally(CommodityType) {
    this.setState({ isBtnDisabled: true });
    let activeItems = [];
    try {
      if (!CommodityType)
        activeItems = this.chartRef.current.chart.legend.allItems
          .filter((x) => x.visible)
          .map((x) => x.name);
      if (CommodityType && CommodityType.toUpperCase() === "FOSSIL GAS") {
        CommodityType = "Natural Gas";
      }
      activeItems = activeItems.map((x) => {
        if (x.toUpperCase() === "FOSSIL GAS") return "Natural Gas";
        return x;
      });
      DateSearch.downloadData(
        this.getFilteredData(),
        activeItems,
        CommodityType
      ).then(
        (result) => {
          if (
            result &&
            (result.response?.status === 200 || result.status === 200)
          ) {
            const data = new Blob([result.data]);
            const csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute(
              "download",
              `${this.state.selectedGranulirty.value} Data for ${
                CommodityType ? CommodityType : activeItems.join("-")
              }.xlsx`
            );
            tempLink.click();
            this.setState({ isBtnDisabled: false, showLoading: false });
          } else {
            this.setState({ isBtnDisabled: false, showLoading: false });
          }
        },
        (err) => {
          this.setState({ isBtnDisabled: false, showLoading: false });
        }
      );
    } catch (err) {
      this.setState({ isBtnDisabled: false, showLoading: false });
    }
  }

  downloadChartDataDetail() {
    if (
      this.state.selectedGranulirty.value === "Yearly" ||
      this.state.selectedGranulirty.value === "Monthly"
    ) {
      this.downloadInternally(null);
    } else {
      const activeItems = this.chartRef.current.chart.legend.allItems
        .filter((x) => x.visible)
        .map((x) => x.name);
      activeItems.forEach((x) => {
        this.download(x);
      });
    }
  }

  download(CommodityType) {
    if (
      this.chartRef &&
      this.chartRef.current &&
      this.chartRef.current.chart.legend
    ) {
      this.downloadInternally(CommodityType);
    }
  }

  getFilteredData = () => {
    const filterData = {};
    filterData.selectedGranulirty = this.state.selectedGranulirty;
    if (this.state.EndTime && this.state.StartTime) {
      filterData.startTime = HelperFunctions.formatDateForBE(
        new Date(this.state.StartTime),
        this.state.selectedGranulirty.value
      );
      filterData.endTime = HelperFunctions.formatDateForBE(
        new Date(this.state.EndTime),
        this.state.selectedGranulirty.value
      );
    }
    if (this.state.renewableFilter) {
      if (this.state.renewableFilter === "Yes")
        filterData.ID_ONSITE_RENEWABLES_IND = 1;
      else filterData.ID_ONSITE_RENEWABLES_IND = 0;
    }
    if (this.state.selectedGranulirty.value === "Yearly") {
      let eTime = new Date(this.state.EndTime);
      eTime = eTime.setFullYear(eTime.getFullYear() + 1);
      filterData.endTime = HelperFunctions.formatDateForBE(
        new Date(eTime),
        this.state.selectedGranulirty.value
      );
    }
    const SF = this.state.rangeData.find((x) => x.value === "sf");
    if (SF && SF.currentMaxValue) {
      filterData.maxSF = SF.currentMaxValue;
    }
    if (SF && SF.currentMinValue) {
      filterData.minSF = SF.currentMinValue;
    }
    const STAR = this.state.rangeData.find((x) => x.value === "energyStar");
    if (STAR && STAR.currentMaxValue) {
      filterData.maxSTAR = STAR.currentMaxValue;
    }
    if (STAR && STAR.currentMinValue) {
      filterData.minSTAR = STAR.currentMinValue;
    }
    const EUI = this.state.rangeData.find((x) => x.value === "eui");
    if (EUI && EUI.currentMaxValue) {
      filterData.maxEUI = EUI.currentMaxValue;
    }
    if (EUI && EUI.currentMinValue) {
      filterData.minEUI = EUI.currentMinValue;
    }
    const GHG = this.state.rangeData.find((x) => x.value === "ghg");
    if (GHG && GHG.currentMaxValue) {
      filterData.maxGHG = GHG.currentMaxValue;
    }
    if (GHG && GHG.currentMinValue) {
      filterData.minGHG = GHG.currentMinValue;
    }
    if (this.state.FilterAgent)
      filterData.DS_ECAP_AGENCY = this.state.FilterAgent;
    if (this.state.FilterBuildingType)
      filterData.DS_ECAP_FACILITY_TYPE = this.state.FilterBuildingType;
    if (this.state.buildingNames)
      filterData.DS_ECAP_BUILDING = this.state.buildingNames;
    if (this.state.WardFilter) filterData.DS_WARD = this.state.WardFilter;
    filterData.RANGE_NAME = this.state.selectedGranulirty.value;
    return filterData;
  };

  getEui(entry) {
    return Number(entry.SF) == 0
      ? 0
      : Number(entry.TOTAL_ENERGY_USE_BY_MMBTU) / Number(entry.SF);
  }

  compartToEUI(eui, metric) {
    return (
      Number(eui) >= Number(metric.currentMinValue) &&
      Number(eui) <= Number(metric.currentMaxValue)
    );
  }
  compartToSF(data, metric) {
    return (
      Number(data.MIN_SF) >= Number(metric.currentMinValue) &&
      Number(data.MAX_SF) <= Number(metric.currentMaxValue)
    );
  }
  compartToGAS(data, metric) {
    return (
      Number(data.MIN_GAS) >= Number(metric.currentMinValue) &&
      Number(data.MAX_GAS) <= Number(metric.currentMaxValue)
    );
  }

  handleAvg(fullChartData) {
    let ab;
    const keys = Object.keys(fullChartData);
    keys.forEach((k) => {
      const currentValues = fullChartData[k].map((x) =>
        Number(x.TOTAL_ENERGY_USE_BY_MMBTU)
      );
      const sum = currentValues.reduce((a, b) => a + b, 0);
      const avg = sum / currentValues.length;
    });
  }

  handleDetailDataResponse(result) {
    if (result.data) {
      let filteredData = result.data.data;
      if (filteredData) {
        const arr = filteredData.reduce(function (r, a) {
          r[a.DS_UTILITY_COMMODITY] = r[a.DS_UTILITY_COMMODITY] || [];
          r[a.DS_UTILITY_COMMODITY].push(a);
          return r;
        }, Object.create(null));
        const key = Object.keys(arr)[0];
        const value = Object.values(arr)[0];
        const newData = { ...this.state.data };
        const fullChartData = { ...this.state.fullChartData };
        if (key) {
          newData[key] = value;
          fullChartData[key] = value;
        }
        // this.handleAvg(fullChartData);

        let btnDisabled = true;
        let showLoading = true;
        if (this.state.apiCounter === 2) {
          btnDisabled = false;
          showLoading = false;
        }
        this.setState({
          data: newData,
          fullChartData: fullChartData,
          isBtnDisabled: btnDisabled,
          showLoading: showLoading,
          apiCounter: this.state.apiCounter + 1,
        });
      } else {
        let btnDisabled = true;
        let showLoading = true;
        if (this.state.apiCounter === 2) {
          btnDisabled = false;
          showLoading = false;
        }
        this.setState({
          apiCounter: this.state.apiCounter + 1,
          isBtnDisabled: btnDisabled,
          showLoading: showLoading,
        });
      }
    } else {
      let btnDisabled = true;
      let showLoading = true;
      if (this.state.apiCounter === 2) {
        btnDisabled = false;
        showLoading = false;
      }
      this.setState({
        apiCounter: this.state.apiCounter + 1,
        isBtnDisabled: btnDisabled,
        showLoading: showLoading,
      });
    }
  }

  getDetailChartData() {
    this.setState({ isBtnDisabled: true, showLoading: true, apiCounter: 0 });
    const filterObj = this.getFilteredData();
    this.setState({ data: {}, fullChartData: {} });
    DateSearch.getDetailChartData(filterObj, window.COMMODITY_TYPES.WIND).then(
      (result) => {
        this.handleDetailDataResponse(result);
      }
    );
    DateSearch.getDetailChartData(filterObj, window.COMMODITY_TYPES.SOLAR).then(
      (result) => {
        this.handleDetailDataResponse(result);
      }
    );
    DateSearch.getDetailChartData(
      filterObj,
      window.COMMODITY_TYPES.ELECTIRC
    ).then((result) => {
      this.handleDetailDataResponse(result);
    });
  }

  getChartData() {
    if (
      this.state.selectedGranulirty.value === "Yearly" ||
      this.state.selectedGranulirty.value === "Monthly"
    ) {
      DateSearch.getChartData(this.getFilteredData()).then((result) => {
        if (result.data) {
          let filteredData = result.data;
          const arr = filteredData.reduce(function (r, a) {
            r[a.DS_UTILITY_COMMODITY] = r[a.DS_UTILITY_COMMODITY] || [];
            r[a.DS_UTILITY_COMMODITY].push(a);
            return r;
          }, Object.create(null));
          this.setState({
            data: arr,
            fullChartData: arr,
            isBtnDisabled: false,
          });
        } else {
          this.setState({ isBtnDisabled: false });
        }
      });
    } else {
      this.getDetailChartData();
    }
  }

  extractMinMax(data) {
    let enrgyStarMax =
      data.length > 0
        ? Math.ceil(
            Math.max(...data.map((obj) => Number(obj.MT_ENERGY_STAR_SCORE)))
          )
        : 0;
    let enrgyStarMin =
      data.length > 0
        ? Math.floor(
            Math.min(...data.map((obj) => Number(obj.MT_ENERGY_STAR_SCORE)))
          )
        : 0;
    let sfMax =
      data.length > 0
        ? Math.ceil(
            Math.max(...data.map((obj) => Number(obj.MT_ECAP_SQUARE_FOOTAGE)))
          )
        : 0;
    let sfMin =
      data.length > 0
        ? Math.floor(
            Math.min(...data.map((obj) => Number(obj.MT_ECAP_SQUARE_FOOTAGE)))
          )
        : 0;
    let euiMax =
      data.length > 0
        ? Math.ceil(
            Math.max(
              ...data.map((obj) => Number(obj.MT_REF_ENERGY_USE_INTENSITY))
            )
          )
        : 0;
    let euiMin =
      data.length > 0
        ? Math.floor(
            Math.min(
              ...data.map((obj) => Number(obj.MT_REF_ENERGY_USE_INTENSITY))
            )
          )
        : 0;
    let ghgMax =
      data.length > 0
        ? Math.ceil(
            Math.max(...data.map((obj) => Number(obj.MT_REF_GREEN_HOUSE_GAS)))
          )
        : 0;
    let ghgMin =
      data.length > 0
        ? Math.floor(
            Math.min(...data.map((obj) => Number(obj.MT_REF_GREEN_HOUSE_GAS)))
          )
        : 0;

    let newRangeData = this.state.rangeData.map((data) => {
      if (data.value == "sf") {
        data.min = sfMin;
        data.max = sfMax;
      } else if (data.value == "energyStar") {
        data.min = enrgyStarMin;
        data.max = enrgyStarMax;
      } else if (data.value == "eui") {
        data.min = euiMin;
        data.max = euiMax;
      } else if (data.value === "ghg") {
        data.min = ghgMin;
        data.max = ghgMax;
      }
      return data;
    });
    this.setState({ rangeData: newRangeData });
  }

  getMapData() {
    DateSearch.getDataMapSearch(this.getFilteredData()).then((result) => {
      this.setState({ markers: result.data, mapLoading: false });
      this.setFilteringValues(result.data);
    });
  }

  fetDataFromBackEnd() {
    DateSearch.getTimeRange().then((result) => {
      this.setTimeRange(result.data);
      this.getChartData();
    });
    this.getMapData();
  }

  resetAll() {
    this.setSelectedGranulirty();
    const rData = [...this.state.rangeData];
    rData.forEach((x) => {
      if (x.currentMinValue) x.currentMinValue = null;
      if (x.currentMaxValue) x.currentMaxValue = null;
    });
    let filteringD = [...this.state.FilteringData];
    filteringD.forEach((x) => {
      x.options = [];
    });
    this.setState(
      {
        data: {},
        FilteringData: filteringD,
        FullDate: [],
        FilterAgent: null,
        buildingNames: [],
        FilterBuildingType: null,
        renewableFilter: null,
        WardFilter: null,
        isBtnDisabled: true,
        rangeData: rData,
        markers: [],
        selectedMarker: null,
        markerToBeHighlighted: null,
      },
      () => {
        this.fetDataFromBackEnd();
      }
    );
  }

  setTimeRange(data) {
    if (data && data[0]) {
      let startTime = new Date(data[0].LAST_YEAR_FIRST_DAY);
      let endTime = new Date(data[0].LAST_YEAR_LAST_DAY);
      if (this.state.selectedGranulirty.value === "Monthly") {
        startTime = new Date();
        endTime = new Date();
        endTime.setMonth(endTime.getMonth() - window.MONTHFILTER);
        startTime.setMonth(endTime.getMonth() - 12);
      }
      this.setState({
        TimeRange: data[0],
        StartTime: startTime,
        EndTime: endTime,
      });
    }
  }

  pinAddressToMap(address) {
    if (address) {
      const currentAd = this.state.autoCompleteResults.find(
        (x) => x.DS_CR_ADDRESS === address
      );
      if (currentAd && currentAd.DS_LAT && currentAd.DS_LON) {
        this.setState(this.state.markerToBeHighlighted);
      }
    }
  }

  setFilteringValues(data) {
    if (data) {
      let buildingNames = [
        ...new Set(data.map((entry) => entry.DS_ECAP_BUILDING)),
      ].map((entry) => {
        return { label: entry, value: entry };
      });
      let buildingTypes = [
        ...new Set(data.map((entry) => entry.DS_ECAP_FACILITY_TYPE)),
      ].map((entry) => {
        return { label: entry, value: entry };
      });
      let agency = [...new Set(data.map((entry) => entry.DS_ECAP_AGENCY))].map(
        (entry) => {
          return { label: entry, value: entry };
        }
      );

      let ward = [
        ...new Set(data.map((entry) => entry.DS_WARD).sort((a, b) => a - b)),
      ].map((entry) => {
        return { label: entry, value: entry };
      });

      let filteringValues = [
        { title: "Building Type", options: buildingTypes },
        { title: "Agency", options: agency },
        { title: "Ward", options: ward },
        { title: "Building Name", options: buildingNames },
        {
          title: "Onsite renewables",
          options: [
            { label: "No", value: "No" },
            { label: "Yes", value: "Yes" },
          ],
        },
      ];

      this.setState({ FilteringData: filteringValues }, () => {
        let d = [...data];
        if (this.state.buildingNames.length > 0) {
          d = [];
          this.state.buildingNames.forEach((x) => {
            const currentBuilding = this.state.markers.find(
              (b) => b.DS_ECAP_BUILDING === x
            );
            if (currentBuilding) d.push(currentBuilding);
          });
        }
        this.extractMinMax([...d]);
      });
    }
  }

  setMetricFilteringValues() {
    let filteringValues = [
      { title: "Sqft" },
      { title: "EUI" },
      { title: "Energy Star Score" },
      { title: "GHG" },
    ];
    this.setState({ FilteringMetricData: filteringValues });
  }

  setTimeRangeButtonClicked = (start, end) => {
    this.setState({ StartTime: start, EndTime: end });
    console.log("the following time range is selected: " + start + "-" + end);
  };

  isPrimary = (title) => {
    if (
      title === "Last Week" &&
      this.state.StartTime &&
      new Date(this.state.StartTime).setHours(0, 0, 0, 0) ===
        new Date(this.state.TimeRange.LAST_WEEK_MONDAY)?.setHours(0, 0, 0, 0) &&
      this.state.EndTime &&
      new Date(this.state.EndTime).setHours(0, 0, 0, 0) ===
        new Date(this.state.TimeRange.LAST_WEEK_SUNDA)?.setHours(0, 0, 0, 0)
    )
      return true;
    else if (
      title === "Last Month" &&
      this.state.StartTime &&
      new Date(this.state.StartTime).setHours(0, 0, 0, 0) ===
        new Date(this.state.TimeRange.LAST_MONTH_FIRST_DAY)?.setHours(
          0,
          0,
          0,
          0
        ) &&
      this.state.EndTime &&
      new Date(this.state.EndTime)?.setHours(0, 0, 0, 0) ===
        new Date(this.state.TimeRange.LAST_MONTH_LAST_DAY)?.setHours(0, 0, 0, 0)
    )
      return true;
    else if (
      title === "Last Year" &&
      this.state.StartTime &&
      new Date(this.state.StartTime).setHours(0, 0, 0, 0) ===
        new Date(this.state.TimeRange.LAST_YEAR_FIRST_DAY)?.setHours(
          0,
          0,
          0,
          0
        ) &&
      this.state.EndTime &&
      new Date(this.state.EndTime).setHours(0, 0, 0, 0) ===
        new Date(this.state.TimeRange.LAST_YEAR_LAST_DAY)?.setHours(0, 0, 0, 0)
    )
      return true;
    else if (
      title === "Last 12 Months" &&
      this.state.StartTime &&
      new Date(this.state.StartTime).setHours(0, 0, 0, 0) ===
        this.state.currentStartTime?.setHours(0, 0, 0, 0) &&
      this.state.EndTime &&
      new Date(this.state.EndTime)?.setHours(0, 0, 0, 0) ===
        this.state.currentEndTime?.setHours(0, 0, 0, 0)
    )
      return true;
    return false;
  };

  getTimeRangeButton = (title, funct) => {
    return (
      <div className="w-100">
        <Button
          children={title}
          callBack={funct}
          primary={this.isPrimary(title)}
          link={!this.isPrimary(title)}
        />
        <HorizontalSpace xs />
      </div>
    );
  };

  getMultiSelectElements = (obj) => {
    return (
      <div className="d-flex flex-wrap">
        {obj.map((x, idx) => {
          return (
            <SelectComponent
              key={idx}
              title={x.title}
              options={x.options}
              selected={
                x.title === "Building Name" &&
                this.state.buildingNames.map((x) => {
                  return { label: x, value: x };
                })
              }
              multiSelect={x.title === "Building Name"}
              selectedCallBack={(e) => {
                this.filterData(x.title, e);
              }}
            />
          );
        })}
      </div>
    );
  };

  getDefaultValue = (y) => {
    return {
      min: y.currentMinValue ? y.currentMinValue : y.min,
      max: y.currentMaxValue ? y.currentMaxValue : y.max,
    };
  };

  getRange = (obj) => {
    return (
      <div className="d-flex flex-wrap">
        {obj.map((y, idx) => {
          return (
            <>
              <Range
                key={idx}
                minValue={y.min}
                maxValue={y.max}
                defaultValue={this.getDefaultValue(y)}
                title={y.title}
                onChange={(value) => {
                  const rData = [...this.state.rangeData];
                  if (rData) {
                    const x = rData.find((a) => a.value === y.value);
                    if (x) {
                      x.currentMinValue = value.min;
                      x.currentMaxValue = value.max;
                      this.setState({ rangeData: rData });
                    }
                  }
                }}
              />
              <HorizontalSpace l />
            </>
          );
        })}
      </div>
    );
  };

  getCollapseComponent = (title, type) => {
    return (
      <CollapseComponent
        title={title}
        children={this.getMultiSelectElements(type)}
        open
      />
    );
  };

  getDatePiker = (title, selectedDate) => {
    return (
      <div className="w-100">
        <p className="my-1">{title}</p>
        <DatePickerComponent
          selectedDate={selectedDate}
          callBack={(value) => {
            if (title === "Start Date") {
              this.setState({ StartTime: value, changedByUser: true });
            } else this.setState({ EndTime: value, changedByUser: true });
          }}
        />
      </div>
    );
  };

  isTimeGranularityValid = () => {
    const maxDays = this.state.MAX_DAYS;
    const rangeName = this.state.selectedGranulirty.value;
    const diffDays = HelperFunctions.getDiffDays(
      this.state.StartTime,
      this.state.EndTime
    );
    if (maxDays < diffDays) {
      NotificationManager.error(
        `When selecting ${rangeName} as Time granularity, the maximum number of days that you can select are ${maxDays}`,
        "Maximum Days Selection",
        5000
      );
      return false;
    }
    return true;
  };

  changedGranulirty = (e) => {
    const selectedValue = this.configData.find(
      (gra) => gra.RANGE_NAME === e.value
    );
    const newValue = {
      label: selectedValue.RANGE_NAME,
      value: selectedValue.RANGE_NAME,
    };
    this.setState({
      selectedGranulirty: newValue,
      MAX_DAYS: selectedValue.MAX_DAYS,
    });
    let end = new Date();
    let days = this.configData.find(
      (con) => con.RANGE_NAME === e.value
    ).MAX_DAYS;
    let start = new Date();
    start = start.setDate(end.getDate() - days);
    if (!this.state.changedByUser) {
      this.setState({ EndTime: end, StartTime: start });
    }
  };

  render() {
    return (
      <div className="dgs_dataSrchPage">
        <BreadCrumb title="Data Search" />
        <ReactTooltip clickable delayHide={500} />
        <div>
          {this.state.mapLoading ? (
            <div
              style={{
                height: 480,
                display: "flex",
                justifyContent: "center",
                flexDirection: "center",
                alignItems: "center",
              }}
            >
              <LoadingComponent loading={this.state.mapLoading} />
            </div>
          ) : (
            <Map
              height="30rem"
              markers={this.state.markers}
              markerHandler={(marker, data) => {
                const bNames = [...this.state.buildingNames];
                const currentBuilding = bNames.find(
                  (x) => x === marker.DS_ECAP_BUILDING
                );
                if (!currentBuilding) {
                  bNames.push(marker.DS_ECAP_BUILDING);
                } else {
                  let index = bNames.indexOf(marker.DS_ECAP_BUILDING);
                  if (index !== -1) bNames.splice(index);
                }
                let highlightedMarkers = bNames.map((name) => {
                  return { DS_ECAP_BUILDING: name };
                });
                this.setState(
                  {
                    buildingNames: bNames,
                    markerToBeHighlighted: highlightedMarkers,
                  },
                  () => {
                    this.getChartData();
                  }
                );
              }}
              highlightedMarker={this.state.markerToBeHighlighted}
              field="DS_ECAP_BUILDING"
              usedPage="building"
            />
          )}
        </div>
        <div className="dgs_dataSrchPage-dateRow d-flex py-3">
          <Container className="d-flex w-100">
            <div className="dgs_dataSrchPage-dateRow-timeRange w-100  col-6 px-0">
              <p className="my-1">Time Range</p>
              <div className="dgs_dataSrchPage-dateRow-timeRange-bttn d-flex">
                {this.getTimeRangeButton(
                  "Last Week",
                  () => {
                    this.setState({ changedByUser: true }, () => {
                      this.setTimeRangeButtonClicked(
                        new Date(this.state.TimeRange.LAST_WEEK_MONDAY),
                        new Date(this.state.TimeRange.LAST_WEEK_SUNDA)
                      );
                    });
                  },
                  this.state.TimeRange.LAST_WEEK_MONDAY,
                  this.state.TimeRange.LAST_WEEK_SUNDAY
                )}
                {this.getTimeRangeButton(
                  "Last Month",
                  () => {
                    this.setState({ changedByUser: true }, () => {
                      this.setTimeRangeButtonClicked(
                        new Date(this.state.TimeRange.LAST_MONTH_FIRST_DAY),
                        new Date(this.state.TimeRange.LAST_MONTH_LAST_DAY)
                      );
                    });
                  },
                  this.state.TimeRange.LAST_MONTH_FIRST_DAY,
                  this.state.TimeRange.LAST_MONTH_LAST_DAY
                )}
                {this.getTimeRangeButton(
                  "Last 12 Months",
                  () => {
                    this.setState({ changedByUser: true }, () => {
                      this.setTimeRangeButtonClicked(
                        this.state.currentStartTime,
                        this.state.currentEndTime
                      );
                    });
                  },
                  this.state.currentStartTime,
                  this.state.currentEndTime
                )}
              </div>
            </div>

            <div className="dgs_dataSrchPage-dateRow-timeRange-picker d-flex col-lg-6">
              {this.getDatePiker("Start Date", this.state.StartTime)}
              <HorizontalSpace xs />
              {this.getDatePiker("End Date", this.state.EndTime)}
              <HorizontalSpace xs />
              <div className="w-100">
                <p className="my-1">Time granularity</p>
                <SelectComponent
                  className="dgs_time-granularity"
                  key={"time-granularity"}
                  options={this.configData.map((gra) => {
                    return { label: gra.RANGE_NAME, value: gra.RANGE_NAME };
                  })}
                  multiSelect={false}
                  selected={this.state.selectedGranulirty}
                  selectedCallBack={this.changedGranulirty}
                />
              </div>
            </div>
          </Container>
        </div>

        <Container className="dgs_dataSrchPage-rangeRow px-0 py-4">
          <CollapseComponent
            title={"Building Attributes Filter"}
            open
            children={this.getMultiSelectElements(this.state.FilteringData)}
          />
          <VerticalSpace s />
          <CollapseComponent
            title={"Metric Filters"}
            open
            children={this.getRange(this.state.rangeData)}
          />
        </Container>
        <Container className="dgs_dataSrchPage-tableRow pb-3">
          <div className="dgs_dataSrchPage-tableRow-filter d-flex   justify-content-between align-items-center">
            <div className="dgs_dataSrchPage-tableRow-filter-left">
              {" "}
              <Button
                children="Reset"
                style={{ height: "2rem", width: "10rem" }}
                callBack={() => this.resetAll()}
                gray
                disabled={this.state.isBtnDisabled}
              />
            </div>
            <div className="dgs_dataSrchPage-tableRow-filter-right d-flex align-items-center justify-content-end ">
              <Button
                children="Download Report"
                style={{ height: "2rem", width: "10rem" }}
                red
                icon={"download-white.svg"}
                disabled={this.state.isBtnDisabled}
                callBack={() => {
                  if (
                    this.state.selectedGranulirty.value !== "Yearly" ||
                    this.state.selectedGranulirty.value !== "Monthly"
                  ) {
                    this.downloadChartDataDetail();
                  } else {
                    this.download();
                  }
                }}
              />
              <HorizontalSpace xxs />
              <Button
                children="Apply"
                style={{ height: "2rem", width: "10rem" }}
                green
                icon={"search-white.svg"}
                disabled={this.state.isBtnDisabled}
                callBack={() => {
                  if (!this.isTimeGranularityValid()) return;
                  if (
                    this.state.EndTime &&
                    this.state.StartTime &&
                    this.state.EndTime < this.state.StartTime
                  ) {
                    NotificationManager.error(
                      "Start Time is after End Time",
                      "Please select correct time range",
                      5000
                    );
                    return;
                  }
                  this.setState({ data: {}, isBtnDisabled: true });
                  this.getChartData();
                  this.getMapData();
                }}
              />
            </div>
          </div>
          <VerticalSpace xl />
          <div style={{ position: "relative" }}>
            {this.state.showLoading && (
              <div className="loading_chart_dSearch">
                <LoadingComponent loading={this.state.showLoading} />
              </div>
            )}
            <LineChart
              chartRef={this.chartRef}
              data={this.state.data}
              activeItems={this.state.activeItems}
              timeRange={this.state.selectedGranulirty.value}
            />
          </div>
          <VerticalSpace xl />
        </Container>
      </div>
    );
  }
}

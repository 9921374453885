import React from "react";
import { Link } from "react-router-dom";
import { Container, NavItem, NavLink } from "reactstrap";
import "./index.scss";
import Logo from "../Logo/index";

export default function Footer() {
  const getCopyRightDate = () => {
    var d = new Date().getFullYear();
    return d;
  };

  const getFooterItem = (title, path, icon) => {
    return (
      <NavItem>
        <NavLink tag={Link} className="text-light d-flex px-2 py-0" to={path}>
          <img alt="" src={icon} style={{ marginRight: 5 }}></img>
          {title}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <div className="d-flex flex-row justify-content-between align-items-center w-100 py-3 dgs_footer">
      <Container className="d-flex justify-content-between align-items-center dgs_footer-body">
        <div className="copyright text-light my-3">
          © {getCopyRightDate()} DGS.gov All Rights Reserved.
        </div>
        <div className="d-flex align-items-center">
          <Logo link={"https://dc.gov/"} />
        </div>
      </Container>
    </div>
  );
}

import React from "react";
import "./index.scss";

export default function LinkComponent({
  children,
  href,
  primary,
  secondary,
  menu,
  callBack,
  underline,
}) {
  const getClasses = () => {
    const res = ["dgs_link"];
    if (primary) {
      res.push("dgs_link-primary");
    }
    if (secondary) {
      res.push("dgs_link-secondary");
    }
    if (menu) {
      res.push("dgs_link-menu");
    }
    if (underline) {
      res.push("dgs_link-underline");
    }
    return res.join(" ");
  };
  return (
    <a href={href} onClick={callBack} className={getClasses()}>
      {children}
    </a>
  );
}

import React, { useEffect, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import Highcharts3d from "highcharts/highcharts-3d";

import "./index.scss";
import { HelperFunctions } from "../../../helper/helper-functions";

export default function BarChart({
  seriesData,
  categories,
  noLegends,
  unit,
  tooltipUnit,
  small,
  color,
}) {
  if (seriesData) {
    seriesData.forEach((x, idx) => {
      x.color = color;
    });
  }
  const chart = useRef();
  Highcharts3d(Highcharts);
  if (typeof Highcharts === "object") {
    HighchartsExporting(Highcharts);
  }

  useEffect(() => {
    let chartObj = chart.current.chart;
    chartObj.showLoading();
  }, []);

  useEffect(() => {
    let chartObj = chart.current.chart;
    if (seriesData) {
      chartObj.hideLoading();
    }
  }, [seriesData]);

  const chartOptions = {
    chart: {
      height: small && "200",
      type: "column",
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 2,
        depth: 35,
        viewDistance: 25,
      },
    },
    title: {
      text: `<div style="margin-bottom:30px;"></div>`,
    },
    exporting: { enabled: true },
    xAxis: {
      categories: categories,
      crosshair: false,
    },

    yAxis: {
      labels: {
        formatter: function () {
          return `${this.value} `;
        },
      },
      min: 0,
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      x: -40,
      enabled: !noLegends,
    },

    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        `<td style="padding:0"><b>{point.y:.1f} ${tooltipUnit}</b></td></tr>`,
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },

      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: seriesData,
  };

  if (unit) {
    chartOptions.yAxis.title = {
      text: `<div style='font-size:20px'>${unit}</div>`,
    };
  } else {
    chartOptions.yAxis.title = {
      text: "",
    };
  }

  return (
    <HighchartsReact
      ref={chart}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}

import React from "react";
import "./index.scss";
import Modal from "react-modal";
import Button from "../Button";
import HorizontalSpace from "../HorizontalSpace";
import Title from "../Title";
import VerticalSpace from "../VerticalSpace";

export default function ModalComp({
  modalTitle,
  children,
  isOpen,
  onclose,
  callBack,
}) {
  return (
    <div>
      <Modal
        className="dgs__modal-body"
        isOpen={isOpen}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="dgs__modal-body-title">
          <Title left children={modalTitle} />
        </div>
        <VerticalSpace s />
        <div>{children}</div>
        <VerticalSpace l />
        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex w-50 ">
            <Button primary children="Close" callBack={onclose}></Button>
            <HorizontalSpace xs />
            <Button secondary children="Save" callBack={callBack}></Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import Axios from "axios";
import { HelperFunctions } from "../../helper/helper-functions";
const backendUrl = HelperFunctions.getBackendUrl();

const pageSet = "/sitecontent/search?PageName=";

export class SiteContentService {
  static async getPageContent(pagename) {
    try {
      return Axios.get(backendUrl + pageSet + pagename);
    } catch (error) {
      return error;
    }
  }

  static async UpdateSiteContent(id, obj) {
    try {
      return Axios.put(backendUrl + `/sitecontent/${id}`, obj);
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }
}

import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HighchartsExporting from "highcharts/modules/exporting";
import highchartsAccessibility from "highcharts/modules/accessibility";
import { HelperFunctions } from "../../../helper/helper-functions";
import "./index.scss";

export default function LineChart({
  chartRef,
  data,
  isAdmin,
  lineName,
  timeRange,
  colors,
}) {
  const [series, setSeries] = useState([]);
  const [timeRangeType, setTimeRange] = useState([]);
  const [total, setTotal] = useState({ mmbtu: 0, currentDate: null });
  const units = window.DATA_SEARCH_UNITS;
  if (isAdmin && isAdmin === "Admin") {
    if (typeof Highcharts === "object") {
      HighchartsExporting(Highcharts);
    }
  }

  useEffect(() => {
    let chartObj = chartRef?.current?.chart;
    if (Object.keys(data).length === 0) {
      chartObj.showLoading();
    } else {
      chartObj.hideLoading();
    }
  }, [data, chartRef]);

  if (typeof window !== `undefined`) {
    highchartsAccessibility(Highcharts);
  }

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let arr = [];
      const keys = Object.keys(data);
      keys.forEach((k) => {
        const currentItem = {
          name: k,
          data:
            data && data[k]
              ? data[k].map((x) => [
                  getDateObject(x),
                  k.toUpperCase() === "WATER"
                    ? Number(x.MT_UTILITY_CONSUMPTION)
                    : Number(x.TOTAL_ENERGY_USE_BY_MMBTU),
                ])
              : [],
          tooltip: {
            valueDecimals: 2,
          },
        };
        if (k.toUpperCase() === "WATER") {
          currentItem.yAxis = 1;
        } else {
          currentItem.yAxis = 0;
        }
        if (k.toUpperCase() === "NATURAL GAS") {
          currentItem.name = "Fossil Gas";
        }
        arr.push(currentItem);
      });
      setSeries(arr);

      let timeRange = "%Y-%b";
      if (data[keys[0]] && data[keys[0]][0]) {
        if (data[keys[0]][0].DT_TIMESTAMP && timeRange === "Minutes") {
          timeRange = "%H:%M";
        }
        if (data[keys[0]][0].DT_TIMESTAMP) {
          timeRange = "%H:%M";
        } else if (data[keys[0]][0].YEAR) {
          timeRange = "%Y";
        } else if (data[keys[0]][0].YEAR_WEEK) {
          timeRange = "Weekly";
        } else if (data[keys[0]][0].YEAR_MONTH_DAY) {
          timeRange = "%y-%m-%e";
        }
      }
      setTimeRange(timeRange);
    }
  }, [data]);

  const getDateOfWeek = (w, y) => {
    var d = 1 + (w - 1) * 7; // 1st of January + 7 days for each week
    return new Date(y, 0, d);
  };

  const getDateObject = (obj) => {
    //need to check the timeRange and based on that return an object.
    if (obj.YEAR_MONTH) {
      return Date.UTC(
        Number(obj.YEAR_MONTH.slice(0, 4)),
        Number(obj.YEAR_MONTH.slice(4, 6)) - 1,
        1
      );
    } else if (obj.YEAR_MONTH_DAY) {
      return Date.parse(obj.YEAR_MONTH_DAY);
    } else if (obj.YEAR) {
      return Date.UTC(Number(obj.YEAR), 1, 1);
    } else if (obj.YEAR_WEEK) {
      const d = getDateOfWeek(
        Number(obj.YEAR_WEEK.slice(4, 6)),
        Number(obj.YEAR_WEEK.slice(0, 4))
      );
      return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    } else if (obj.DT_TIMESTAMP && timeRange === "Minutes") {
      const d = new Date(obj.DT_TIMESTAMP);
      return Date.UTC(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes()
      );
    } else if (obj.DT_TIMESTAMP) {
      const d = new Date(obj.DT_TIMESTAMP);
      return Date.UTC(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        0
      );
    } else if (obj.TIME) {
      return Number(obj.TIME.slice(0, 2) + obj.TIME.slice(3, 5));
    }
  };

  const getTickInterval = () => {
    if (timeRangeType === "Yearly") return 24 * 3600 * 1000 * 30 * 12;
    if (timeRangeType === "Monthly") return 24 * 3600 * 1000 * 30;
    if (timeRangeType === "Weekly") return 24 * 3600 * 1000 * 7;
    if (timeRangeType === "Hourly") return 3600 * 1000;
  };

  const getTitleIfNeeded = () => {
    if (series && series.find((x) => x.name.toUpperCase() === "WATER"))
      return "CCF";
    return "";
  };

  const getWeeklyFormat = (timestamp) => {
    var date = new Date(timestamp),
      day = date.getUTCDay() === 0 ? 7 : date.getUTCDay(),
      dayNumber;

    date.setDate(date.getUTCDate() + 4 - day);
    dayNumber = Math.floor(
      (date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6)) / 86400000
    );

    return `${date.getFullYear()}-${1 + Math.floor(dayNumber / 7)}`;
  };

  const getTooltipDate = (currentD) => {
    let d = null;
    if (timeRangeType === "Weekly") {
      d = getWeeklyFormat(currentD);
      d = `Week: ${d}`;
    } else if (timeRangeType === "%y-%m-%e") {
      const x = new Date(currentD);
      x.setHours(10);
      currentD = x.getTime();
      d = Highcharts.dateFormat(timeRangeType, currentD);
    } else if (timeRangeType === "%H:%M") {
      d = Highcharts.dateFormat(`%y-%m-%e ${timeRangeType}`, currentD);
    } else {
      d = Highcharts.dateFormat(timeRangeType, currentD);
    }
    return d;
  };

  const chartOptions = {
    title: {
      text: "",
    },
    navigator: {
      enabled: true,
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      symbolHeight: 20,
      symbolWidth: 20,
      symbolRadius: 0,
      enabled: true,
    },
    xAxis: {
      type: "datetime",
      tickInterval: getTickInterval(),
      labels: {
        formatter: function () {
          if (timeRangeType === "Weekly") {
            return getWeeklyFormat(this.value);
          }
          return Highcharts.dateFormat(timeRangeType, this.value);
        },
      },
      title: {
        text: `<div style='font-size:20px'></div>`,
      },
    },
    yAxis: [
      {
        title: { text: "<div style='font-size:20px'>MMBtu</div>" },
        labels: {
          formatter: function () {
            return `<span className="value_yaxis">${HelperFunctions.formatNumberWithCommas(
              this.value
            )}</span>`;
          },
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
      },
      {
        opposite: true, //optional, you can have it on the same side.
        title: {
          text: `<div style='font-size:20px'>${getTitleIfNeeded()}</div>`,
        },
        labels: {
          formatter: function () {
            return `<span className="value_yaxis">${HelperFunctions.formatNumberWithCommas(
              this.value
            )}</span>`;
          },
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
    ],

    plotOptions: {
      column: {
        events: {
          legendItemClick: function (col) {
            if (col.target.index === 0) {
              var chart = lineName.highcharts();
              if (chart && chart.series) {
                chart.series.each(function () {
                  if (this.index !== 0) {
                    this.setVisible(false, false);
                  }
                });
              }
              return false;
            }
          },
        },
      },
    },

    tooltip: {
      formatter: function () {
        let mmbtuTotal = 0;
        const currentDate = getTooltipDate(this.x);
        let res = `<span><strong>${currentDate}</strong></span></br>`;
        this.points.forEach((point) => {
          const seriesName = point.series.name;
          let currentValue = point.y;
          let unitValue = units.find((unit) =>
            unit.key.toUpperCase().includes(seriesName.toUpperCase())
          );
          unitValue = unitValue ? unitValue.value : "";
          if (seriesName.toUpperCase() !== "WATER") {
            mmbtuTotal += point.y;
            if (currentValue < 10) {
              currentValue = currentValue * 1000;
              if (unitValue === "MMBtu") unitValue = "KBtu";
            }
          }
          res += `<span style="color:${
            point.series.color
          }">${seriesName}</span>: <b>${HelperFunctions.formatNumberWithCommas(
            currentValue
          )} </b> ${unitValue} <br/>`;
        });
        res += `<tr><td><span style="font-size:12px;font-weight:bolder">Total: </span></td><td><span style="font-size:12px;font-weight:bolder">${HelperFunctions.formatNumberWithCommas(
          mmbtuTotal
        )} MMBtu</span></td></tr><br/>`;
        return res;
      },
      valueDecimals: 2,
      shared: true,
      useHTML: true,
    },

    series: series,
  };

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}

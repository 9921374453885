import React from "react";
import "./index.scss";
import HorizontalSpace from "../HorizontalSpace";

export default React.forwardRef(
  ({ checkId, type, children, onChange, value, ...props }, ref) => {
    return (
      <label className="d-flex align-items-center dgs_checkbox mb-0">
        <input
          type="checkbox"
          id={`dgs_check_${checkId}`}
          autocomplete="off"
          onChange={onChange}
          checked={value}
          className={type ? `dgs_check-${type}` : ""}
          ref={ref}
          {...props}
        />
        <span
          className={type ? `dgs_checkmark-${type}` : "dgs_checkmark"}
        ></span>
        <HorizontalSpace xxs />
        <label htmlFor={`dgs_check_${checkId}`} className="mb-0 ">
          {children}
        </label>
      </label>
    );
  }
);

import React from "react";
import "./index.scss";

export default function VerticalSpace({ size, xxs, xs, s, m, l, xl }) {
  const getClassName = () => {
    const res = ["dgs_vs"];
    if (xs) {
      res.push("dgs_vs-xs");
    }
    if (s) {
      res.push("dgs_vs-s");
    }
    if (m) {
      res.push("dgs_vs-m");
    }
    if (l) {
      res.push("dgs_vs-l");
    }
    if (l) {
      res.push("dgs_vs-xl");
    }
    return res.join(" ");
  };
  return <div className={getClassName()}></div>;
}

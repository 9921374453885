import React, { useRef, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import Highcharts3d from "highcharts/highcharts-3d";

import "./index.scss";
import { HelperFunctions } from "../../../helper/helper-functions";

export default function ThreeDPie({ dataValues, title, colors }) {
  const titleValue = title;

  const chart = useRef();
  Highcharts3d(Highcharts);

  if (typeof Highcharts === "object") {
    HighchartsExporting(Highcharts);
  }

  const getName = (idx) => {
    if (dataValues) {
      return dataValues[idx][2];
    }
  };

  const chartOptions = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0,
      },
    },
    title: {
      text: "Total:" + " " + title,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    tooltip: {
      pointFormatter() {
        return `${getName(
          this.index
        )}</br>  <b>${HelperFunctions.formatNumberWithCommas(
          `${this.y}`
        )} SF</b>`;
      },
      headerFormat: ``,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        colors: colors,
        depth: 30,
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },
    },
    series: [
      {
        type: "pie",
        name: title,
        data: dataValues,
      },
    ],
  };

  useEffect(() => {
    let chartObj = chart.current.chart;
    chartObj.showLoading();
  }, []);

  useEffect(() => {
    let chartObj = chart.current.chart;
    if (dataValues) {
      chartObj.hideLoading();
    }
  }, [dataValues]);

  return (
    <HighchartsReact
      ref={chart}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}

import Axios from "axios";
import { HelperFunctions } from "../../helper/helper-functions";

const backendUrl = HelperFunctions.getBackendUrl();

const dataSet = "/dataset";

export class RenewableEnergyService {
  // ---------------------- MAP API ---------------------------------

  /**
   * Use this method to get SolarMap Search Result
   * @param filterValue if value is not available use 202008
   * @returns {Promise<AxiosResponse<any>|*|undefined>}
   * @constructor
   */
  static async SearchSolarMap(filterValue = "") {
    const name = "RENEWABLE_SOLAR_MAP_ONSITE";
    const column = [
      { name: "ID_SOLAR_SITE" },
      { name: "DS_SOLAR_SITE" },
      { name: "MT_SOLAR_CAPACITY" },
      { name: "DS_ECAP_BUILDING" },
      { name: "DS_ECAP_FACILITY_TYPE" },
      { name: "DS_ECAP_AGENCY" },
      { name: "DS_CR_ADDRESS" },
      { name: "DS_LAT" },
      { name: "DS_LON" },
      { name: "ID_ECAP_BUILDING_SRC" },
    ];
    const filter = {
      logic: "OR",
      filters: [
        {
          field: "DS_CR_ADDRESS",
          operator: "contains",
          value: filterValue,
        },
        {
          field: "DS_ECAP_FACILITY_TYPE",
          operator: "contains",
          value: filterValue,
        },
        {
          field: "DS_ECAP_BUILDING",
          operator: "contains",
          value: filterValue,
        },
        {
          field: "DS_ECAP_AGENCY",
          operator: "contains",
          value: filterValue,
        },
      ],
    };
    return this.SearchDataSet({ name, column, filter });
  }

  /**
   * Use this method to get WindMap Result
   * @returns {Promise<AxiosResponse<any>|*|undefined>}
   * @constructor
   */
  static async SearchWindMap() {
    const name = "RENEWABLE_WIND_MAP";
    const column = [
      { name: "ID_WIND_SITE" },
      { name: "DS_WIND_SITE" },
      { name: "MT_WIND_CAPACITY" },
      { name: "DS_ECAP_BUILDING" },
      { name: "DS_ECAP_FACILITY_TYPE" },
      { name: "DS_CR_ADDRESS" },
      { name: "DS_LAT" },
      { name: "DS_LON" },
      { name: "ID_ECAP_BUILDING_SRC" },
    ];
    return this.SearchDataSet({ name, column });
  }

  static async SearchCommunityMap(filterValue = "") {
    const name = "RENEWABLE_SOLAR_MAP";
    const column = [
      { name: "ID_SOLAR_SITE" },
      { name: "DS_SOLAR_SITE" },
      { name: "MT_SOLAR_CAPACITY" },
      { name: "DS_ECAP_BUILDING" },
      { name: "DS_ECAP_FACILITY_TYPE" },
      { name: "DS_ECAP_AGENCY" },
      { name: "DS_CR_ADDRESS" },
      { name: "DS_LAT" },
      { name: "DS_LON" },
    ];
    const filter = {
      filters: [
        {
          field: "DS_SOLAR_SITE_TYPE",
          operator: "eq",
          value: "Community",
          function: "TRIM",
        },
      ],
    };
    return this.SearchDataSet({ name, column, filter });
  }

  static async SearchDataSetRenewableMap(nameVal, columnName, filter) {
    const name = nameVal;
    const column = [
      "SITE_NAME",
      columnName,
      "BUILDING_NAME",
      "BUILDING_TYPE",
      "ADDRESS",
      "LATITUDE",
      "LONGITUDE",
    ];
    if (filter == null) {
      return this.SearchDataSet({ name, column });
    }
    return this.SearchDataSet({ name, column, filter });
  }

  // ----------------------------- Capacity API ---------------------

  /**
   * Search for Solar Capacity
   * @param filterValue if value is not available use 202008
   * @returns {Promise<AxiosResponse<any>|*|undefined>}
   * @constructor
   */
  static async SearchSolarCapacity(filterValue = "On-Site") {
    const name = "LU_SOLAR_SITE";
    const filterField = "DS_ECAP_BUILDING";
    const groupField = "MT_SOLAR_CAPACITY";
    const groupTitle = "groupTitle";
    return this.SearchCapacity(
      name,
      filterField,
      groupField,
      groupTitle,
      filterValue
    );
  }

  /**
   * Search for Wind Capacity
   * @param filterValue if value is not available use 202008
   * @returns {Promise<AxiosResponse<any>|*|undefined>}
   * @constructor
   */
  static async SearchWindCapacity(filterValue) {
    const name = "LU_WIND_SITE";
    const filterField = "DS_UTILITY_COMMODITY";
    const groupField = "MT_WIND_CAPACITY";
    const groupTitle = "WIND_CAPACITY";
    return this.SearchCapacity(
      name,
      filterField,
      groupField,
      groupTitle,
      filterValue
    );
  }

  static async SearchCapacity(
    name,
    filterField,
    groupField,
    groupTitle,
    filterValue
  ) {
    const filters = [
      {
        field: filterField,
        operator: "neq",
        value: filterValue,
        function: "TRIM",
      },
    ];
    const filter = { filters };

    const groups = [
      {
        field: groupField,
        function: "SUM",
        title: groupTitle,
      },
    ];
    const group = { groups };

    return this.SearchDataSet({ name, filter, group });
  }

  // --------------------------------------------- YEARLY CHART ---------------------------------------

  /**
   * Search for Solar Chart
   * @param filterValue if value is not available use 202008
   * @param isYearly set to true to search for yearly chart, false for monthly chart
   * @param isDetailed set to true to get details chart
   * @param startTime for monthly chart set search time between [startTime-endTime] e.g. 202108
   * @param endTime for monthly chart set search time between [startTime-endTime] e.g. 202109
   * @returns {Promise<AxiosResponse<any>|*|undefined>}
   * @constructor
   */
  static async SearchSolarChart(
    filterValue = `${HelperFunctions.getLastYear()}${HelperFunctions.getCurrnetMonth()}`,
    isYearly,
    isDetailed,
    startTime,
    endTime
  ) {
    const name = isDetailed
      ? "RENEWABLE_TR_SOLAR_GENERATION"
      : "RENEWABLE_SM_SOLAR_GENERATION";
    const filterFiled = "DS_SOLAR_SITE_TYPE";
    const groupField = "MT_SOLAR_GENERATION";
    const groupTitle = "SOLAR_GENERATION";
    return this.SearchChart(
      name,
      isYearly,
      filterFiled,
      filterValue,
      groupField,
      groupTitle,
      startTime,
      endTime
    );
  }

  /**
   * Search for Wind Chart
   * @param filterValue if value is not available use 202008
   * @param isYearly set to true to search for yearly chart, false for monthly chart
   * @param isDetailed set to true to get details chart
   * @param startTime for monthly chart set search time between [startTime-endTime] e.g. 202108
   * @param endTime for monthly chart set search time between [startTime-endTime] e.g. 202109
   * @returns {Promise<AxiosResponse<any>|*|undefined>}
   * @constructor
   */
  static async SearchWindChart(
    filterValue,
    isYearly,
    isDetailed,
    startTime,
    endTime
  ) {
    const name = isDetailed
      ? "RENEWABLE_TR_WIND_GENERATION"
      : "RENEWABLE_SM_WIND_GENERATION";
    const filterFiled = "DS_UTILITY_COMMODITY";
    const groupField = "MT_WIND_GENERATION";
    const groupTitle = "WIND_GENERATION";

    return this.SearchChart(
      name,
      isYearly,
      filterFiled,
      filterValue,
      groupField,
      groupTitle,
      startTime,
      endTime
    );
  }

  static getNeededMonth = () => {
    const d = new Date();
    d.setMonth(d.getMonth() - window.MONTHFILTER);
    return HelperFunctions.getMonth(d);
  };

  static async SearchChart(
    name,
    isYearly,
    filterFiled,
    filterValue,
    groupField,
    groupTitle,
    startTime = `${HelperFunctions.getLastYear()}${this.getNeededMonth()}`,
    endTime = `${HelperFunctions.getCurrentYear()}${this.getNeededMonth()}`
  ) {
    const sortField = isYearly ? "YEAR" : "YEAR_MONTH";
    const groupKey = isYearly ? "YEAR" : "YEAR_MONTH";

    let extraFilters = [];

    if (!isYearly) {
      extraFilters = [
        {
          field: sortField,
          operator: "lte",
          value: endTime,
        },
        {
          field: sortField,
          operator: "gte",
          value: startTime,
        },
      ];
    } else {
      extraFilters = [
        {
          field: "YEAR",
          operator: "lt",
          value: `${HelperFunctions.getLastYearFilterLimit()}`,
        },
      ];
    }
    const sort = [
      {
        dir: "ASC",
        field: sortField,
      },
    ];
    if (
      name === "RENEWABLE_TR_WIND_GENERATION" ||
      name === "RENEWABLE_SM_WIND_GENERATION"
    ) {
      extraFilters.push({
        field: "DS_UTILITY_COMMODITY",
        operator: "eq",
        value: window.COMMODITY_TYPES.WIND,
        //function: "TRIM",
      });
    } else {
      extraFilters.push({
        field: filterFiled,
        operator: "eq",
        value: "On-Site",
        function: "TRIM",
      });
    }
    const filter = {
      filters: [...extraFilters],
    };
    filter.logic = "AND";

    const group = {
      keys: [groupKey],
      groups: [
        {
          field: groupField,
          function: "SUM",
          title: groupTitle,
        },
      ],
    };
    return this.SearchDataSet({ name, sort, filter, group });
  }

  static async SearchDataSet(searchObj) {
    try {
      return Axios.post(backendUrl + dataSet + "/search", searchObj).catch(
        (e) => {
          console.log(e);
        }
      );
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }
  // ----------------------------------------- END OF SEARCH API ----------------------------------------------
  static async GetDataSet() {
    try {
      return Axios.get(backendUrl + dataSet);
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async GetDataSetById(id) {
    try {
      return Axios.get(backendUrl + dataSet + "/" + id);
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async PostDataSet(dataSetObj) {
    try {
      return Axios.post(backendUrl + dataSet, dataSetObj);
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async DeleteDataSet(id) {
    try {
      return Axios.delete(backendUrl + dataSet + "/" + id);
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async SummaryForOnsitSolar() {
    try {
      return Axios.post(backendUrl + dataSet + "/search", {
        name: "LU_SOLAR_SITE",
        filter: {},
        group: {
          groups: [
            {
              field: "MT_SOLAR_CAPACITY",
              function: "SUM",
              title: "SOLAR_CAPACITY",
            },
            {
              field: "MT_SOLAR_CAPACITY",
              function: "COUNT",
              title: "ROOFTOP_COUNT",
            },
            {
              field: "MT_SOLAR_CAPACITY",
              function: "COUNT",
              title: "CANOPY_COUNT",
            },
          ],
        },
      });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async SummaryForCommunity() {
    try {
      return Axios.post(backendUrl + dataSet + "/search", {
        name: "LU_SOLAR_SITE",
        filter: {
          filters: [
            {
              field: "DS_SOLAR_SITE_TYPE",
              operator: "eq",
              value: "Community",
            },
          ],
        },
        group: {
          groups: [
            {
              field: "MT_SOLAR_CAPACITY",
              function: "SUM",
              title: "CAPACITY",
            },
            {
              field: "MT_SOLAR_CAPACITY",
              function: "COUNT",
              title: "COUNT",
            },
          ],
        },
      });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async SummaryForWind() {
    try {
      return Axios.post(backendUrl + dataSet + "/search", {
        name: "LU_WIND_SITE",
        filter: {},
        group: {
          groups: [
            {
              field: "MT_WIND_CAPACITY",
              function: "SUM",
              title: "WIND_CAPACITY",
            },
            {
              field: "MT_WIND_CAPACITY",
              function: "COUNT",
              title: "COUNT",
            },
          ],
        },
      });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async YearlyCommunitySolarData() {
    try {
      return Axios.post(backendUrl + dataSet + "/search", {
        name: "RENEWABLE_SM_SOLAR_GENERATION",
        sort: [
          {
            dir: "ASC",
            field: "YEAR",
          },
        ],
        filter: {
          filters: [
            {
              field: "DS_SOLAR_SITE_TYPE",
              operator: "eq",
              value: "Community",
              function: "TRIM",
            },
          ],
        },
        group: {
          keys: ["YEAR"],
          groups: [
            {
              field: "MT_SOLAR_GENERATION",
              function: "SUM",
              title: "SOLAR_GENERATION",
            },
          ],
        },
      });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async MonthlyCommunitySolarData() {
    try {
      return Axios.post(backendUrl + dataSet + "/search", {
        name: "RENEWABLE_SM_SOLAR_GENERATION",
        sort: [
          {
            dir: "ASC",
            field: "YEAR_MONTH",
          },
        ],
        filter: {
          filters: [
            {
              field: "DS_SOLAR_SITE_TYPE",
              operator: "eq",
              value: "Community",
              function: "TRIM",
            },
            {
              field: "YEAR_MONTH",
              operator: "lte",
              value: `${HelperFunctions.getCurrentYear()}${this.getNeededMonth()}`,
            },
            {
              field: "YEAR_MONTH",
              operator: "gte",
              value: `${HelperFunctions.getLastYear()}${this.getNeededMonth()}`,
            },
          ],
          logic: "AND",
        },
        group: {
          keys: ["YEAR_MONTH"],
          groups: [
            {
              field: "MT_SOLAR_GENERATION",
              function: "SUM",
              title: "SOLAR_GENERATION",
            },
          ],
        },
      });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }
}

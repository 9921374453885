import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { color } from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import Highcharts3d from "highcharts/highcharts-3d";
import { HelperFunctions } from "../../../helper/helper-functions";

export default function StackedAreaChart({
  seriesData,
  categories,
  noLegends,
  unit,
  tooltipUnit,
  colors,
}) {
  if (seriesData) {
    seriesData.forEach((x, idx) => {
      x.fillColor = colors[idx];
      x.color = HelperFunctions.addAlpha(colors[idx], 0.7);
    });
  }
  const chart = useRef();
  const [total, setTotal] = useState(0);
  Highcharts3d(Highcharts);
  if (typeof Highcharts === "object") {
    HighchartsExporting(Highcharts);
  }

  useEffect(() => {
    let chartObj = chart.current.chart;
    chartObj.showLoading();
  }, []);

  useEffect(() => {
    let chartObj = chart.current.chart;
    if (seriesData) {
      chartObj.hideLoading();
    }
  }, [seriesData]);

  const getTooltipUnit = (name) => {
    const currentObj = tooltipUnit.find((x) => x.key === name);
    if (currentObj) return currentObj.value;
  };

  const chartOptions = {
    chart: {
      type: "area",
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 2,
        depth: 35,
        viewDistance: 25,
      },
    },
    title: {
      text: `<div style="margin-bottom:30px;"></div>`,
    },
    exporting: { enabled: true },
    xAxis: {
      categories: categories,
      crosshair: false,
    },

    yAxis: {
      labels: {
        formatter: function () {
          return HelperFunctions.formatNumberWithCommas(this.value);
        },
      },
      min: 0,
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      x: -40,
      enabled: !noLegends,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormatter() {
        return (
          `<tr><td style="color:${this.color};padding:0">${this.series.name}: </td>` +
          `<td style="padding:0"><b>${HelperFunctions.formatNumberWithCommas(
            this.y
          )} ${getTooltipUnit(this?.series?.name)}</b></td></tr>`
        );
      },
      footerFormat: `<tr><td><span style="font-size:12px;font-weight:bolder">Total</span></td><td><span style="font-size:12px;font-weight:bolder">${HelperFunctions.formatNumberWithCommas(
        total
      )} MMBtu</span></td></table>`,
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        point: {
          events: {
            mouseOver: function () {
              setTimeout(() => {
                if (total !== this.total) {
                  setTotal(this.total);
                }
              }, 0);
            },
          },
        },
      },

      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: seriesData,
  };

  if (unit) {
    chartOptions.yAxis.title = {
      text: `<div style='font-size:20px'>${unit}</div>`,
    };
  } else {
    chartOptions.yAxis.title = {
      text: "",
    };
  }

  return (
    <HighchartsReact
      ref={chart}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}

import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import "./index.scss";
import Title from "../../components/ui/Title";
import Input from "../../components/ui/Input";
import VerticalSpace from "../../components/ui/VerticalSpace";
import CheckBox from "../../components/ui/CheckBox";
import Button from "../../components/ui/Button";
import ErrorMsg from "../../components/ui/ErrorMsg";
import { AuthService } from "../../services/authentication";
import LocalStorageService from "../../services/authentication/localStorageService";
import { AppContext } from "../../Context";

export default function LoginPage() {
  const history = useHistory();
  const [user, setUser] = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.document.title = "SustainableDGS | Login";
  }, []);

  const onSubmit = async (data) => {
    try {
      const res = await AuthService.Login({
        ...data,
        returnUrl: "",
      });
      if (res.status === 200) {
        setUser(data.Username);
        LocalStorageService.setToken(res.data.data.accessToken);
        LocalStorageService.setTokenType("private");
        const rolesRes = await AuthService.getRoles();
        if (rolesRes.status === 200) {
          LocalStorageService.setRoles(rolesRes.data);
          const claimsRes = await AuthService.getClaims();
          if (claimsRes.status === 200) {
            LocalStorageService.setClaims(claimsRes.data);
            history.push("/");
          }
        }
      }
    } catch (err) {
      alert(err?.response?.data?.errorMessage);
    }
  };
  return (
    <>
      <div className="dgs_loginPage">
        <div className="dgs_loginPage-seperator"></div>
        <form
          className="dgs_loginPage-form"
          id="loginForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="dgs_loginPage-form-group">
            <Title children={"Welcome. Please Login"} />
            <VerticalSpace m />
            <Input
              placeholder={"Username"}
              {...register("Username", { required: true })}
            />
            {errors && errors.Username && (
              <ErrorMsg>Please enter your Username</ErrorMsg>
            )}
          </div>
          <VerticalSpace xs />
          <div className="dgs_loginPage-form-group">
            <Input
              type={"password"}
              placeholder={"Password"}
              {...register("Password", { required: true })}
            />
            {errors && errors.Password && (
              <ErrorMsg>Please enter your Password</ErrorMsg>
            )}
          </div>
          <VerticalSpace xs />
          <div className="dgs_loginPage-form-check pl-0 mt-2">
            <CheckBox
              checkId={"checkMeOut"}
              children={"Remember"}
              {...register("rememberLogin")}
            />
          </div>
          <VerticalSpace xs />
          <div className="dgs_loginPage-form-button">
            <Button children={"Login"} primary type="submit" />
          </div>
        </form>
        <div className="sun"></div>
      </div>
      <div className="dgs_loginPage-bg">
        <img src="imgs/login_bg.png" alt="" className="w-100"></img>
      </div>
    </>
  );
}

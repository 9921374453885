import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Route, Switch, withRouter } from "react-router-dom";
import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Layout } from "./components/Layout";
import { AppProvider } from "./Context";
import "./custom.css";
import BackGroundPge from "./pages/background";
import BUildingOverview from "./pages/buildingOverview";
import { DataSearchPage } from "./pages/dataSearch/DataSearchPage";
import { Home } from "./pages/home/Home";
import LoginPage from "./pages/login";
import PortfolioMngPage from "./pages/portfolioManagement";
import RenewableEnergyPage from "./pages/renewableEnergy";
import WastePage from "./pages/waste";
import RouteChangeTracker from "./routing/googleAnalytics";
import { AuthService } from "./services/authentication";
import LocalStorageService from "./services/authentication/localStorageService";

// const TRACKING_ID = "G-M33NMMY20P";
// ReactGA.initialize(TRACKING_ID, {
//   gaOptions: { cookieFlags: "max-age=7200;secure;samesite=none" },
// });

const App = ({ history }) => {
  const [loaded, setLoaded] = useState(false);
  const [isCalled, setCalled] = useState(false);

  const login = async () => {
    const token = LocalStorageService.getAccessToken();
    const roles = LocalStorageService.getRoles();
    if (!token || !roles) {
      const res = await AuthService.LoginInternal({
        Username: window.dgs_public_details.username,
        password: window.dgs_public_details.password,
      });
      if (res.status === 200) {
        LocalStorageService.setToken(res.data.data.accessToken);
        LocalStorageService.setTokenType("public");
        const rolesRes = await AuthService.getRoles();
        if (rolesRes.status === 200) {
          LocalStorageService.setRoles(rolesRes.data);
          const claimsRes = await AuthService.getClaims();
          if (claimsRes.status === 200) {
            LocalStorageService.setClaims(claimsRes.data);
            setLoaded(true);
          }
        }
      }
    } else {
      setLoaded(true);
    }
  };

  useEffect(() => {
    async function anyNameFunction() {
      if (isCalled) {
        await login();
      }
    }
    anyNameFunction();
  });

  useEffect(() => {
    setCalled(true);
    LocalStorageService.clearToken();
  }, []);

  history.listen((location, action) => {
    setLoaded(false);
  });

  return (
    <AppProvider>
      {/* <RouteChangeTracker /> */}

      <Layout>
        <NotificationContainer />
        <Switch key={"comps"}>
          {loaded && (
            <>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/data-search" component={DataSearchPage} />
              <Route exact path="/background" component={BackGroundPge} />
              <Route
                exact
                path="/renewable-energy"
                component={RenewableEnergyPage}
                key={document.location.href}
              />
              <Route
                exact
                path="/building-overview"
                component={BUildingOverview}
              />
              <Route exact path="/waste" component={WastePage} />
              <Route
                exact
                path="/portfolio-management"
                component={PortfolioMngPage}
              />
              <Route path="/counter" component={Counter} />
              <Route path="/fetch-data" component={FetchData} />
            </>
          )}
        </Switch>
      </Layout>
    </AppProvider>
  );
};

const WithRouterApp = withRouter(App);
export default WithRouterApp;

import React from "react";
import "./index.scss";

export default function Title({
  children,
  primary,
  secondary,
  white,
  menu,
  left,
  right,
  s,
  xs,
  l,
}) {
  const getClasses = () => {
    const res = ["dgs_title"];
    if (primary) res.push("dgs_title-primary");
    if (secondary) res.push("dgs_title-secondary");
    if (white) res.push("dgs_title-white");
    if (menu) res.push("dgs_title-menu");
    if (left) res.push("dgs_title-left");
    if (right) res.push("dgs_title-right");
    if (xs) res.push("dgs_title-xsmall");
    if (s) res.push("dgs_title-small");
    if (l) res.push("dgs_title-large");

    return res.join(" ");
  };
  return <div className={getClasses()}>{children}</div>;
}

import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "./index.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function TextEditor({ editorState, onEditorStateChange }) {
  return (
    <Editor
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="dgs__textEditor-wrapper"
      editorClassName="dgs__textEditor-editor"
      onEditorStateChange={onEditorStateChange}
    />
  );
}

import React, { useEffect, useState } from "react";
import "./index.scss";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function SelectComponent({
  title,
  options,
  dataSet,
  multiSelect,
  selectedCallBack,
  selected,
  ...props
}) {
  const [sel, setSelected] = useState(selected ? selected : null);
  useEffect(() => {
    if (selected) setSelected(selected);
  }, [selected]);
  useEffect(() => {
    if (options.length === 0) setSelected(null);
  }, [options]);
  return (
    <div
      className={`dgs_multiSlct ml-2 mb-4 ${
        props.className ? props.className : ""
      }`}
      id="dgs__select-component"
      {...props}
    >
      <p className="mb-0">{title}</p>
      {multiSelect ? (
        <Select
          defaultValue={selected}
          value={sel}
          isMulti
          name={dataSet}
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(e) => {
            setSelected(e);
            selectedCallBack(e);
          }}
          components={animatedComponents}
          closeMenuOnSelect={!title.includes("Building Name")}
        />
      ) : (
        <Select
          defaultValue={selected}
          name={dataSet}
          value={sel}
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(e) => {
            setSelected(e);
            selectedCallBack(e);
          }}
        />
      )}
    </div>
  );
}

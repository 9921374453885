import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { useHistory } from "react-router";
import { Collapse, Container, Navbar, NavItem, NavLink } from "reactstrap";
import Logo from "../Logo";
import Button from "../Button";
import HorizontalSpace from "../HorizontalSpace";
import LocalStorageService from "../../../services/authentication/localStorageService";
import { AppContext } from "../../../Context";

export default function Header({ activeItem }) {
  const history = useHistory();
  const isLoggedInInternally = () => {
    const token = LocalStorageService.getAccessToken();
    const tokenType = LocalStorageService.getTokenType();
    if (token && tokenType === "private") return true;
    return false;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInInternally());
  const [user, setUser] = useContext(AppContext);

  useEffect(() => {
    setIsLoggedIn(isLoggedInInternally());
  }, [user]);

  const getClassName = () => {
    if (isOpen === true) {
      return "dgs_header-hamburgerbtn-open";
    }
    return "";
  };

  const getMenuItem = (title, path, icon, bigger) => {
    return (
      <NavItem
        className={
          activeItem === path
            ? `dgs_header-menu-item active`
            : `dgs_header-menu-item`
        }
      >
        <a onClick={() => history.push(path)} style={{ cursor: "pointer" }}>
          <img
            alt=""
            src={icon}
            style={{ width: bigger ? "22px" : "19px" }}
          ></img>
          {title}
        </a>
      </NavItem>
    );
  };
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const toggleOpen = () => {
    return setIsOpenDropdown(!isOpenDropdown);
  };
  const menuClass = `dropdown-menu${isOpenDropdown ? " show" : ""}`;
  return (
    <>
      <div className="d-flex flex-row justify-content-between bg-white dgs_header">
        <Navbar className="navbar-expand-sm w-100 pb-lg-0 pb-md-2" light>
          <Container className="d-flex flex-lg-row flex-xs-row justify-content-center flex-nowrap">
            <Logo link={"https://dgs.dc.gov/"} big />
            <div
              className="dgs_header-hamburgerbtn"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div id="hamburgerbtn" className={getClassName()}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <Container
              className="d-flex flex-row justify-content-center dgs_header-menu px-0"
              navbar
            >
              <ul className="navbar-nav flex-grow">
                {getMenuItem("Portfolio", "/", "/icon/header_icon_1.svg")}
                <hr />
                {getMenuItem(
                  "Building Overview",
                  "/building-overview",
                  "/icon/building_overview.svg"
                )}
                <hr />
                {getMenuItem(
                  "Data Search",
                  "/data-search",
                  "/icon/header_icon_2.svg"
                )}
                <hr />
                {getMenuItem(
                  "Renewables",
                  "/renewable-energy",
                  "/icon/renawable_energy_3.png",
                  true
                )}
                <hr />
                {getMenuItem(
                  "Background",
                  "/background",
                  "/icon/header_icon_4.svg"
                )}
              </ul>
            </Container>
            <div className="d-flex flex-row  align-items-center">
              <div className="d-none d-sm-block">
                <Logo link={"https://dc.gov/"} />
              </div>
              <HorizontalSpace xs />
              {isLoggedIn === false ? (
                <Button
                  callBack={() => {
                    window.location.href = "/login";
                  }}
                  primary
                >
                  Login
                </Button>
              ) : (
                <>
                  <div
                    className="dgs_header-menu-avatar d-flex justify-content-center align-items-center dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    onClick={toggleOpen}
                  >
                    {user && user.substring(0, 1)}
                  </div>
                  <div
                    className={menuClass}
                    id="dgs_header-menu-dropdown"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item"
                      href="/login"
                      onClick={() => {
                        setUser(null);
                        window.location.href = "/login";
                        LocalStorageService.clearToken();
                      }}
                    >
                      Logout
                    </a>
                  </div>
                </>
              )}
            </div>
          </Container>
        </Navbar>
      </div>
      <Collapse
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        className="dgs_header-menuResponsive"
      >
        <Container className="d-flex flex-column justify-content-center align-items-center px-0">
          <ul className="navbar-nav bg-white px-3">
            {getMenuItem("Portfolio", "/", "/icon/header_icon_1.svg")}
            {getMenuItem(
              "Building Overview",
              "/building-overview",
              "/icon/building_overview.svg"
            )}
            {getMenuItem(
              "Data Search",
              "/data-search",
              "/icon/header_icon_2.svg"
            )}
            {getMenuItem(
              "Renewable Energy",
              "/renewable-energy",
              "/icon/renewable_energy.svg"
            )}
            {getMenuItem(
              "Background",
              "/background",
              "/icon/header_icon_4.svg"
            )}
          </ul>
        </Container>
      </Collapse>
    </>
  );
}

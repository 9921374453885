import React, { useState } from "react";
import "./index.scss";
import Button from "../Button";
import VerticalSpace from "../VerticalSpace";

export default function TabComponent({ active, tabs, onCallBack }) {
  const [activeTab, setActiveTab] = useState(active);
  return (
    <div className="d-flex">
      <ul className="nav nav-pills">
        {tabs.map((value, idx) => {
          return (
            <li key={idx} className="mr-3">
              <Button
                callBack={() => {
                  setActiveTab(value.key);
                  onCallBack(value.key);
                }}
                tabActive={activeTab === value.key ? true : false}
                tabNoneActive={activeTab !== value.key ? true : false}
                icon={activeTab === value.key ? value.iconActive : value.icon}
              >
                {value.title}
              </Button>
              <VerticalSpace s />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { HelperFunctions } from "../../../../helper/helper-functions";

export default function EditableSection({
  isEditable,
  secondGroupClaims,
  activeClaim,
  onChange,
}) {
  const getValue = () => {
    return (
      HelperFunctions.getChartVisibility(activeClaim, secondGroupClaims) === "1"
    );
  };

  const [value, setValue] = useState(getValue());

  useEffect(() => {
    setValue(getValue());
  }, [secondGroupClaims, activeClaim]);

  return (
    <>
      {isEditable && (
        <Toggle
          key={activeClaim}
          checked={value}
          onChange={(e) => {
            const value = e.target.checked;
            setValue(value);
            onChange({ key: activeClaim, value });
          }}
        />
      )}
    </>
  );
}

import React, { useRef, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HighchartsExporting from "highcharts/modules/exporting";
import highchartsAccessibility from "highcharts/modules/accessibility";
import "./index.scss";
import { HelperFunctions } from "../../../helper/helper-functions";

export default function LineChartFill({
  isAdmin,
  chartData,
  title,
  tooltip,
  percentage,
  noLoading,
  chartColor,
  renewablePage,
  categories,
}) {
  const chart = useRef();
  if (isAdmin && isAdmin === "Admin") {
    if (typeof Highcharts === "object") {
      HighchartsExporting(Highcharts);
    }
  }

  if (typeof window !== `undefined`) {
    highchartsAccessibility(Highcharts);
  }

  // ================================== stock basic line chart===============================

  const chartOptions = {
    chart: {
      height: renewablePage ? 350 : 150,
    },
    rangeSelector: {
      selected: 1,
    },

    legend: {
      enabled: false,
    },

    title: {
      text: "",
      // enabled: false,
    },
    yAxis: {
      labels: {
        formatter: function () {
          return `<span className="value_yaxis">${HelperFunctions.formatNumberWithCommas(
            this.value
          )}</span>`;
        },
      },
      min: percentage ? 0 : 50000,
      // .toString().substring(0, 5);
    },
    xAxis: {
      labels: {
        formatter: function () {
          return !renewablePage
            ? `<span className="value_yaxis">${this.value
                .toString()
                .substring(0, 5)}</span>`
            : `<span className="value_yaxis">${
                categories[this.value] ? categories[this.value] : ""
              }</span>`;
        },
      },
    },
    series: [
      {
        name: "",
        data: chartData,
        type: "area",
        threshold: null,
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormatter() {
            const x = this;
            return (
              `<tr><td style="color:{series.color};padding:0">${this.series.name}: </td>` +
              `<td style="padding:0"><b>${HelperFunctions.formatNumberWithCommas(
                this.y
              )} ${tooltip}</b></td></tr>`
            );
          },
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        color: chartColor ? chartColor : "#0038B1",
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, chartColor ? chartColor : "#0038B1"],
            [
              1,
              Highcharts.color(chartColor ? chartColor : "#0038B1")
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
    ],
  };

  useEffect(() => {
    let chartObj = chart.current.chart;
    if (!noLoading) chartObj.showLoading();
  }, []);

  useEffect(() => {
    let chartObj = chart.current.chart;
    if (
      (chartData && Object.keys(chartData).length > 0) ||
      chartData.length === 0
    ) {
      chartObj.hideLoading();
    }
  }, [chartData]);

  if (title) {
    chartOptions.yAxis.title = {
      text: title,
    };
  } else {
    chartOptions.yAxis.title = {
      text: "",
    };
  }

  return (
    <HighchartsReact
      ref={chart}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}

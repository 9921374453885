import React, { useEffect, useState } from "react";
import "./index.scss";
import Button from "../Button";

export default function SearchBar({
  placeholder,
  callBack,
  options,
  disabled,
  onSelectCallBack,
  reset,
}) {
  const [value, setValue] = useState("");
  const [clicked, setClicked] = useState(false);
  const [noResultsFound, setNoResultsFoundVisibility] = useState(false);

  useEffect(() => {
    if (reset) setValue("");
  }, [reset]);

  useEffect(() => {
    if (value === "") {
      setClicked(false);
    }
  }, [value]);

  useEffect(() => {
    if (options.length === 0 && value) setNoResultsFoundVisibility(true);
    else setNoResultsFoundVisibility(false);
  }, [options]);

  return (
    <div className="dgs_searcBar  w-100">
      <div className="d-flex">
        <div className="dgs_searcBar-inputArea w-100 d-flex">
          <img alt="" src="/icon/search.svg"></img>
          <input
            autocomplete="off"
            className="dgs_searcBar-inputArea-input w-100"
            type="search"
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              setClicked(true);
              setValue(e.target.value);
              callBack(e.target.value);
            }}
          />
        </div>
        <div className="dgs_searcBar-button">
          <Button
            children="Search"
            callBack={() => {
              callBack(value);
              setClicked(true);
            }}
            disabled={disabled}
            primary
          />
        </div>
      </div>
      {((options && options.length > 0 && clicked) || noResultsFound) && (
        <div className="dgs_searcBar-result">
          {noResultsFound ? (
            <div>No results found</div>
          ) : (
            <>
              {options &&
                options.map((obj, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        onSelectCallBack(obj.DS_CR_ADDRESS);
                        setValue(obj.DS_CR_ADDRESS);
                        setClicked(false);
                      }}
                    >
                      {`${obj.DS_ECAP_BUILDING}: ${obj.DS_CR_ADDRESS}`}
                    </div>
                  );
                })}
            </>
          )}
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./index.scss";
import { Collapse, Container, CardBody, Card } from "reactstrap";

export default function CollapseComponent({ title, children, open }) {
  const [isOpen, setIsOpen] = useState(open ? open : false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Container>
      <div className="dgs_collapse w-100">
        <div
          className="dgs_collapse-title d-flex align-items-center justify-content-between  py-3 px-4"
          onClick={toggle}
        >
          {title}
          {!isOpen ? (
            <img alt="" src="/icon/header_icon_1.svg"></img>
          ) : (
            <img alt="" src="/icon/header_icon_2.svg"></img>
          )}
        </div>
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              <div>{children}</div>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </Container>
  );
}

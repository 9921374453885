import React from "react";
import { Container } from "reactstrap";
import "./index.scss";

export default function BreadCrumb({ title, subTitle }) {
  return (
    <nav aria-label="breadcrumb dgs_breadCrumb">
      <ol className="breadcrumb dgs_breadCrumb-body">
        <Container className="d-flex align-items-center">
          <li className="breadcrumb-item mb-0">
            <a href="/">
              <img alt="" src={"/icon/footer_nav_icon_1.svg"}></img>
            </a>
          </li>
          <li className="breadcrumb-item mb-0" aria-current="page">
            {title}
          </li>
          {subTitle && (
            <li className="breadcrumb-item mb-0" aria-current="page">
              {subTitle}
            </li>
          )}
        </Container>
      </ol>
    </nav>
  );
}

import React, { useEffect, useState } from "react";
import "./index.scss";
import DatePicker from "react-date-picker";

export default function DatePickerComponent({ selectedDate, callBack }) {
  const [selectDate, setSelectDate] = useState(
    selectedDate ? selectedDate : new Date()
  );
  useEffect(() => {
    if (selectedDate) {
      setSelectDate(new Date(selectedDate));
    }
  }, [selectedDate]);

  return (
    <>
      <DatePicker
        onChange={(d) => {
          setSelectDate(d);
          callBack(d);
        }}
        openCalendarOnFocus={false}
        format="MM/dd/yyyy"
        value={selectDate}
      />
    </>
  );
}

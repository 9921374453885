import React from "react";
import "./index.scss";
import BreadCrumb from "../../components/ui/BreadCrumb";
import Title from "../../components/ui/Title";
import VerticalSpace from "../../components/ui/VerticalSpace";
import SearchBar from "../../components/ui/SearchBar";
import { Container } from "reactstrap";
import Switch from "../../components/ui/Switch";
import Button from "../../components/ui/Button";
import HorizontalSpace from "../../components/ui/HorizontalSpace";

export default function PortfolioMngPage() {
  const getBuildingListItem = (title, type) => {
    return (
      <div className="dgs_PortfolioMngPage-body-listRow-items-element d-flex align-items-center justify-content-between py-2">
        <Title children={title} xs left />
        {type === "remove" && <Button remove children="+  Remove" />}
        {type === "add" && <Button add children="- Add" />}
      </div>
    );
  };
  return (
    <div className="dgs_PortfolioMngPage">
      <BreadCrumb title="Portfolio Management" />
      <div className="dgs_PortfolioMngPage-subHeader"></div>
      <Container className="dgs_PortfolioMngPage-body">
        <div className="dgs_PortfolioMngPage-body-searchRow d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center w-100">
            <Title children="Portfolio Management" left primary s />
            <HorizontalSpace s />
            <Switch />
          </div>
          <SearchBar placeholder="Search by Building Name" />
        </div>
        <VerticalSpace s />
        <div className="dgs_PortfolioMngPage-body-listRow">
          <div className="dgs_PortfolioMngPage-body-listRow-title">
            <Title children="Building Name" left s secondary />
          </div>
          <div className="dgs_PortfolioMngPage-body-listRow-items">
            {getBuildingListItem("Building Name #1", "add")}
            {getBuildingListItem("Building Name #2", "remove")}
            {getBuildingListItem("Building Name #3", "add")}
            {getBuildingListItem("Building Name #4", "remove")}
            {getBuildingListItem("Building Name #5", "add")}
            {getBuildingListItem("Building Name #6", "remove")}
          </div>
        </div>
      </Container>
    </div>
  );
}

import axios from "axios";
import LocalStorageService from "./localStorageService";

axios.interceptors.request.use(
  (config) => {
    if (config && config.public) return config;
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      if (error.response.config.url.indexOf("/token") > -1) {
        alert("invalid credentials.");
        return Promise.reject(error);
      }
      LocalStorageService.clearToken();
      // window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

import React, { useEffect, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import Highcharts3d from "highcharts/highcharts-3d";

import "./index.scss";
import { HelperFunctions } from "../../../helper/helper-functions";

export default function NewBarChart({
  seriesData,
  categories,
  title,
  noLegends,
  unit,
  tooltipUnit,
  field,
  small,
  grpToHighlight,
  withColors,
  limitColors,
  xAxis,
  log,
}) {
  const chart = useRef();
  Highcharts3d(Highcharts);
  if (typeof Highcharts === "object") {
    HighchartsExporting(Highcharts);
  }

  useEffect(() => {
    let chartObj = chart?.current?.chart;
    chartObj.showLoading();
  }, []);

  useEffect(() => {
    let chartObj = chart?.current?.chart;
    if (seriesData) {
      chartObj.hideLoading();
    } else {
      chartObj.showLoading();
    }
  }, [seriesData]);

  const getColor = (category) => {
    return HelperFunctions.getBarColor(limitColors, category);
  };

  useEffect(() => {
    let chartObj = chart?.current?.chart;
    if (chartObj && withColors && chartObj.series[0]) {
      const points = chartObj.series[0].points;
      if (points) {
        points.forEach((point, idx) => {
          point.update({
            color: getColor(categories && categories[idx]),
            marker: {
              radius: 1.5,
            },
            className: "",
          });
        });
      }
    }
  }, [withColors, seriesData, chart]);

  const handlePoints = (field) => {
    let chartObj = chart?.current?.chart;
    const previousHighlightedPoints = chartObj.series[0]
      .getPointsCollection()
      .filter((x) => x.className === `highlighted_building_${field}`);
    previousHighlightedPoints.forEach((p) => {
      if (p && p.update) {
        p.update({
          color: getColor(categories[p.category]),
          marker: {
            radius: 1.5,
          },
          className: "",
        });
      }
    });
  };

  useEffect(() => {
    let chartObj = chart?.current?.chart;
    if (
      chartObj &&
      chartObj.series[0] &&
      chartObj.series[0].points &&
      grpToHighlight &&
      seriesData &&
      seriesData.length > 0
    ) {
      const point = chartObj.series[0].points.find(
        (x) => categories[x.category] === grpToHighlight[field]
      );
      handlePoints(field);
      if (point) {
        point.update({
          color: "#4791fe",
          marker: {
            radius: 10,
          },
          className: `highlighted_building_${field}`,
        });
      }
    } else {
      if (chartObj && chartObj.series[0] && chartObj.series[0].points)
        handlePoints(field);
    }
  }, [grpToHighlight, seriesData]);

  const getRange = (x) => {
    if (categories) {
      return categories[x];
    }
    return "";
    let nextValue = categories[x + 1];
    if (nextValue) {
      const v = Number(nextValue);
      const notANum = isNaN(v);
      if (!notANum) nextValue = v - 1;
    }
    return categories[x] + `${nextValue ? `-${nextValue}` : ""}`;
  };

  const getBarSpace = () => {
    if (categories) {
      let x = 25;
      let y = 10 / categories.length;
      return x * y;
    }
    return 10;
  };

  const getXAxixLabel = (value) => {
    if (categories && categories[value]) {
      const groupName = categories[value];
      if (groupName) {
        const idx = groupName.indexOf("-");
        if (idx > -1) {
          return groupName.substring(idx + 1, groupName.length);
        }
      }
      return groupName;
    }
    return "";
  };

  const chartOptions = {
    chart: {
      height: small && "250",
      type: "column",
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 2,
        depth: 35,
        viewDistance: 25,
      },
    },
    title: {
      text: `<div style="margin-bottom:30px;"></div>`,
    },
    exporting: { enabled: true },
    xAxis: {
      tickInterval: 1,
      title: {
        text: `<div style='font-size:15px;'><p>${xAxis}</p></div>`,
        align: "low",
        margin: 20,
      },
      labels: {
        formatter: function () {
          return `<span style="font-size:8px;">${getXAxixLabel(
            this.value
          )}</span>`;
        },
        useHTML: true,
        rotation: -45,
        align: "right",
        reserveSpace: true,
        x: getBarSpace(),
      },
      crosshair: false,
    },

    yAxis: {
      labels: {
        formatter: function () {
          return `<span style="font-size:9px;">${this.value}</span>`;
        },
        rotation: -45,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      x: -40,
      enabled: !noLegends,
    },

    tooltip: {
      formatter: function () {
        const nextObj = this.x + 1;
        if (nextObj) {
          let nextValue = categories[this.x + 1];
          if (nextValue) {
            const v = Number(nextValue);
            const notANum = isNaN(v);
            if (!notANum) nextValue = v - 1;
          }
          return (
            "<table>" +
            '<tr><th colspan="2">' +
            getRange(this.x) +
            " " +
            tooltipUnit +
            "</th></tr>" +
            `<tr><td># of Buildings: ${this.y}</td><td>` +
            "</td></tr>" +
            "</table>"
          );
        }
      },
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
      column: {
        groupPadding: 0.05,
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    series: seriesData,
  };

  chartOptions.yAxis.title = {
    text: `<div style='font-size:15px'># of Buildings</div>`,
    align: "low",
  };

  if (log) {
    chartOptions.yAxis.type = "logarithmic";
  }

  return (
    <HighchartsReact
      ref={chart}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}

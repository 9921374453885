import LocalStorageService from "../services/authentication/localStorageService";

export class HelperFunctions {
  static getBackendUrl() {
    if (process.env.REACT_APP_ENV === "PROD") {
      return window.BackEndUrl;
    } else {
      return window.BackEndUrl_OBase;
    }
  }

  static getLastYearFilterLimit() {
    const d = new Date();
    return d.getFullYear() - window.YEARFILTER;
  }

  static getLastYear() {
    let currentDate = new Date();
    return currentDate.getFullYear() - 1;
  }

  static getCurrnetMonth() {
    let currentDate = new Date();
    return this.getMonth(currentDate);
  }

  static getCurrentYear() {
    let currentDate = new Date();
    return currentDate.getFullYear();
  }

  static getClaimValueByClaimName(key, specificClaims) {
    const currentClaims = !specificClaims
      ? LocalStorageService.getClaims()
      : specificClaims;
    if (currentClaims) {
      const claim = currentClaims.find((x) => x.type === key);
      if (claim) return claim.value;
    }
    return null;
  }

  static isAdmin() {
    const roles = LocalStorageService.getRoles();
    if (roles && roles.length > 0) {
      const adminRole = roles.find((x) => x === "DGS-SE.Dashboard01");
      if (adminRole) return true;
    }
    return false;
  }

  static formatNumberWithCommas(x, isFloorNeeded = true) {
    let y = isFloorNeeded ? Math.round(x) : Number(x.toFixed(1));
    y =
      y > 999
        ? y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : y.toString();
    return y;
  }

  static getDiffDays = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  static getTileTitle = (key) => {
    if (key === "energyConsumption") return "Energy Consumption ";
    if (key === "waterConsumption") return "Water Use ";
    if (key === "greenEnergy") return "Green Power ";
    if (key === "waste") return "Waste Div Rate ";
    return "";
  };

  static ourData =
    " The DGS Utility Dashboard combines data from a variety of<br/>sources to give users a full picture of what is happening across<br/>the portfolio and at specific buildings... <a style=color:#de4156 href=/background/#dgs_background-ourData><strong>read more</strong></a>";

  static getEnergyUseIntensity = () => {
    return " <strong>Energy Use Intensity (EUI): </strong>A unit of</br>measurement that describes a building’s energy use, relative to</br>its size, on an annual basis. The common metric is KBtu/sf/yr... <a style=color:#de4156 href=/background/#dgs_background-terms-energy-intensity><strong>read more</strong>";
  };

  static getTileTooltip = (key) => {
    if (key === "energyConsumption") return this.ourData;
    if (key === "waterConsumption") return this.ourData;
    if (key === "greenEnergy")
      return "<strong>Green Power:</strong>Electricity that is generated by <br/> renewable energy sources, which can include solar photovoltaic, <br/>wind, hydropower, geothermal, and other sources that provide the <br/>highest environmental benefit.";
    if (key === "waste") return this.ourData;
    return "";
  };

  static addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  static getBarColor(limitColors, category) {
    if (category && limitColors) {
      const categoryString = String(category);
      const limit = categoryString.split("-");
      const num = limit[limit.length - 1].replace(/\D/g, "");
      const upperLimit = Number(num);
      const colors = Object.keys(limitColors).map((x) => Number(x));
      const color = colors.find((x) => x >= upperLimit);
      return limitColors[color];
    }
  }

  static getChartVisibility = (key, specificClaims) => {
    let k = null;
    if (key === "energyConsumption") k = "PORTFOLIO_ENERGY_CONSUMPTION";
    if (key === "waterConsumption") k = "PORTFOLIO_WATER_USE";
    if (key === "greenEnergy") k = "PORTFOLIO_GREEN_POWER";
    if (key === "waste") k = "PORTFOLIO_WASTE_DIV";
    return this.getClaimValueByClaimName(k, specificClaims);
  };

  static getClaimName = (key) => {
    let k = null;
    if (key === "energyConsumption") k = "PORTFOLIO_ENERGY_CONSUMPTION";
    if (key === "waterConsumption") k = "PORTFOLIO_WATER_USE";
    if (key === "greenEnergy") k = "PORTFOLIO_GREEN_POWER";
    if (key === "waste") k = "PORTFOLIO_WASTE_DIV";
    return k;
  };

  static getMonthsAsString(data) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    let res = [];
    data.forEach((element) => {
      const monIdx = Number(element.slice(4, 6)) - 1;
      res.push(`${months[monIdx]} ${element.slice(2, 4)}`);
    });
    return res;
  }

  static getWeekNumber(d) {
    var date = new Date(d.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  }

  static getMonth = (date) => {
    const currentMonth = date.getMonth() + 1;
    return `${currentMonth <= 9 ? `0${currentMonth}` : currentMonth}`;
  };

  static getNeededColumns = (viewName, commodity) => {
    const commonCols = [
      {
        name: "DS_ECAP_BUILDING",
      },
      {
        name: "DS_ECAP_FACILITY_TYPE",
      },
      {
        name: "DS_UTILITY_COMMODITY",
      },
      {
        name: "DS_ECAP_AGENCY",
      },
      {
        name: "MT_ECAP_SQUARE_FOOTAGE",
      },
      {
        name: "MT_REF_ENERGY_USE_INTENSITY",
      },
      {
        name: "MT_ENERGY_STAR_SCORE",
      },
      {
        name: "MT_REF_GREEN_HOUSE_GAS",
      },
      { name: "TOTAL_ENERGY_USE_BY_MMBTU" },
      {
        name: "ID_ONSITE_RENEWABLES_IND",
      },
      { name: "ID_UTILITY_METER_UNIT" },
    ];
    const fixedCols = [
      {
        name: "DS_CR_ADDRESS",
      },
      {
        name: "DS_LAT",
      },
      {
        name: "DS_LON",
      },
      {
        name: "TOTAL_ENERGY_USE_BY_KBTU",
      },

      {
        name: "DS_WARD",
      },
      {
        name: "YEAR",
      },
      {
        name: "MT_UTILITY_CONSUMPTION",
      },
      {
        name: "MT_GREEN_HOUSE_GAS",
      },
      { name: "MT_SOLAR_GENERATION_GRP" },
      { name: "MT_ENERGY_USE_INTENSITY_GRP" },
    ];
    if (viewName === "BUILDING_ENERGY_INTENSITY")
      return [...fixedCols, ...commonCols];
    else {
      const cols = [
        { name: "WEEK" },
        { name: "YEAR_WEEK" },
        { name: "YEAR_MONTH_DAY" },
        { name: "DAY" },
        { name: "HOUR" },
        { name: "TIME" },
        { name: "DT_TIMESTAMP" },
        {
          name: "MT_REF_GREEN_HOUSE_GAS",
        },
      ];
      return [
        {
          name: "WEEK",
        },
        {
          name: "YEAR_WEEK",
        },
        {
          name: "YEAR_MONTH_DAY",
        },
        {
          name: "DAY",
        },
        {
          name: "HOUR",
        },
        {
          name: "DS_ECAP_BUILDING",
        },
        {
          name: "DS_ECAP_FACILITY_TYPE",
        },
        {
          name: "DS_ECAP_AGENCY",
        },
        {
          name: "DS_UTILITY_COMMODITY",
        },
        {
          name: "MT_CONSUMPTION",
        },
        {
          name: "MT_ECAP_SQUARE_FOOTAGE",
        },
        {
          name: "MT_REF_ENERGY_USE_INTENSITY",
        },
        {
          name: "MT_REF_GREEN_HOUSE_GAS",
        },
        {
          name: "MT_ENERGY_STAR_SCORE",
        },
        {
          name: "TIME",
        },
        {
          name: "TOTAL_ENERGY_USE_BY_MMBTU",
        },
        {
          name: "DT_TIMESTAMP",
        },
        {
          name: "ID_ONSITE_RENEWABLES_IND",
        },
        { name: "ID_UTILITY_METER_UNIT" },
      ];
    }
  };

  static formatDateForBE(date, selectedFilter) {
    const year = date.getFullYear();
    if (selectedFilter === "Yearly") {
      return year;
    } else if (selectedFilter === "Monthly") {
      return `${year}${this.getMonth(date)}`;
    } else if (selectedFilter === "Weekly") {
      const weekNumber = this.getWeekNumber(date);
      return `${year}${weekNumber <= 9 ? `0${weekNumber}` : weekNumber}`;
    } else {
      // this used for hourly and daily
      let month = this.getMonth(date);
      let day = date.getDate();
      day = day <= 9 ? `0${day}` : day;
      return `${year}-${month}-${day}T00:00:00`;
    }
  }

  static formatDate(date, noDay) {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return noDay
      ? "" + y + (m <= 9 ? "0" + m : m)
      : "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }

  //   static exceptionHandler(e) {
  //     var msg = e?.response?.data?.message;
  //     if (msg) {
  //       this.displayErrorNotification(msg);
  //     } else if (e?.message) {
  //       console.error(e.message);
  //       this.displayErrorNotification(e.message);
  //     }
  //     if (e?.stack) {
  //       console.error(e.stack);
  //     }
  //   }
}

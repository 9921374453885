import React from "react";
import "./index.scss";

export default function Switch() {
  return (
    <div className="dgs_switch d-flex">
      <label className="switch mb-0">
        <input type="checkbox" />
        <span className="slider round"></span>
      </label>
      <span className="dgs_switch-on ml-2">on</span>
    </div>
  );
}

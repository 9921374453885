import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import "./index.scss";
import { HelperFunctions } from "../../helper/helper-functions";
import { RenewableEnergyService } from "../../services/renewable-energy";
import { SiteContentService } from "../../services/site-content";
import Map from "../../components/maps/Map";
import LineChartFill from "../../components/charts/LineChartFill";
import ModalComp from "../../components/ui/Modal";
import Title from "../../components/ui/Title";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import SearchBar from "../../components/ui/SearchBar";
import BreadCrumb from "../../components/ui/BreadCrumb";
import TabComponent from "../../components/ui/Tab";
import TextEditor from "../../components/ui/TextEditor";
import VerticalSpace from "../../components/ui/VerticalSpace";
import LinkComponent from "../../components/ui/LinkComponent";
import HorizontalSpace from "../../components/ui/HorizontalSpace";
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { AuthService } from "../../services/authentication";

export default function RenewableEnergyPage() {
  const tabs = [
    {
      title: "On-Site Solar",
      key: "0",
      icon: "onsitesolar_icon.svg",
      iconActive: "onsitesolar_icon_active.svg",
    },
    {
      title: "Wind",
      key: "1",
      icon: "win_icon.svg",
      iconActive: "win_icon_active.svg",
    },
    {
      title: "Community Solar",
      key: "2",
      icon: "community_icon.svg",
      iconActive: "community_icon_active.svg",
    },
  ];
  const [activeKey, setActiveKey] = useState(tabs[0].key);
  const [yearlyData, setYearlyData] = useState({});
  const [monthlyData, setMonthlyData] = useState([]);
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [onSiteMarker, setOnSiteMarker] = useState([]);
  const [windMarker, setWindMarker] = useState([]);
  const [communityMarker, setCommunityMarker] = useState([]);
  const [markerToBeHighlighted, setHighlightedMarker] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activePath, setActivePath] = useState("OnsitePATH1");
  const [textExpanded, setTextExpanded] = useState(false);
  const [contentEditorStateToHtml, setContentEditorStateToHtml] = useState(
    EditorState.createEmpty()
  );
  const [energyTypeTitle, setEnergyTypeTitle] = useState();

  const isAdmin = HelperFunctions.isAdmin();

  useEffect(() => {
    window.document.title = "SustainableDGS | Renewables";
  }, []);

  const getContentByPath = (pathName) => {
    return contentData.find((x) => x.path === pathName);
  };

  const getActiveContentPath = () => {
    if (activePath === "PATH1" && activeKey === "0") {
      return "OnsitePATH1";
    }
    if (activePath === "PATH2" && activeKey === "0") {
      return "OnsitePATH2";
    }
    if (activePath === "PATH1" && activeKey === "1") {
      return "WindPATH1";
    }
    if (activePath === "PATH2" && activeKey === "1") {
      return "WindPATH2";
    }
    if (activePath === "PATH1" && activeKey === "2") {
      return "CommunitySolarPATH1";
    }
    if (activePath === "PATH2" && activeKey === "2") {
      return "CommunitySolarPATH2";
    }
  };

  const onContentEditorStateChange = (editorState) => {
    setContentEditorStateToHtml(editorState);
  };

  const toHtmlText = draftToHtml(
    convertToRaw(contentEditorStateToHtml.getCurrentContent())
  );

  const updateContentData = async () => {
    const activePath = getActiveContentPath();
    if (activePath) {
      const obj = getContentByPath(activePath);
      if (obj) {
        const newObj = {
          ...obj,
          path: activePath,
          content: toHtmlText,
          title: energyTypeTitle,
        };
        // show loading
        await SiteContentService.UpdateSiteContent(obj.id, newObj);
        await loadContentData();
        // hide loading
      }
    }
  };

  const parseAndSetData = (res, key, isYearly) => {
    if (isYearly && res.status === 200 && res.data.isSuccessfull) {
      setYearlyData({
        chartTitle: "Yearly",
        categories: res.data.data.map((x) => x.YEAR),
        dataName: "Yearly",
        dataValue: res.data.data.map((x) => Math.round(Number(x[key]))),
        chartColor: "#32a12e",
      });
    } else if (res.status === 200 && res.data.isSuccessfull) {
      setMonthlyData({
        chartTitle: "Monthly",
        categories: HelperFunctions.getMonthsAsString(
          res.data.data.map((x) => x.YEAR_MONTH)
        ),
        dataName: "Monthly",
        dataValue: res.data.data.map((x) => Math.round(Number(x[key]))),
        chartColor: "#32a12e",
      });
    }
  };

  const loadChartsData = async () => {
    if (activeKey === "0") {
      const yearlyRes = await RenewableEnergyService.SearchSolarChart(
        null,
        true,
        false
      );
      const monthlyRes = await RenewableEnergyService.SearchSolarChart(
        null,
        false,
        false
      );
      parseAndSetData(yearlyRes, "SOLAR_GENERATION", true);
      parseAndSetData(monthlyRes, "SOLAR_GENERATION");
    }
    if (activeKey === "1") {
      const yearlyRes = await RenewableEnergyService.SearchWindChart(
        null,
        true,
        false
      );
      const monthlyRes = await RenewableEnergyService.SearchWindChart(
        null,
        false,
        false
      );
      parseAndSetData(yearlyRes, "WIND_GENERATION", true);
      parseAndSetData(monthlyRes, "WIND_GENERATION");
    }
    if (activeKey === "2") {
      const yearlyRes = await RenewableEnergyService.YearlyCommunitySolarData(
        null,
        true,
        false
      );
      const monthlyRes = await RenewableEnergyService.MonthlyCommunitySolarData(
        null,
        false,
        false
      );
      parseAndSetData(yearlyRes, "SOLAR_GENERATION", true);
      parseAndSetData(monthlyRes, "SOLAR_GENERATION");
    }
  };

  const loadMapData = async () => {
    if (activeKey === "0") {
      const res = await RenewableEnergyService.SearchSolarMap();
      if (res.status === 200 && res.data.isSuccessfull) {
        setOnSiteMarker(res.data.data);
      }
    }
    if (activeKey === "1") {
      const res = await RenewableEnergyService.SearchWindMap();
      if (res.status === 200 && res.data.isSuccessfull) {
        setWindMarker(res.data.data);
      }
    }
    if (activeKey === "2") {
      const res = await RenewableEnergyService.SearchCommunityMap();
      if (res.status === 200 && res.data.isSuccessfull) {
        setCommunityMarker(res.data.data);
      }
    }
  };

  const loadContentData = async () => {
    try {
      const res = await SiteContentService.getPageContent("renewable-energy");
      if (res && res.data && res.data.data && res.data.data.items) {
        setContentData(res.data.data.items);
      }
    } catch (err) {
      if (err?.response?.status) {
        await AuthService.handleLogin();
        await loadContentData();
      }
    }
  };

  const setSummaryDataContent = () => {
    const activePath = getActiveContentPath();
    let currentPath = activePath ? activePath : null;
    let currentContent = null;
    if (currentPath) {
      const contentByPath = getContentByPath(currentPath);
      if (contentByPath) {
        currentContent = contentByPath.content;
      }
    }
    if (currentContent) {
      setContentEditorStateToHtml(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(currentContent))
        )
      );
    }
  };

  const setSummaryDataTitle = () => {
    let activePath = getActiveContentPath();
    let currentPath = activePath ? activePath : null;
    let currentTitle = null;
    if (currentPath) {
      let contentByPath = getContentByPath(currentPath);
      if (contentByPath) {
        currentTitle = contentByPath.title;
      }
    }
    if (currentTitle) {
      setEnergyTypeTitle(currentTitle);
    }
  };

  useEffect(() => {
    setSummaryDataContent();
    setSummaryDataTitle();
  }, [activePath, activeKey]);

  useEffect(() => {
    loadChartsData();
    loadMapData();
    loadContentData();
  }, [activeKey]);

  const ReadMore = ({ children, isHtml }) => {
    const text = children;
    const partOfText = text.slice(0, 250);
    return (
      <div className="mb-3">
        {isHtml && textExpanded === false && (
          <p
            className="dgs_renewableEnergyPage-info-graph-text"
            dangerouslySetInnerHTML={{ __html: partOfText }}
          ></p>
        )}
        {isHtml && textExpanded === true && (
          <p
            className="dgs_renewableEnergyPage-info-graph-text"
            dangerouslySetInnerHTML={{ __html: text }}
          ></p>
        )}
        <LinkComponent
          callBack={() => setTextExpanded(!textExpanded)}
          children={" " + `${!textExpanded ? "Read more" : "Read less"}`}
          primary
        />
      </div>
    );
  };

  const getSummaryDetail = () => {
    let currentObj = null;
    if (activeKey === "0") {
      currentObj = getContentByPath("OnsitePATH2");
    }
    if (activeKey === "1") {
      currentObj = getContentByPath("WindPATH2");
    }
    if (activeKey === "2") {
      currentObj = getContentByPath("CommunitySolarPATH2");
    }
    if (currentObj) {
      return <ReadMore isHtml children={currentObj.content} />;
    }
    return null;
  };

  const getSummaryTitle = () => {
    const getTitle = (pathName) => {
      return (
        <h5 className="">
          {getContentByPath(pathName) ? (
            <div
              dangerouslySetInnerHTML={{
                __html: getContentByPath(pathName).title,
              }}
            />
          ) : null}
        </h5>
      );
    };
    if (activeKey === "0") {
      return getTitle("OnsitePATH2");
    }
    if (activeKey === "1") {
      return getTitle("WindPATH2");
    }
    if (activeKey === "2") {
      return getTitle("CommunitySolarPATH2");
    }
  };

  const getChartDetailTitle = (title) => {
    return (
      <div className="pl-3">
        <Title children={title} s secondary left />
      </div>
    );
  };

  const getChart = () => {
    let monthlyDataLineChart = {};
    if (monthlyData && monthlyData.dataValue) {
      monthlyDataLineChart = monthlyData.dataValue.map((x, idx) => [
        monthlyData.categories[idx],
        parseInt(x),
      ]);
    }
    let yearlyDataLineChart = {};
    if (yearlyData && yearlyData.dataValue) {
      yearlyDataLineChart = yearlyData.dataValue.map((x, idx) => [
        yearlyData.categories[idx],
        parseInt(x),
      ]);
    }
    return (
      <>
        <div className="col-lg-4 col-md-12">
          <LineChartFill
            renewablePage
            categories={yearlyData.categories}
            chartData={yearlyDataLineChart}
            title="MWh"
            tooltip="MWh"
            percentage
            chartColor={yearlyData.chartColor}
          />
        </div>
        <div className="col-lg-8 col-md-12">
          <LineChartFill
            renewablePage
            categories={monthlyData.categories}
            chartData={monthlyDataLineChart}
            title=""
            tooltip="MWh"
            percentage
            chartColor={monthlyData.chartColor}
          />
        </div>
      </>
    );
  };

  const updateAutoCompleteResults = async (value) => {
    const res = await RenewableEnergyService.SearchSolarMap(value);
    if (res.status === 200 && res.data.isSuccessfull) {
      setAutoCompleteResults(res.data.data);
    }
  };

  const pinAddressToMap = (address) => {
    if (address) {
      const currentAd = autoCompleteResults.find(
        (x) => x.DS_CR_ADDRESS === address
      );
      if (currentAd && currentAd.DS_LAT && currentAd.DS_LON) {
        setHighlightedMarker(currentAd);
      }
    }
  };

  const getMarkers = () => {
    if (activeKey === "0") return onSiteMarker;
    if (activeKey === "1") return windMarker;
    if (activeKey === "2") return communityMarker;
  };

  const getContentDetail = (pathName) => {
    return (
      <div className="dgs_renewableEnergyPage-info-data-item d-flex col-lg-12 ">
        {getContentByPath(pathName) ? (
          <div
            dangerouslySetInnerHTML={{
              __html: getContentByPath(pathName).content,
            }}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className="dgs_renewableEnergyPage">
      <BreadCrumb title="Renewable Energy" subTitle={tabs[activeKey].title} />
      <div className="dgs_renewableEnergyPage-mapWrapper">
        <Map
          height="30rem"
          markers={getMarkers()}
          highlightedMarker={markerToBeHighlighted}
          markerHandler={(marker, data) => {
            if (marker) {
              if (
                marker.ID_ECAP_BUILDING &&
                markerToBeHighlighted &&
                markerToBeHighlighted.ID_ECAP_BUILDING ===
                  marker.ID_ECAP_BUILDING
              ) {
                setHighlightedMarker(null);
              } else {
                setHighlightedMarker(marker);
              }
            } else {
              setHighlightedMarker(null);
            }
          }}
          noFullAddress={activeKey !== "0"}
          setHighlightedMarker
          field="ID_SOLAR_SITE"
          usedPage="renewable"
        />
      </div>
      <div className="dgs_renewableEnergyPage-srchRow d-flex align-items-center justify-content-center py-3 px-5 w-100">
        {activeKey === "0" && (
          <>
            <Title children="DC On-Site Solar" s white />
            <HorizontalSpace s />
            <SearchBar
              className="w-lg-25"
              callBack={(value) => updateAutoCompleteResults(value)}
              options={autoCompleteResults && autoCompleteResults}
              onSelectCallBack={(address) => pinAddressToMap(address)}
            />
          </>
        )}
        {activeKey === "1" && (
          <>
            <Title children="South Chestnut, PA Windfarm" s white />
          </>
        )}
        {activeKey === "2" && (
          <>
            <Title children="Community Solar at Oxon Run, DC" s white />
          </>
        )}
      </div>
      <Container className="pt-3">
        <div className="dgs_renewableEnergyPage-tabs">
          <TabComponent
            tabs={tabs}
            active={activeKey}
            onCallBack={(key) => {
              setYearlyData([]);
              setMonthlyData([]);
              setActiveKey(key);
              setTextExpanded(false);
            }}
          ></TabComponent>
        </div>
        <div className="dgs_renewableEnergyPage-info">
          <div className="dgs_renewableEnergyPage-info-count d-flex align-items-center justify-content-between ">
            <div>
              <div className="mb-1">
                {activeKey === "0" &&
                  getChartDetailTitle(
                    getContentByPath("OnsitePATH1")
                      ? getContentByPath("OnsitePATH1").title
                      : null
                  )}
                {activeKey === "1" &&
                  getChartDetailTitle(
                    getContentByPath("WindPATH1")
                      ? getContentByPath("WindPATH1").title
                      : null
                  )}
                {activeKey === "2" &&
                  getChartDetailTitle(
                    getContentByPath("CommunitySolarPATH1")
                      ? getContentByPath("CommunitySolarPATH1").title
                      : null
                  )}
              </div>
              <div className="dgs_renewableEnergyPage-info-data  pr-3">
                {activeKey === "0" && getContentDetail("OnsitePATH1")}
                {activeKey === "1" && getContentDetail("WindPATH1")}
                {activeKey === "2" && getContentDetail("CommunitySolarPATH1")}
              </div>
            </div>
            {isAdmin && (
              <div className="d-flex justify-content-end  px-0 col-lg-1 col-md-1">
                <Button
                  edit
                  children="edit"
                  icon="pen.svg"
                  callBack={() => {
                    setActivePath("PATH1");
                    setIsOpen(true);
                  }}
                ></Button>
              </div>
            )}
          </div>
          <div className="dgs_renewableEnergyPage-info-graph">
            <div className="d-flex align-items-center justify-content-between">
              <div className="col-lg-11">
                {getSummaryTitle()}
                {getSummaryDetail()}
              </div>
              {isAdmin && (
                <div className="d-flex justify-content-end  px-0 col-lg-1 col-md-1">
                  <Button
                    edit
                    children="edit"
                    icon="pen.svg"
                    callBack={() => {
                      setIsOpen(true);
                      setActivePath("PATH2");
                    }}
                  ></Button>
                </div>
              )}
            </div>
            <ModalComp
              isOpen={modalIsOpen}
              onclose={() => setIsOpen(false)}
              callBack={() => {
                updateContentData();
                setIsOpen(false);
              }}
              modalTitle={
                <Input
                  dark
                  defaultValue={energyTypeTitle}
                  onChange={(e) => {
                    setEnergyTypeTitle(e.target.value);
                  }}
                />
              }
              children={
                <TextEditor
                  editorState={contentEditorStateToHtml}
                  onEditorStateChange={onContentEditorStateChange}
                />
              }
            />
            <div className="dgs_renewableEnergyPage-info-graph-bar d-flex ">
              {activeKey === "0" && getChart()}
              {activeKey === "1" && getChart()}
              {activeKey === "2" && getChart()}
            </div>
          </div>
        </div>
      </Container>
      <VerticalSpace s />
    </div>
  );
}

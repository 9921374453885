import React from "react";
import "./index.scss";

export default function HorizontalSpace({ xxs, xs, s, m, l }) {
  const getClassName = () => {
    const res = ["dgs_hs"];
    if (xxs) {
      res.push("dgs_hs-xxs");
    }
    if (xs) {
      res.push("dgs_hs-xs");
    }
    if (s) {
      res.push("dgs_hs-s");
    }
    if (m) {
      res.push("dgs_hs-m");
    }
    if (l) {
      res.push("dgs_hs-l");
    }
    return res.join(" ");
  };
  return <div className={getClassName()}></div>;
}

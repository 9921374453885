import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../constants";
import Marker from "../Marker";

export default function Map({
  markers = [],
  height,
  highlightedMarker,
  field,
  usedPage,
  markerHandler,
  noFullAddress,
}) {
  const [map, setMap] = useState(null);
  const [centerPoint, setCenterPoint] = useState({});

  function animateMapZoomTo(map, targetZoom) {
    var currentZoom = arguments[2] || map.getZoom();
    if (currentZoom != targetZoom) {
      window.google.maps.event.addListenerOnce(
        map,
        "zoom_changed",
        function (event) {
          animateMapZoomTo(
            map,
            targetZoom,
            currentZoom + (targetZoom > currentZoom ? 1 : -1)
          );
        }
      );
      setTimeout(function () {
        map.setZoom(currentZoom);
      }, 80);
    }
  }

  const fitMarkerBounds = () => {
    if (window.google && map && highlightedMarker.DS_LAT) {
      map.panTo({
        lat: Number(highlightedMarker.DS_LAT),
        lng: Number(highlightedMarker.DS_LON),
      });
      animateMapZoomTo(map, 15);
      map.setCenter({
        lat: Number(highlightedMarker.DS_LAT),
        lng: Number(highlightedMarker.DS_LON),
      });
    }
  };

  // Fit bounds function
  const fitBounds = () => {
    if (window.google && map) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((item) => {
        const pos = { lat: Number(item.DS_LAT), lng: Number(item.DS_LON) };
        bounds.extend(pos);
      });
      map.fitBounds(bounds);
      var listener = new window.google.maps.event.addListener(
        map,
        "idle",
        function () {
          map.setZoom(11);
          new window.google.maps.event.removeListener(listener);
        }
      );
    }
  };

  // Fit bounds on mount, and when the markers change
  useEffect(() => {
    fitBounds();
    setCenterPoint(getCenterPoint());
  }, [markers]);

  useEffect(() => {
    if (highlightedMarker) {
      fitMarkerBounds();
    }
  }, [highlightedMarker]);

  useEffect(() => {}, [highlightedMarker]);

  const getCenterPoint = () => {
    if (
      markers &&
      markers[0]?.DS_LAT &&
      markers &&
      markers[0]?.DS_LON &&
      markers.length === 1
    ) {
      return {
        lat: Number(markers[0].DS_LAT),
        lng: Number(markers[0].DS_LON),
      };
    } else {
      return {
        lat: 38.8938672,
        lng: -77.0846158,
      };
    }
  };

  const isMarkerHighlighted = (marker) => {
    return;
  };
  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={GOOGLE_API_KEY}
      language="en"
      region="EN"
      libraries={["places"]}
      version="weekly"
    >
      <div style={{ height: height ? height : "400px", width: "100%" }}>
        <GoogleMap
          onLoad={(map) => setMap(map)}
          id="marker-example"
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          zoom={11}
          center={centerPoint}
        >
          {markers &&
            markers.map((marker, idx) => {
              return (
                <Marker
                  usedPage={usedPage}
                  noFullAddress={noFullAddress}
                  marker={marker}
                  key={idx}
                  highlight={
                    highlightedMarker &&
                    ((Array.isArray(highlightedMarker) &&
                      highlightedMarker.find(
                        (m) => m[field] === marker[field]
                      ) !== undefined) ||
                      highlightedMarker[field] === marker[field])
                  }
                  markerHandler={markerHandler}
                />
              );
            })}
        </GoogleMap>
      </div>
    </LoadScript>
  );
}

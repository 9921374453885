import React, { useEffect, useState } from "react";
import HorizontalSpace from "../HorizontalSpace";
import "./index.scss";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { HelperFunctions } from "../../../helper/helper-functions";

export default function Range({
  minValue,
  maxValue,
  defaultValue,
  title,
  onChange,
}) {
  const [rangeValue, setRangeValue] = useState(
    defaultValue ? defaultValue : { min: minValue, max: maxValue }
  );
  useEffect(() => {
    if (minValue === maxValue) {
      defaultValue.min = "";
      defaultValue.max = "";
    }
    setRangeValue(defaultValue);
  }, [defaultValue]);
  return (
    <div className="d-flex align-items-center dgs_range mb-5">
      <div style={{ width: "100%" }}>
        <p style={{ marginBottom: "20px !important" }}>{title}</p>
        <div className="d-flex align-items-center">
          <InputRange
            maxValue={maxValue}
            minValue={minValue}
            value={rangeValue}
            onChange={(value) => {
              setRangeValue(value);
              onChange(value);
            }}
            formatLabel={(n) => HelperFunctions.formatNumberWithCommas(n)}
          />
        </div>
      </div>
      <HorizontalSpace xxs />
    </div>
  );
}

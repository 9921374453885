import React from "react";
import "./index.scss";

export default function Button({
  primary,
  secondary,
  outline,
  green,
  red,
  gray,
  edit,
  add,
  remove,
  link,
  icon,
  children,
  callBack,
  tabActive,
  tabNoneActive,
  disabled,
  type,
  ...props
}) {
  const getClassName = () => {
    const res = ["dgs_btn"];
    if (primary) {
      res.push("dgs_btn-primary");
    }
    if (secondary) {
      res.push("dgs_btn-secondary");
    }
    if (outline) {
      res.push("dgs_btn-outline");
    }
    if (green) {
      res.push("dgs_btn-green");
    }
    if (red) {
      res.push("dgs_btn-red");
    }
    if (gray) {
      res.push("dgs_btn-gray");
    }
    if (edit) {
      res.push("dgs_btn-edit");
    }
    if (add) {
      res.push("dgs_btn-add");
    }
    if (remove) {
      res.push("dgs_btn-remove");
    }
    if (link) {
      res.push("dgs_btn-link");
    }
    if (tabActive) {
      res.push("dgs_btn-tabActive");
    }
    if (tabNoneActive) {
      res.push("dgs_btn-tabNoneActive");
    }
    if (disabled) {
      res.push("dgs_btn-disabled");
    }
    return res.join(" ");
  };
  return (
    <button
      {...props}
      type={type ? type : "button"}
      onClick={() => {
        if (!disabled && callBack) callBack();
      }}
      className={getClassName()}
    >
      {icon && <img className="mr-1" alt="" src={`/icon/${icon}`}></img>}
      {children}
    </button>
  );
}

import Axios from "axios";
import { HelperFunctions } from "../../helper/helper-functions";

const backendUrl = HelperFunctions.getBackendUrl();

const dataSet = "/dataset";

export class DateSearch {
  static async getTimeRange() {
    const RequestBody = {
      name: "DATASEARCH_TIME_RANGE",
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => {
          return result.data;
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getDataMapSearch(filter) {
    const filters = [
      ...this.getBuildingFilters(filter),
      ...this.getMetricFilters(filter, true),
    ];
    let RequestBody = {
      name: "DATASEARCH_MAP_DATA",
    };
    if (filters && filters.length > 0) {
      RequestBody.filter = {
        logic: "AND",
        filters: filters,
      };
    }
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => {
          return result.data;
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getDetailChartData(filter, CommodityType) {
    const RequestBody = {
      name: this.getViewName(filter, CommodityType),
      sort: this.getSorting(filter),
      filter: {
        logic: "AND",
        filters: this.getNeededFilters(filter, [], CommodityType),
      },
      group: this.getGroupFilter(filter),
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => result)
        .catch((e) => {
          console.log(e);
          // todo remove this and maybe return empty object in case of errors
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static getFilterTimeFieldName(filter) {
    const filedName = this.getSorting(filter)[0].field;
    if (filedName === "HOUR" || filedName === "TIME") return "YEAR_MONTH_DAY";
    return filedName;
  }

  static getBuildingFilters(filter) {
    let result = [];
    if (
      filter.ID_ONSITE_RENEWABLES_IND === 0 ||
      filter.ID_ONSITE_RENEWABLES_IND === 1
    ) {
      result.push({
        field: "ID_ONSITE_RENEWABLES_IND",
        operator: "eq",
        value: filter.ID_ONSITE_RENEWABLES_IND,
      });
    }
    if (filter.DS_ECAP_AGENCY) {
      result.push({
        field: "DS_ECAP_AGENCY",
        operator: "eq",
        value: filter.DS_ECAP_AGENCY,
      });
    }
    if (filter.DS_ECAP_FACILITY_TYPE) {
      result.push({
        field: "DS_ECAP_FACILITY_TYPE",
        operator: "eq",
        value: filter.DS_ECAP_FACILITY_TYPE,
      });
    }
    if (filter.DS_ECAP_BUILDING && filter.DS_ECAP_BUILDING.length > 0) {
      result.push({
        field: "DS_ECAP_BUILDING",
        operator: "in",
        value: filter.DS_ECAP_BUILDING.join(","),
      });
    }
    if (filter.DS_WARD) {
      result.push({
        field: "DS_WARD",
        operator: "eq",
        value: filter.DS_WARD,
      });
    }
    return result;
  }

  static getMetricFilters(filter, forMap) {
    let result = [];
    if (filter.minSF) {
      result.push({
        field: "MT_ECAP_SQUARE_FOOTAGE",
        operator: "gte",
        value: filter.minSF,
      });
    }
    if (filter.maxSF) {
      result.push({
        field: "MT_ECAP_SQUARE_FOOTAGE",
        operator: "lte",
        value: filter.maxSF,
      });
    }
    if (filter.minSTAR) {
      result.push({
        field: "MT_ENERGY_STAR_SCORE",
        operator: "gte",
        value: filter.minSTAR,
      });
    }
    if (filter.maxSTAR) {
      result.push({
        field: "MT_ENERGY_STAR_SCORE",
        operator: "lte",
        value: filter.maxSTAR,
      });
    }
    if (filter.minGHG) {
      result.push({
        field: "MT_REF_GREEN_HOUSE_GAS",
        operator: "gte",
        value: filter.minGHG,
      });
    }
    if (filter.maxGHG) {
      result.push({
        field: "MT_REF_GREEN_HOUSE_GAS",
        operator: "lte",
        value: filter.maxGHG,
      });
    }
    if (filter.minEUI) {
      result.push({
        field: "MT_REF_ENERGY_USE_INTENSITY",
        operator: "gte",
        value: filter.minEUI,
      });
    }
    if (filter.maxEUI) {
      result.push({
        field: "MT_REF_ENERGY_USE_INTENSITY",
        operator: "lte",
        value: filter.maxEUI,
      });
    }
    return result;
  }

  static getNeededFilters(filter, activeItems, CommodityType) {
    let result = this.getBuildingFilters(filter);
    if (filter.startTime) {
      result.push({
        field: this.getFilterTimeFieldName(filter),
        operator: "gte",
        value: filter.startTime,
      });
    }
    if (filter.endTime) {
      result.push({
        field: this.getFilterTimeFieldName(filter),
        operator: "lte",
        value: filter.endTime,
      });
    }
    if (!CommodityType) {
      if (filter.activeItems && filter.activeItems.length > 0 && !activeItems) {
        result.push({
          field: "DS_UTILITY_COMMODITY",
          operator: filter.activeItems.length === 1 ? "eq" : "in",
          value:
            filter.activeItems.length === 1
              ? filter.activeItems[0]
              : filter.activeItems.join(","),
          function: "TRIM",
        });
      } else if (activeItems) {
        result.push({
          field: "DS_UTILITY_COMMODITY",
          operator: activeItems.length === 1 ? "eq" : "in",
          value:
            activeItems.length === 1 ? activeItems[0] : activeItems.join(","),
          function: "TRIM",
        });
      }
    }
    result = [...result, ...this.getMetricFilters(filter)];
    return result;
  }

  static async downloadData(filter, activeItems, CommodityType) {
    const viewName = this.getViewName(filter, CommodityType);
    const RequestBody = {
      name: viewName,
      column: HelperFunctions.getNeededColumns(viewName, CommodityType),
      sort: this.getSorting(filter),
      filter: {
        logic: "AND",
        filters: this.getNeededFilters(filter, activeItems, CommodityType),
      },
    };
    try {
      return Axios.post(backendUrl + dataSet + "/Excel/download", RequestBody, {
        responseType: "blob",
      })
        .then((result) => result)
        .catch((e) => {
          return e;
        });
    } catch (error) {
      return error;
    }
  }

  static getViewName = (filter, commodityType) => {
    if (
      filter.RANGE_NAME === "Hourly" ||
      filter.RANGE_NAME === "Daily" ||
      filter.RANGE_NAME === "Weekly" ||
      filter.RANGE_NAME === "Minutes"
    ) {
      if (commodityType === window.COMMODITY_TYPES.WIND) {
        return "DATASEARCH_TR_WIND_GENERATION";
      }
      if (commodityType === window.COMMODITY_TYPES.ELECTIRC) {
        return "DATASEARCH_TR_ELC_CONSUMPTION";
      }
      if (commodityType === window.COMMODITY_TYPES.SOLAR) {
        return "DATASEARCH_TR_SOLAR_GENERATION";
      }
    } else return "BUILDING_ENERGY_INTENSITY";
  };

  static getSorting = (filter) => {
    if (filter.RANGE_NAME === "Minutes") {
      return [{ dir: "ASC", field: "DT_TIMESTAMP" }];
    }
    if (filter.RANGE_NAME === "Hourly") {
      return [{ dir: "ASC", field: "DT_TIMESTAMP" }];
    }
    if (filter.RANGE_NAME === "Daily") {
      return [{ dir: "ASC", field: "YEAR_MONTH_DAY" }];
    }
    if (filter.RANGE_NAME === "Weekly") {
      return [{ dir: "ASC", field: "YEAR_WEEK" }];
    }
    if (filter.RANGE_NAME === "Monthly") {
      return [{ dir: "ASC", field: "YEAR_MONTH" }];
    }
    if (filter.RANGE_NAME === "Yearly") {
      return [{ dir: "ASC", field: "YEAR" }];
    }
  };

  static getGroupFilter(filter) {
    let res = {};
    res.keys = ["DS_UTILITY_COMMODITY"];
    if (filter.selectedGranulirty.value === "Hourly") {
      res.keys.push("DT_TIMESTAMP");
    } else if (filter.selectedGranulirty.value === "Minutes") {
      res.keys.push("DT_TIMESTAMP");
    } else {
      res.keys.push(this.getSorting(filter)[0].field);
    }
    const currentViewName = this.getViewName(filter);

    res.groups = [
      {
        field:
          currentViewName !== "BUILDING_ENERGY_INTENSITY"
            ? "TOTAL_ENERGY_USE_BY_MMBTU"
            : "TOTAL_ENERGY_USE_BY_MMBTU",
        function: "SUM",
        title: "TOTAL_ENERGY_USE_BY_MMBTU",
      },
    ];
    if (currentViewName === "BUILDING_ENERGY_INTENSITY") {
      res.groups.push({
        field: "MT_UTILITY_CONSUMPTION",
        function: "SUM",
        title: "MT_UTILITY_CONSUMPTION",
      });
    }
    return res;
  }

  static async getChartData(filter) {
    const RequestBody = {
      name: this.getViewName(filter),
      sort: this.getSorting(filter),
      filter: {
        logic: "AND",
        filters: this.getNeededFilters(filter),
      },
      group: this.getGroupFilter(filter),
    };
    try {
      return Axios.post(backendUrl + dataSet + "/search", RequestBody)
        .then((result) => {
          return result.data;
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }
}

import Axios from "axios";
import { HelperFunctions } from "../../helper/helper-functions";
import LocalStorageService from "./localStorageService";

const backendUrl = HelperFunctions.getBackendUrl();

export class AuthService {
  static async Login(obj) {
    try {
      return Axios.post(backendUrl + "/account/dgstoken", obj);
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async LoginInternal(obj) {
    try {
      return Axios.post(backendUrl + "/account/token", obj);
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getRoles() {
    try {
      return Axios.get(backendUrl + "/account/roles");
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getClaims() {
    try {
      return Axios.get(backendUrl + "/account/claims");
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async getClaimsForRole(role) {
    try {
      return Axios.post(backendUrl + `/account/claims`, `"${role}"`, {
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async updatePermission(obj) {
    try {
      return Axios.post(backendUrl + "/account/updatepermission", obj);
    } catch (error) {
      HelperFunctions.exceptionHandler(error);
      return error;
    }
  }

  static async handleLogin() {
    const token = LocalStorageService.getAccessToken();
    const roles = LocalStorageService.getRoles();
    if (!token || !roles) {
      const res = await this.LoginInternal({
        Username: window.dgs_public_details.username,
        password: window.dgs_public_details.password,
      });
      if (res.status === 200) {
        LocalStorageService.setToken(res.data.data.accessToken);
        LocalStorageService.setTokenType("public");
        const rolesRes = await AuthService.getRoles();
        if (rolesRes.status === 200) {
          LocalStorageService.setRoles(rolesRes.data);
          const claimsRes = await AuthService.getClaims();
          if (claimsRes.status === 200) {
            LocalStorageService.setClaims(claimsRes.data);
          }
        }
      }
    }
  }
}

const LocalStorageService = (function () {
  function _setToken(tokenString) {
    localStorage.setItem("tokenString", tokenString);
  }

  function _getAccessToken() {
    return localStorage.getItem("tokenString");
  }

  function _setTokenType(tokenString) {
    localStorage.setItem("tokenType", tokenString);
  }

  function _getTokenType() {
    return localStorage.getItem("tokenType");
  }

  function _setRoles(roles) {
    localStorage.setItem("roles", roles.join("||"));
  }

  function _getRoles() {
    return localStorage.getItem("roles")
      ? localStorage.getItem("roles").split("||")
      : null;
  }

  function _setClaims(claims) {
    localStorage.setItem("claims", JSON.stringify(claims));
  }

  function _getClaims() {
    return localStorage.getItem("claims")
      ? JSON.parse(localStorage.getItem("claims"))
      : null;
  }

  function _setUser(user) {
    localStorage.setItem("user", user);
  }

  function _getUser() {
    return localStorage.getItem("user");
  }

  function _clearToken() {
    localStorage.removeItem("tokenString");
    localStorage.removeItem("tokenType");
    localStorage.removeItem("roles");
    localStorage.removeItem("claims");
    localStorage.removeItem("user");
  }

  return {
    setToken: _setToken,
    setTokenType: _setTokenType,
    getTokenType: _getTokenType,
    getAccessToken: _getAccessToken,
    clearToken: _clearToken,
    setRoles: _setRoles,
    getRoles: _getRoles,
    setClaims: _setClaims,
    getClaims: _getClaims,
    setUser: _setUser,
    getUser: _getUser,
  };
})();
export default LocalStorageService;

import React, { useEffect } from "react";
import "./index.scss";
import BreadCrumb from "../../components/ui/BreadCrumb";
import Title from "../../components/ui/Title";
import VerticalSpace from "../../components/ui/VerticalSpace";
import { Container } from "reactstrap";

export default function BackGroundPge() {
  const getBackGroundInfo = (title, sectionId, info) => {
    return (
      <div
        className="dgs_backgroundPage-body-info mb-3"
        id={sectionId && sectionId}
      >
        <Title children={title} left secondary></Title>
        <div className="mb-0 mt-4">{info}</div>
      </div>
    );
  };

  useEffect(() => {
    window.document.title = "SustainableDGS | Background";
  }, []);

  return (
    <div className="dgs_backgroundPage">
      <BreadCrumb title="Background" />
      <Container className="dgs_backgroundPage-body">
        <VerticalSpace s />
        {getBackGroundInfo(
          "About",
          "dgs_background-about",
          <>
            <p>
              The mission of the{" "}
              <strong>Department of General Services (DGS)</strong> is to build,
              maintain, and sustain the District of Columbia’s real estate
              portfolio, which includes more than 157 million square feet of
              land and 35.7 million square feet of state-of-the-art facilities
              in Washington, DC. DGS provides a multitude of property-related
              services to both District agencies and their constituents. This
              work allows the agency to foster economic viability, environmental
              stewardship, and equity across all eight wards.{" "}
            </p>
            <p>
              <strong>
                DGS’ Sustainability and Energy Management Division (DGS-SE)
              </strong>{" "}
              is responsible for transforming the District government’s utility
              supply and building footprint through energy and sustainability
              work, while saving taxpayer dollars and creating local jobs.
              DGS-SE has three core objectives: limit portfolio resource
              consumption and environmental impacts, increase digital control
              and system knowledge of the building portfolio, and, deliver cost
              savings to District taxpayers and agencies.{" "}
            </p>
            <p>
              <strong>
                DGS-SE supports citywide climate and sustainability initiatives
              </strong>
              , such as Sustainable DC 2.0, Clean Energy DC, and Carbon Neutral
              and Climate Resilient DC plans. Mayor Bowser’s pledge to make the
              District of Columbia carbon-neutral and climate-resilient by 2050
              will transform DC into the healthiest, greenest, and most livable
              city for District residents.{" "}
            </p>
            <p>
              <strong>The DGS Utility Dashboard</strong> is an interactive
              platform for exploring the District government’s building
              portfolio, with a focus on building energy performance. It was
              created to make utility data available and understandable to
              stakeholders of all types. For instance, electricity consumption
              is recorded by smart meters and the data is delivered daily to DGS
              where it is processed, stored, and posted to the Dashboard. A key
              use of the dashboard is to communicate energy performance as set
              out by the Building Energy Performance Standards (BEPS)
              regulations.{" "}
            </p>
          </>
        )}
        {getBackGroundInfo(
          "DGS-SE GOALS",
          "dgs_background-goals",
          <>
            <ul>
              <li>
                Overarching: Green the District government utility supply and
                real estate portfolio to make Washington, DC the greenest,
                healthiest, and most livable city in the U.S.
              </li>
              <li>
                Eliminate greenhouse gas (GHG) emissions from site energy
                consumption by 45,000 Metric Tonnes CO2 (MTCO2) by 2032. This
                roughly equates to the savings from the Building Energy
                Performance Standard (BEPS) over four cycles, or through 2044.
              </li>
              <li>Achieve Zero Waste by 2032.</li>
              <li>
                Increase renewable energy supply to make up at least 50% of the
                District’s government’s energy mix by 2032.
              </li>
            </ul>
          </>
        )}
        {getBackGroundInfo(
          "Our Data​",
          "dgs_background-ourData",
          <>
            <ul>
              <li>
                The SustainableDGS Dashboard combines data from a variety of
                sources to give users the ability to track and trend
                sustainability performance the at individual DC government
                buildings and portfolio--wide. The majority of data is sourced
                from the three large utility companies that serve District
                buildings:{" "}
                <ul>
                  <li>
                    <strong>Pepco</strong> is a Transmission and Distribution
                    utility that provides electricity to DC government buildings
                    managed by DGS. Electricity comes from the Pennsylvania
                    Jersey Maryland (PJM) Regional Transmission Organization
                    (RTO) and travels through Pepco power lines to District
                    buildings where Pepco has a meter installed. Through this
                    Advanced Meter Infrastructure (AMI) or smart meters, Pepco
                    bills DGS monthly based on electricity consumption, peak
                    demand, rate class, and other factors; while also providing
                    15-minute interval data for most buildings.{" "}
                  </li>
                  <li>
                    <strong>Washington Gas</strong> provides fossil gas to those
                    District government buildings that rely on it for heating
                    air and water, cooking equipment, and fueling backup
                    generators. Washington Gas currently only provides monthly
                    billing data and does not make interval data available.{" "}
                  </li>
                  <li>
                    <strong>DC Water</strong> provides potable water to District
                    government buildings, as well as treats sewer and stormwater
                    that is generated at these same buildings and sites. Did you
                    the know that the Blue Plains Advanced Wastewater Treatment
                    Plant is the largest of its kind in the world??{" "}
                  </li>
                </ul>
              </li>
              Most of District government’s solar systems are owned, installed,
              and managed by third parties through Power Purchase Agreements
              (PPA). DGS is billed monthly for the amount of solar power that
              was produced on District government properties (on-site). Systems
              are generally sized so that the building will consume all the
              output of the on-site solar but, in some cases, electricity may be
              sent back onto the grid where it can be consumed by other
              customers served by Pepco. This is called net metering. The solar
              system owners provide access to 5-minute interval data, which
              allows for near real time monitoring and troubleshooting.
            </ul>
          </>
        )}
        {getBackGroundInfo(
          "Using the dashboards​​",
          "dgs_background-dashBoard",
          <>
            <p>
              SustainableDGS was built to enable a wide variety of users to gain
              insights into public buildings that they interact with throughout
              the District.{" "}
            </p>
            <p>
              Most users will find the map search bar in the{" "}
              <em>‘Building Overview’</em> page to be the most useful way to
              learn more about a specific building. Users can search by building
              name or address and see how a selected building compares to
              similar buildings and the rest of the District government
              portfolio.
            </p>
            <p>
              Interactive maps on the <em>Building Overview</em> and Renewable{" "}
              <em>Energy </em> pages allow users to see the breadth of the
              District government’s portfolio and geospatially select buildings
              across all eight wards.
            </p>
            <p>
              For more detailed data, the <em>‘Building Overview’</em> page’s
              trend chart provide a deep dive into granular interval data. The
              trend chart offers advanced search and filter functions for
              individual buildings or groups of buildings across multiple
              utilities and for different time periods and granularities. Users
              can view daily load curves for grid-sourced electricity
              consumption relative to on-site solar consumption; compare
              seasonal fluctuations in electricity and fossil gas ; all while
              comparing multiple buildings within the same chart.{" "}
            </p>
            <p>
              SustainableDGS is currently live in beta form while DGS
              Sustainability and Energy continues to make improvements and
              refinements to it.
            </p>
          </>
        )}
        {getBackGroundInfo(
          "Terms & Definitions",
          "dgs_background-terms",
          <>
            <ul>
              <li>
                <strong>Agency:</strong> A representation of “standard” or
                typical energy performance, used for comparative purposes.
                Baseline may be expressed according to a variety of metrics and
                may account for weather or other independent variables that
                influence energy consumption.
              </li>
              <li>
                <strong>Baseline:</strong> A representation of “standard” or
                typical energy performance, used for comparative purposes.
                Baseline may be expressed according to a variety of metrics and
                may account for weather or other independent variables that
                influence energy consumption.
              </li>
              <li>
                <strong>Benchmarking:</strong> Tracking a building’s energy and
                water use and using a standard metric to compare the building’s
                performance against past performance and to its peers
                nationwide. Building performance can be compared to that of
                similar buildings (cross-sectional benchmarking) or its own
                historic performance (longitudinal benchmarking). Benchmarking
                may also be performed at the system or component level.
              </li>
              <li>
                <strong>Building Automation System (BAS):</strong> control
                systems that consist of sensors and actuators that are
                programmed using control logic to monitor and regulate operation
                of building equipment and systems (e.g., HVAC, lighting, and
                plug loads) in a coordinated fashion to optimize performance and
                energy use.
              </li>
              <li>
                <strong>Building Energy Performance Standard (BEPS):</strong> a
                minimum threshold of energy performance by property type created
                to drive energy performance in existing buildings to help reduce
                energy and its associated greenhouse gas emissions. (The BEPS
                provision of the Clean Energy DC Omnibus Amendment Act of 2018)
                require by regulation that building owners or managers make
                energy efficiency improvements in lower-performing buildings
                over a continuously implemented, iterative five-year cycle.
              </li>
              <li>
                <strong>Building Type:</strong> Categories of buildings based on
                their primary use that is often used to compare buildings with
                similar operations. Examples include K-12 School, Office,
                Warehouse, Recreation, and Mixed Use Property.
              </li>
              <li>
                <strong>Capacity:</strong> The maximum amount of electricity a
                generator can produce or the amount of electricity transmission
                equipment can carry under ideal conditions.
              </li>
              <li>
                <strong>Demand:</strong> The rate of energy use by a particular
                building or system, i.e., power. Common units of energy demand
                are kilowatts (kW) for electricity, tons for chilled and hot
                water, and therms per hour or cubic feet per minute for gas.
              </li>
              <li>
                <strong>Electricity:</strong> a form of energy resulting from
                the flow of electrical charges produced by various generating
                sources.
              </li>
              <li>
                <strong>Energy:</strong> The combination of all sources that
                service a building, which can include electricity, fossil gas,
                steam, solar, wind, and geothermal.
              </li>
              <li>
                <strong>Energy Information System (EIS):</strong> A software
                that enables the continuous publication of energy data,
                including 15-minute electricity , billing consumption and cost ,
                benchmarking and other relevant building data, to a public
                online platform.
              </li>
              <li>
                <strong>Energy Savings: </strong>A reduction in energy use often
                quantified by accounting for key normalization factors such as
                weather or hours of operation.
              </li>
              <li>
                <strong>ENERGY STAR Score: </strong>A measure of how well your
                property is performing relative to similar properties, when
                normalized for climate and operational characteristics. The
                1-100 scale is set so that 1 represents the worst performing
                buildings and 100 represents the best performing buildings. A
                score of 50 indicates that a building, taking into account its
                size, location, and operating parameters, is performing at the
                national median,
              </li>
              <li id="dgs_background-terms-energy-intensity">
                <strong>Energy Use Intensity (EUI): </strong>A unit of
                measurement that describes a building’s energy use, relative to
                its size, on an annual basis. The common metric is KBtu/sf/yr.
                SustainableDGS uses site EUI, which refers to the amount of
                energy used as shown on utility bills. Source EUI is another
                measure that considers the generation source of the energy
                consumed. Lower values represent less energy consumption, which
                is generally better.
              </li>
              <li>
                <strong>Fossil Gas: </strong>Also known as <em>natural gas</em>,
                which is mixture of methane and other hydrocarbon gases
                delivered to buildings via pipeline and combusted to provide
                space heating, hot water heating, and backup power via
                generators.
              </li>
              <li>
                <strong>Green Power:</strong>Electricity that is generated by
                renewable energy, which can include solar photovoltaic, wind,
                hydropower, geothermal, and other sources that provide the
                highest environmental benefit.
              </li>
              <li id="dgs_background-terms-energy-intensity-greenhouse">
                <strong>Greenhouse Gases (GHG): </strong>Gaseous compounds,
                including carbon dioxide (CO2), methane (CH4), nitrous oxide
                (N2O) and fluorinated gases, that absorb infrared radiation,
                thereby trapping and holding heat in the atmosphere and causing
                climate change. GHGs primarily result from the combustion of
                fossil fuels. Commonly referred to as annual emissions of CO2e.
              </li>
              <li>
                <strong>kBtu: </strong>1,000 British Thermal Units (Btu).
                Commonly used to aggregate different types of energy commodities
                (e.g. electric and fossil gas).
              </li>
              <li>
                <strong>Kilowatt hour (kWh): </strong>1,000 watt hours (Wh).
                Common for electricity. o MMBtu: 1,000,000 British Thermal Units
                (Btu), also sometimes referred to as MBtu. Commonly used to
                aggregate different types of energy commodities (e.g.
                electricity and fossil gas).
              </li>
              <li>
                <strong>MMBtu: </strong>1,000,000 British Thermal Units (Btu),
                also sometimes referred to as MBtu. Commonly used to aggregate
                different types of energy commodities (e.g. electricity and
                fossil gas).
              </li>
              <li>
                <strong>Megawatt hour (MWh): </strong>1,000,000 watt hours (Wh).
                Common metric for electricity.
              </li>
              <li>
                <strong>On-Site Renewable: </strong>A renewable
                energy-generating system located on or adjacent to the property
                where its output is used. The most common system type in the
                District is rooftop solar photovoltaic.
              </li>

              <li>
                <strong>Peak Load: </strong>The greatest amount of energy that a
                consumer draws from the electric grid in a specified period of
                time. Peak load is also known as peak demand and is measured in
                kilowatts (kW)
              </li>
              <li>
                <strong>Power Purchase Agreement: </strong>A long term
                electricity supply contract, generally between the owner of an
                electricity generation facility and an end-user or broker, that
                specify the terms of the agreement such as the price per
                kilowatt-hour (kWh), generation facility capacity and
                generation, conditions, etc.
              </li>
              <li>
                <strong>Renewable Energy: </strong>Fuel sources, such as the
                sun, wind, moving water, and the earth’s heat, that restore
                themselves over short periods of time and do not diminish.
              </li>
              <li>
                <strong>Solar Generation: </strong>Electricity (kWh) produced by
                solar photovoltaics panels when exposed to photons, or particles
                of light
              </li>
              <li>
                <strong>Solar Photovoltaic (Solar PV): </strong>A system
                containing solar modules, each containing a number of solar
                cells, which produce electricity.
              </li>
              <li>
                <strong>Square feet (sqft): </strong>The exterior
                wall-to-exterior wall area of a building that includes all
                interior spaces and exterior walls. For the purposes of this
                dashboard, gross square feet is used. This can also be referred
                to as Gross Floor Area (GFA)
              </li>
              <li>
                <strong>Utility Bill Management Software: </strong>A utility
                bill accounting and energy management software used by DGS to
                process utility bills for payment and analyze cost and
                consumption.
              </li>
              <li>
                <strong>Utility Management: </strong>The strategic and effective
                use of commodities such as electricity to minimize costs and
                environmental impact.
              </li>
              <li>
                <strong>Ward: </strong>The District of Columbia is broken into 8
                Wards, each with approximately 75,000 residents and each
                represented by their own Council member.
              </li>
              <li>
                <strong>Water Use: </strong>The total amount of utility-provided
                potable water that is used by a variety of inside and outside
                building systems.
              </li>
              <li>
                <strong>Wind: </strong>Electricity generated by a turbine that
                harnesses mechanical power from the wind.
              </li>
            </ul>
          </>
        )}
      </Container>
    </div>
  );
}
